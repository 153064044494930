<template>
  <div :class="$style.myChannelView">
    <template v-if="activeMoment">
      <mobile-my-channel-view
        v-if="windowWidth <= DESKTOP_MY_CHANNEL_WIDTH_BREAKPOINT"
        :ref="(comp) => (mobileMyChannelViewRef = comp)"
        :class="$style.mobileMyChannelView"
        :moments="moments"
        :active-moment="activeMoment"
        :active-moment-index="activeMomentIndex"
        @on-ready="onPlayerReady"
        @on-ended="onEndedMoment"
        @on-change="onChangeMoment"
        @on-load-more="onLoadMore"
        @on-dbltap="onLikeClick"
        @on-play="onPlay"
        @on-can-play="onCanPlay"
      />

      <desktop-my-channel-view
        v-else
        :class="$style.desktopMyChannelView"
        :moments="moments"
        :active-moment="activeMoment"
        @on-ready="onPlayerReady"
        @on-ended="onEndedMoment"
        @on-can-play="onCanPlay"
        @on-pause="onPause"
        @on-play="onPlay"
      />
    </template>

    <transition name="fade">
      <my-channel-kinom-info
        v-if="activeMoment"
        :class="$style.movieInfo"
        :button-text="primaryButtonText"
        :link="getKinomButtonRoute()"
        :content-link="getContentRoute(activeMoment)"
        :title="activeMoment.contentTitle"
        :title-info="titleInfo"
        :poster="activeMoment.contentPoster"
        :is-modal-mode="isModalMode"
        @on-go-to-item-click="onGoToItemPage"
        @on-watch-button-click="onWatchButtonClick"
      />

      <my-channel-kinom-info-skeleton v-else :class="[$style.movieInfo, $style.movieInfoSkeleton]" />
    </transition>

    <my-channel-popups />

    <my-channel-kinom-controls
      v-if="activeMoment"
      ref="kinomControlsRef"
      :class="$style.controls"
      :active-moment="activeMoment"
      :my-channel-sharing-options-list-ref="myChannelSharingOptionsListRef"
      @on-like="onLikeClick"
      @on-dislike="onDislikeClick"
      @on-remove-reaction="onRemoveReactionClick"
      @on-share="onShareClick"
      @on-collection-add="onCollectionAdd"
      @on-collection-remove="onCollectionRemove"
      @on-click-outside="onClickOutside"
    />

    <div :class="$style.sharingOptionsWrapper">
      <transition name="fade">
        <my-channel-sharing-options-list
          v-if="isSharingOptionsListShown"
          ref="myChannelSharingOptionsListRef"
          :options="sharingOptionsList"
          :class="$style.sharingOptions"
          @on-option-click="shareKinom"
        />
      </transition>
    </div>

    <app-link v-if="activeMoment" :to="getKinomButtonRoute()" @click="handleWatchButtonClick">
      <app-button :text="primaryButtonText" :class="$style.button" />
    </app-link>

    <div v-else :class="$style.buttonSkeletonWrapper">
      <app-skeleton height="48px" border-radius="12px" :class="$style.button" />
    </div>

    <transition name="fade">
      <div v-if="activeMoment" :class="$style.playerGradient" />
    </transition>
  </div>
</template>

<script lang="ts" setup>
import useContentMediaButtonTexts from '@package/content-utils/src/code/content-media-button-texts';
import type { VijuPlayer } from '@package/media-player/src/player/modules/instance/player';
import {
  AnalyticPageName,
  ItemPageFrom,
  OfferPageFrom,
  useContentPageAnalytics,
  useSubscribeAnalytics,
} from '@package/sdk/src/analytics';
import { type Genre, MediaContentType } from '@package/sdk/src/api';
import { copyToClipboard, getFirstElement } from '@package/sdk/src/core';
import { useWindowSize } from '@vueuse/core';
import type { RouteLocationNamedRaw } from 'vue-router';

import useAuthActions from '@/code/auth/use-auth-actions';
import getKmzaPageValue from '@/code/kmza/get-kmza-page-value';
import type { CommonMoment } from '@/code/moments/moments';
import useMyChannelOnboarding from '@/code/my-channel/use-my-channel-onboarding';
import { NotificationLevel } from '@/code/notifications/notification';
import IconCheckmark from '@/components/icons/IconCheckmark.vue';
import DesktopMyChannelView from '@/components/my-channel/DesktopMyChannelView.vue';
import MobileMyChannelView from '@/components/my-channel/MobileMyChannelView.vue';
import MyChannelKinomControls from '@/components/my-channel/MyChannelKinomControls.vue';
import MyChannelKinomInfo from '@/components/my-channel/MyChannelKinomInfo.vue';
import MyChannelPopups from '@/components/my-channel/MyChannelPopups.vue';
import MyChannelSharingOptionsList from '@/components/my-channel/MyChannelSharingOptionsList.vue';
import AppSkeleton from '@/components/skeleton/AppSkeleton.vue';
import MyChannelKinomInfoSkeleton from '@/components/skeleton/MyChannelKinomInfoSkeleton.vue';
import AppButton from '@/components/ui/AppButton.vue';
import AppLink from '@/components/ui/AppLink.vue';
import useMobile from '@/platform/layout/use-mobile';
import useLocale from '@/platform/localization/use-locale';
import { useHostname } from '@/platform/network/use-hostname';
import { RouteQuery } from '@/platform/router/query';
import { AppRoute } from '@/platform/router/routes';
import { useRouteUtils } from '@/platform/router/use-route-utils';
import { LocalStorageKey } from '@/platform/storage/local-storage';
import { useCatalogStore } from '@/stores/use-catalog-store';
import type { DropdownOption } from '@/stores/use-layout-store';
import { useLayoutStore } from '@/stores/use-layout-store';
import { useOffersStore } from '@/stores/use-offers-store';
import { useSessionStore } from '@/stores/use-session-store';

enum ShareOptions {
  ShareKinom = 'share-kinom',
  SharePlaylist = 'share-playlist',
}

const props = defineProps<{
  moments: CommonMoment[];
  activeMomentIndex: number;
  activeMoment?: CommonMoment;
  isModalMode?: boolean;
  selectedPlaylistTitle?: string;
  selectedPlaylistId?: string;
}>();

const emit = defineEmits<{
  (e: 'on-ready', player: VijuPlayer): void;
  (e: 'on-ended', index?: number): void;
  (e: 'on-change', index: number): void;
  (e: 'on-like'): void;
  (e: 'on-dislike'): void;
  (e: 'on-remove-reaction'): void;
  (e: 'on-share', isPlayling: boolean): void;
  (e: 'on-load-more'): void;
  (e: 'on-collection-add'): void;
  (e: 'on-collection-remove'): void;
  (e: 'on-play'): void;
  (e: 'on-pause'): void;
}>();

const { $analyticSender } = useNuxtApp();
const { getContentRoute } = useRouteUtils();
const { $contentCacheManager } = useNuxtApp();
const catalogStore = useCatalogStore();

const { onGotoItemPage } = useContentPageAnalytics($analyticSender);
const subscribeAnalytics = useSubscribeAnalytics($analyticSender);

const myChannelOnboarding = useMyChannelOnboarding();
const { width: windowWidth } = useWindowSize();
const { getMyChannelPlayButtonText } = useContentMediaButtonTexts();
const { translateContentButtonText } = useLocale();
const { getOfferPageRoutesToRedirect } = useAuthActions();
const { isPartnerSubscription, isActiveSubscription } = storeToRefs(useOffersStore());
const { isAuth } = storeToRefs(useSessionStore());

const layoutStore = useLayoutStore();

const isMobile = useMobile();

const hostName = useHostname();

const { translate } = useLocale();

const route = useRoute();
const router = useRouter();

const mobileMyChannelViewRef = ref<ComponentPublicInstance<{ slideMomentWithUserInteraction: () => void }>>();
const kinomControlsRef = ref<ComponentPublicInstance<{ shareButtonRef: HTMLButtonElement }>>();

const myChannelSharingOptionsListRef = ref<HTMLDivElement>();

const isSharingOptionsListShown = ref(false);

const DESKTOP_MY_CHANNEL_WIDTH_BREAKPOINT = 1024;
const PLAYLIST_LINK_SHARING_FORBIDDEN_ROUTES = [
  AppRoute.MyChannel,
  AppRoute.MyChannelMoment,
  AppRoute.MyCollection,
  AppRoute.MyCollectionKinom,
  AppRoute.MovieCard,
  AppRoute.SerialCard,
  AppRoute.SerialEpisodeCard,
  AppRoute.SerialSeasonCard,
];

const sharingOptionsList = computed<DropdownOption[]>(() => {
  const sharingOptions = [
    {
      value: ShareOptions.ShareKinom,
      label: translate('page.myChannel.sharingOptions.copyKinomLink'),
    },
  ];

  if (!PLAYLIST_LINK_SHARING_FORBIDDEN_ROUTES.includes(route.name)) {
    sharingOptions.push({
      value: ShareOptions.SharePlaylist,
      label: translate('page.myChannel.sharingOptions.copyPlaylistLink'),
    });
  }

  return sharingOptions;
});

const titleInfo = computed(() => {
  const year = props.activeMoment?.contentYear || '';
  const genre = getFirstElement(props.activeMoment?.genres as Genre[]);

  if (genre) {
    return `${year}, ${genre.title}`;
  }

  return year;
});

const primaryButtonText = computed(() =>
  translateContentButtonText(
    getMyChannelPlayButtonText({
      contentType: props.activeMoment?.contentType,
    }),
  ),
);

const shouldRedirectToOffers = computed(
  () => !isAuth.value || (!isActiveSubscription.value && !isPartnerSubscription.value),
);

const setCurrentLinkedKinom = () => {
  catalogStore.setCurrentLinkedContentKinom(props.activeMoment);
};

const handleWatchButtonClick = () => {
  onGoToItemPage();

  setCurrentLinkedKinom();
};

const getSharingLink = (option?: DropdownOption) => {
  if (!props.activeMoment) {
    return '';
  }

  let link = '';

  if (!option?.value || sharingOptionsList.value.length === 1) {
    link = `${AppRoute.MyChannel}/${props.activeMoment.id}`;
  } else {
    const linksMap: Record<ShareOptions, string> = {
      [ShareOptions.ShareKinom]: `${AppRoute.MyChannel}/${props.activeMoment.id}`,
      [ShareOptions.SharePlaylist]: `${AppRoute.Playlists}/${props.selectedPlaylistId}?${RouteQuery.MomentId}=${props.activeMoment.id}`,
    };
    link = linksMap[option.value as ShareOptions];
  }

  return `${hostName}/${link}`;
};

const shareKinom = (option: DropdownOption) => {
  const link = getSharingLink(option);

  if (!link) {
    return;
  }

  copyToClipboard(link);

  layoutStore.addNotification({
    level: NotificationLevel.Info,
    message: translate('page.myChannel.tooltip.linkCopySuccess'),
    hideTimeoutMs: 3000,
    icon: IconCheckmark,
  });

  isSharingOptionsListShown.value = false;

  emit('on-share', isSharingOptionsListShown.value);
};

const onShareClick = async () => {
  const link = getSharingLink();
  const hasNativeShare = 'share' in window.navigator;

  if (hasNativeShare && sharingOptionsList.value.length === 1 && isMobile) {
    try {
      await window.navigator.share({
        title: document.title,
        url: link,
      });
    } catch (e) {}
  } else {
    isSharingOptionsListShown.value = !isSharingOptionsListShown.value;
  }

  emit('on-share', isSharingOptionsListShown.value);
};

const onPlayerReady = (player: VijuPlayer) => {
  emit('on-ready', player);
};

const onPlay = () => {
  emit('on-play');
};

const onPause = () => {
  emit('on-pause');
};

const onLikeClick = () => {
  emit('on-like');
  myChannelOnboarding.showMyChannelPopup(LocalStorageKey.MyChannelLikePopupShown);
};

const onDislikeClick = () => {
  emit('on-dislike');

  myChannelOnboarding.showMyChannelPopup(LocalStorageKey.MyChannelDislikePopupShown);
  mobileMyChannelViewRef.value?.slideMomentWithUserInteraction();
};

const onRemoveReactionClick = () => {
  emit('on-remove-reaction');
};

const onCollectionAdd = () => {
  emit('on-collection-add');

  myChannelOnboarding.showMyChannelPopup(LocalStorageKey.MyChannelCollectionPopupShown);
};

const onCollectionRemove = () => {
  emit('on-collection-remove');
};

const onChangeMoment = (index: number) => {
  emit('on-change', index);
};

const onLoadMore = () => {
  emit('on-load-more');
};

const onEndedMoment = (player: VijuPlayer, index?: number) => {
  emit('on-ended', index);
  player.setConfigProperty('video.kinom.isPlayButtonHidden', true);
};

const onCanPlay = (player: VijuPlayer) => {
  player.setConfigProperty('video.kinom.isPlayButtonHidden', false);
};

const onClickOutside = () => {
  isSharingOptionsListShown.value = false;
};

const onWatchButtonClick = () => {
  if (!props.activeMoment) {
    return;
  }

  if (shouldRedirectToOffers.value) {
    const modalModePage = route.name === AppRoute.Index ? AnalyticPageName.Main : AnalyticPageName.Item;
    const page = props.isModalMode ? modalModePage : AnalyticPageName.MyChannel;

    subscribeAnalytics.onGotoOfferPage({
      page,
      from: OfferPageFrom.WatchButton,
      itemId: props.activeMoment.contentId,
      kinomId: props.activeMoment.id,
    });
  }

  onGoToItemPage();
};

const onGoToItemPage = () => {
  if (!props.activeMoment) {
    return;
  }

  $contentCacheManager.addToCache(props.activeMoment);

  catalogStore.setCurrentLinkedContentKinom(props.activeMoment);

  const season = props.activeMoment.seasonNumber;
  const episode = props.activeMoment.episodeNumber;
  const isSelectedPlaylistTitleRequired = props.selectedPlaylistTitle && props.isModalMode;

  onGotoItemPage({
    page: getKmzaPageValue(route.name as AppRoute),
    title: props.activeMoment.contentTitle,
    kinomTitle: props.activeMoment.title,
    contentType: props.activeMoment.contentType,
    itemId: props.activeMoment.contentId,
    kinomId: props.activeMoment.id,
    ...(isSelectedPlaylistTitleRequired && { playlistTitle: props.selectedPlaylistTitle }),
    ...(season && { season }),
    ...(episode && { episode }),
    from: props.isModalMode ? ItemPageFrom.Playlist : ItemPageFrom.MyChannel,
    position: props.activeMomentIndex,
  });
};

const getKinomButtonRoute = (): RouteLocationNamedRaw => {
  if (!props.activeMoment) {
    return {};
  }

  if (shouldRedirectToOffers.value) {
    // после авторизации или покупки подписки редиректим сразу на КОНТЕНТ
    const isMovie = props.activeMoment.contentType === MediaContentType.Movie;

    const activeMomentData = getContentRoute(props.activeMoment);

    const movieBackRoute = {
      name: AppRoute.MovieCard,
      params: {
        id: activeMomentData.params?.id,
      },
    };

    const serialBackRoute = {
      name: AppRoute.SerialEpisodeCard,
      params: {
        id: activeMomentData.params?.id,
        seasonId: activeMomentData.params?.seasonId,
        episodeId: activeMomentData.params?.episodeId,
      },
    };

    const backRoute = router.resolve(isMovie ? movieBackRoute : serialBackRoute).fullPath;

    return getOfferPageRoutesToRedirect(backRoute);
  }

  return getContentRoute(props.activeMoment, { isPlayerPage: true });
};
</script>
<style lang="scss" module>
@use '@/assets/breakpoints' as breakpoints;

.myChannelView {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: var(--g-border-round-32);
  overflow: hidden;
}

.mobileMyChannelView {
  display: none;
}

.movieInfo,
.movieInfoSkeleton {
  position: absolute;
  bottom: var(--g-spacing-24);
  left: var(--g-spacing-24);
}

.movieInfo {
  position: absolute;
  bottom: var(--g-spacing-24);
  left: var(--g-spacing-24);
  z-index: 99999;
}

.controls {
  position: absolute;
  bottom: var(--g-spacing-24);
  right: var(--g-spacing-16);
  z-index: 99999;
}

.button,
.buttonSkeletonWrapper {
  display: none;
}

.playerGradient {
  position: absolute;
  bottom: 5px;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 240px;
  background: var(--g-my-channel-player-gradient);
  pointer-events: none;
}

.sharingOptions {
  width: 352px;
  height: fit-content;
}

.sharingOptionsWrapper {
  position: absolute;
  bottom: var(--g-spacing-20);
  right: 64px;
  z-index: var(--z-index-my-channel-sharing-options);
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

@include breakpoints.max-width-1024 {
  .myChannelView {
    border-radius: 0;
  }
  .mobileMyChannelView {
    display: block;
  }

  .desktopMyChannelView {
    display: none;
  }
}

@include breakpoints.max-width-480 {
  .button {
    position: absolute;
    bottom: calc(var(--g-spacing-32));
    left: var(--g-spacing-16);
    right: var(--g-spacing-16);
    z-index: 3;
    display: block;
    width: calc(100% - var(--g-spacing-32));
  }

  .sharingOptions {
    width: 100%;
  }

  .sharingOptionsWrapper {
    right: 0;
    padding: 0 var(--g-spacing-16);
    width: 100%;
    max-width: 100%;
    justify-content: center;
  }

  .buttonSkeletonWrapper {
    display: block;
  }

  .movieInfo,
  .controls {
    bottom: calc(var(--g-spacing-48) + var(--g-spacing-24) + var(--g-spacing-16));
  }

  .movieInfo,
  .movieInfoSkeleton {
    left: var(--g-spacing-16);
  }
}
</style>
