<template>
  <div :class="$style.authInput">
    <app-input
      :ref="(comp) => (loginInputEl = comp?.$el)"
      v-model="loginModel"
      :type="type"
      :autocomplete="autocomplete"
      :name="name"
      :autofocus="true"
      aria-autocomplete="inline"
      :has-clear-icon="loginModel.length > 0"
      :mask-options="inputMaskOptions"
      :placeholder="placeholder"
      :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.Auth, 'loginInput')"
      :button-data-id="getTestElementIdentifier(ElementTestIdentifierScope.Auth, 'clearLoginButton')"
      :error-message="errorMessage"
      @clear="onClearLoginButtonClick"
    />
    <input
      v-if="autocomplete !== 'off'"
      aria-hidden="true"
      :class="$style.hidden"
      name="hiddenPassword"
      tabindex="-1"
      type="password"
    />
  </div>
</template>

<script setup lang="ts">
import { getAuthInputMaskOptions } from '@package/sdk/src/core';

import { ElementTestIdentifierScope, getTestElementIdentifier } from '@/code/e2e-testing/element-test-identifier';
import useVariationVariables from '@/platform/variation/use-variation-variables';

import AppInput from '../ui/AppInput.vue';

const props = withDefaults(
  defineProps<{
    modelValue: string;
    autofocus?: boolean;
    name?: 'login';
    type?: 'email' | 'text';
    autocomplete?: 'email' | 'new-password' | 'current-password' | 'off';
    placeholder?: string;
  }>(),
  {
    type: 'text',
    name: 'login',
    autocomplete: 'email',
    autofocus: false,
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const { appVariation } = useVariationVariables();

const inputMaskOptions = getAuthInputMaskOptions(appVariation);

const errorMessage = ref('');
const loginInputEl = ref<HTMLInputElement>();

defineExpose({
  errorMessage,
});

const onClearLoginButtonClick = () => {
  loginModel.value = '';
  errorMessage.value = '';

  loginInputEl.value?.focus();
};

const loginModel = computed({
  get: () => props.modelValue,
  set: (val: string) => emit('update:modelValue', val),
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;

.authInput {
  position: relative;
  width: 100%;
  margin-bottom: var(--g-spacing-16);
}

.authClear {
  position: absolute;
  top: 20px;
  right: 15px;
}

.loginError {
  width: 100%;
  color: var(--color-text-negative);

  @include fonts.WebBody-2;
}

.hidden {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
</style>
