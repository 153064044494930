<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6 7.98437C5.45029 8.60718 4.64602 9 3.75 9C2.09315 9 0.75 7.65685 0.75 6C0.75 4.34315 2.09315 3 3.75 3C4.64602 3 5.45029 3.39282 6 4.01563C6.54971 3.39282 7.35398 3 8.25 3C9.90685 3 11.25 4.34315 11.25 6C11.25 7.65685 9.90685 9 8.25 9C7.35398 9 6.54971 8.60718 6 7.98437Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 10.9766C7.17544 11.9108 5.96903 12.5 4.625 12.5C2.13972 12.5 0.125 10.4853 0.125 8C0.125 5.51472 2.13972 3.5 4.625 3.5C5.96903 3.5 7.17544 4.08922 8 5.02345C8.82456 4.08922 10.031 3.5 11.375 3.5C13.8603 3.5 15.875 5.51472 15.875 8C15.875 10.4853 13.8603 12.5 11.375 12.5C10.031 12.5 8.82456 11.9108 8 10.9766Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 15.9687C10.9006 17.2144 9.29204 18 7.5 18C4.18629 18 1.5 15.3137 1.5 12C1.5 8.68629 4.18629 6 7.5 6C9.29204 6 10.9006 6.78563 12 8.03126C13.0994 6.78563 14.708 6 16.5 6C19.8137 6 22.5 8.68629 22.5 12C22.5 15.3137 19.8137 18 16.5 18C14.708 18 13.0994 17.2144 12 15.9687Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16 21.2916C14.5341 22.9525 12.3894 24 10 24C5.58172 24 2 20.4183 2 16C2 11.5817 5.58172 8 10 8C12.3894 8 14.5341 9.04751 16 10.7084C17.4659 9.04751 19.6106 8 22 8C26.4183 8 30 11.5817 30 16C30 20.4183 26.4183 24 22 24C19.6106 24 17.4659 22.9525 16 21.2916Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import AppIcon from './AppIcon.vue';
import { type IconSize, getBoundsByIconSize, getSVGViewboxByIconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
