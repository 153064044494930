<template>
  <div
    ref="dropdownEl"
    :class="$style.dropdownWrapper"
    @keydown.up.prevent="onArrowUpPress"
    @keydown.down.prevent="onArrowDownPress"
    @keydown.enter="onDropdownClick"
  >
    <div
      ref="dropdownHeadEl"
      :class="{
        [$style.dropdownHead]: true,
        [$style.dropdownHeadSecondary]: variant === 'secondary',
      }"
      tabindex="0"
      @click="onDropdownClick"
    >
      <img
        v-if="isLeadingIconShown && !hasImageError"
        :src="option?.icon"
        :class="$style.leadingIcon"
        :alt="$t('alt.dropdown')"
      />
      <dropdown-item-default-icon v-else-if="isLeadingIconShown && hasImageError" />

      <p
        ref="labelWrapperEl"
        :title="option?.label || selectedOptionLabels"
        :class="{ [$style.selectedOption]: true, [$style.selectedOptionMargin]: isLeadingIconShown }"
      >
        <span ref="labelEl" :class="{ [$style.labelAnimating]: isAnimationInProgress && isMobile }">
          {{ multiselect ? selectedOptionLabels : option?.label }}
        </span>
      </p>

      <app-slot-button v-if="multiselect && selectedOptions?.length" @click="onDropdownClearClick">
        <icon-clear size="small" :class="$style.iconClear" />
      </app-slot-button>

      <div v-else-if="options.length > 1" :class="$style.trailingIcon">
        <icon-chevron size="small" :direction="isDropdownOpened ? 'up' : 'down'" />
      </div>
    </div>

    <transition name="fade">
      <div
        v-show="isDropdownOpened"
        :class="{
          [$style.optionsWrapper]: true,
          [$style.optionsWrapperSecondary]: variant === 'secondary',
        }"
      >
        <div v-if="hasSearchInput" :class="$style.input">
          <app-input
            ref="searchInputEl"
            v-model="searchQueryText"
            tabindex="0"
            :placeholder="inputPlaceholder || undefined"
            variation="secondary"
            :has-clear-icon="searchQueryText.length > 0"
            size="small"
            @clear="onInputClearClick"
            @update:model-value="onInput"
          >
            <template #leadingIcon>
              <icon-search size="small" :filled="false" />
            </template>
          </app-input>
        </div>

        <div
          ref="optionsEl"
          :class="$style.options"
          tabindex="0"
          :style="{
            maxHeight,
          }"
        >
          <app-dropdown-item
            v-for="(opt, index) in options"
            :key="opt.value"
            :option="opt"
            :is-selected="checkIsOptionSelected(opt)"
            :is-dropdown-opened="isDropdownOpened"
            :with-fade="withFade"
            tabindex="0"
            :variant="variant"
            :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.Common, 'dropdownItem')"
            @click="selectOption(opt, index)"
            @keydown.enter.stop="selectOption(opt, index)"
          />
        </div>

        <p v-if="options?.length === 0" :class="$style.noResults">{{ $t('dropdown.noResults') }}</p>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import ConstantsConfigInstanceWeb from '@package/constants/code/constants-config-web';
import { DisposableStore, KeyCode, timeout, toPixel, UnexpectedComponentStateError } from '@package/sdk/src/core';
import { onClickOutside, useElementSize } from '@vueuse/core';

import { ElementTestIdentifierScope, getTestElementIdentifier } from '@/code/e2e-testing/element-test-identifier';
import useMobile from '@/platform/layout/use-mobile';
import { type DropdownOption } from '@/stores/use-layout-store';

import useImageLoadError from '../../platform/image/handle-load-error';
import IconChevron from '../icons/IconChevron.vue';
import IconClear from '../icons/IconClear.vue';
import IconSearch from '../icons/IconSearch.vue';
import AppInput from '../ui/AppInput.vue';
import AppSlotButton from '../ui/AppSlotButton.vue';
import AppDropdownItem from './AppDropdownItem.vue';
import DropdownItemDefaultIcon from './DropdownItemDefaultIcon.vue';

const emit = defineEmits<{
  (e: 'update:option', option: DropdownOption[] | DropdownOption): void;
  (e: 'update:search', searchValue: string): void;
  (e: 'select', option: DropdownOption | DropdownOption[]): void;
  (e: 'input', searchValue: string): void;
  (e: 'header-click'): void;
}>();

const props = withDefaults(
  defineProps<{
    options: DropdownOption[];
    withFade?: boolean;
    option?: DropdownOption;
    multiselect?: boolean;
    closeOnOptionClick?: boolean;
    hasSearchInput?: boolean;
    inputPlaceholder?: string;
    variant?: 'primary' | 'secondary';
    selectedOptions?: DropdownOption[];
    parentElementRef?: HTMLDivElement;
    diffHeight?: number;
    maxOptionsHeight?: number;
  }>(),
  {
    closeOnOptionClick: true,
    hasSearchInput: false,
    inputPlaceholder: '',
    variant: 'primary',
    multiselect: false,
    withFade: false,
    selectedOptions: () => [],
    maxOptionsHeight: 300,
  },
);

const isMobile = useMobile();

const { $keyboardHandler } = useNuxtApp();
const disposableStore = new DisposableStore();

const dropdownEl = ref<HTMLDivElement>();
const optionsEl = ref<HTMLDivElement>();
const labelWrapperEl = ref<HTMLDivElement>();
const labelEl = ref<HTMLDivElement>();
const dropdownHeadEl = ref<HTMLElement>();
const searchInputEl = ref<
  ComponentPublicInstance<{
    inputEl: HTMLInputElement;
  }>
>();

const labelTranslate = ref(0);
const isAnimationInProgress = ref(true);

const isDropdownOpened = ref(false);
const selectedMultiselectOptions = ref<DropdownOption[]>(props.selectedOptions);

const { height } = useElementSize(props.parentElementRef);

const labelTranslatePx = computed(() => `-${toPixel(labelTranslate.value)}`);
const translateDuration = computed(
  () => `${labelTranslate.value / ConstantsConfigInstanceWeb.getProperty('dropdownTitleSpeedCoefficient')}s`,
);

const maxHeight = computed(() => {
  if (props.parentElementRef && props.diffHeight && props.maxOptionsHeight && height.value) {
    const maxHeight = height.value - props.diffHeight;
    const isBiggerThanMaxAllowedHeight = maxHeight > props.maxOptionsHeight ? props.maxOptionsHeight : maxHeight;

    return toPixel(isBiggerThanMaxAllowedHeight);
  }

  return toPixel(props.maxOptionsHeight);
});

const selectedOption = ref<DropdownOption>(props.options[0]);
const selectedOptionIndex = ref<number>(0);
const searchQueryText = ref('');
const isImageLoadingError = ref(false);

const focusedOption = ref<HTMLElement>();

const isLeadingIconShown = computed(
  () => props.variant === 'primary' && selectedOption.value?.icon && !props.multiselect,
);

const selectedOptionLabels = computed(() => {
  return props.selectedOptions?.map((option: DropdownOption) => option.label).join(', ');
});

const isSingleItemInDropdown = computed(() => props.options.length === 1);

const { hasImageError, updatePath } = useImageLoadError(props.option?.icon || '');

const checkIsOptionSelected = (option: DropdownOption) => {
  if (props.multiselect) {
    return Boolean(props.selectedOptions?.find((selectedOption) => option.value === selectedOption.value));
  }

  return option.value === props.option?.value;
};

const emitSearchValue = () => {
  emit('update:search', searchQueryText.value);
  emit('input', searchQueryText.value);
};

const resetSearchValue = () => {
  searchQueryText.value = '';

  emitSearchValue();
};

const onDropdownClearClick = (e: Event) => {
  e.stopPropagation();

  selectedMultiselectOptions.value = [];

  emit('update:option', selectedMultiselectOptions.value);
};

const onInputClearClick = () => {
  resetSearchValue();
};

const onInput = () => {
  emitSearchValue();
};

const getDropdownOptions = () => {
  const options = optionsEl.value?.children;

  if (!options) {
    return [];
  }

  return options;
};

const onDropdownClick = () => {
  if (isSingleItemInDropdown.value) {
    return;
  }

  isDropdownOpened.value = !isDropdownOpened.value;

  if (focusedOption.value) {
    focusedOption.value = undefined;
  }

  emit('header-click');
};

const selectOption = async (option: DropdownOption, index: number) => {
  if (props.option && props.option.value === option.value) {
    closeDropdown();

    return;
  }

  if (props.multiselect) {
    if (props.selectedOptions.find((selectedOption) => option.value === selectedOption.value)) {
      const optionIndex = selectedMultiselectOptions.value.findIndex(
        (opt: DropdownOption) => opt.value === option.value,
      );
      selectedMultiselectOptions.value.splice(optionIndex, 1);
    } else {
      selectedMultiselectOptions.value.push(option);
    }
  } else {
    selectedOption.value = option;
    selectedOptionIndex.value = index;
  }

  const focusedElement = getDropdownOptions()[index];

  if (!focusedElement) {
    return;
  }

  focusedOption.value = focusedElement as HTMLElement;

  isImageLoadingError.value = false;

  const valueToEmit = props.multiselect ? selectedMultiselectOptions.value : selectedOption.value;
  emit('update:option', valueToEmit);
  emit('select', valueToEmit);

  // для мобилки для длинных названий нужно показывать бегущую строку
  // здесь рассчитываем translateX для транзишена и рестартим анимацию
  if (isMobile) {
    await calculateLabelTranslateInPx();
    await restartAnimation();
  }

  if (props.closeOnOptionClick && !props.multiselect) {
    closeDropdown();
  }
};

const onArrowDownPress = () => {
  if (!isDropdownOpened.value) {
    return;
  }

  const isInputBlurred = props.hasSearchInput && document.activeElement !== searchInputEl.value?.inputEl;

  if (isInputBlurred && !focusedOption.value) {
    focusInput();
    focusedOption.value = undefined;

    return;
  }

  if (!focusedOption.value) {
    focusedOption.value = getDropdownOptions()[0] as HTMLElement;
    focusedOption.value?.focus();

    return;
  }

  const nextEl = focusedOption.value.nextElementSibling as HTMLElement;

  if (nextEl) {
    focusedOption.value = nextEl;
    nextEl.focus();
  }
};

const onArrowUpPress = () => {
  const isDropdownHeadFocused = document.activeElement === (dropdownHeadEl.value as Element);

  if (!isDropdownOpened.value || isDropdownHeadFocused || !dropdownHeadEl.value) {
    return;
  }

  const prevEl = focusedOption.value?.previousElementSibling as HTMLElement;
  const isInputBlurred = document.activeElement !== searchInputEl.value?.inputEl;

  if (prevEl) {
    focusedOption.value = prevEl;
    prevEl.focus();
  } else if (searchInputEl.value && isInputBlurred) {
    focusInput();
    focusedOption.value = undefined;
  } else {
    dropdownHeadEl.value.focus();
    focusedOption.value = undefined;
  }
};

const focusInput = () => {
  const input = searchInputEl.value?.inputEl as HTMLInputElement;

  if (!input) {
    throw new UnexpectedComponentStateError('input');
  }

  input.focus();
};

const closeDropdown = () => {
  isDropdownOpened.value = false;
  focusedOption.value = undefined;
};

const calculateLabelTranslateInPx = async () => {
  if (!isMobile || !labelEl.value || !labelWrapperEl.value) {
    return;
  }

  await nextTick();

  // добавляем ширину фейда, чтобы показывался полный тайтл
  const translateX =
    labelEl.value.offsetWidth -
    labelWrapperEl.value.offsetWidth +
    ConstantsConfigInstanceWeb.getProperty('dropdownFadeWidthPx');

  if (translateX <= 0) {
    labelTranslate.value = 0;
    return;
  }

  labelTranslate.value = translateX;
};

const restartAnimation = async () => {
  if (!labelEl.value) {
    return;
  }

  isAnimationInProgress.value = false;
  await timeout(50);
  isAnimationInProgress.value = true;
};

watch(selectedOption, () => updatePath(props.option?.icon || ''), { immediate: true });

watch(
  () => props.selectedOptions,
  (val) => {
    selectedMultiselectOptions.value = val;
  },
  { immediate: true },
);

onClickOutside(dropdownEl, closeDropdown);

defineExpose({
  resetSearchValue,
});

onMounted(async () => {
  await calculateLabelTranslateInPx();

  disposableStore.add($keyboardHandler.on(KeyCode.Escape, closeDropdown));
});

onBeforeUnmount(() => {
  disposableStore.dispose();
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;

$dropdownHeight: 56px;
$dropdownHeightSecondary: 48px;

.dropdownWrapper {
  position: relative;
}

.dropdownHead {
  display: flex;
  align-items: center;
  padding: var(--g-spacing-12);
  min-height: 48px;
  border-radius: var(--g-border-round-12);
  background-color: var(--color-bg-field);
  justify-content: space-between;
  cursor: pointer;
}

.dropdownHeadSecondary {
  padding: var(--g-spacing-12) var(--g-spacing-16);
}

.input {
  padding: var(--g-spacing-16);
}

.iconClear {
  color: var(--color-icon-primary);
}

.selectedOption {
  margin: 0 var(--g-spacing-16) 0 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;

  @include fonts.WebHeadline-2;

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: var(--g-spacing-48);
    width: 50px;
    height: 48px;
    background: var(--gradient-fade-dark);
    content: '';
  }
}

.selectedOptionMargin {
  margin-left: var(--g-spacing-16);
}

.labelAnimating {
  display: inline-block;
  animation-name: marquee;
  animation-timing-function: linear;
  animation-duration: v-bind(translateDuration);
}

.leadingIcon {
  width: 24px;
  height: 24px;
}

.trailingIcon {
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  transition: transform 0.4s ease;
}

.optionsWrapper {
  position: absolute;
  top: calc($dropdownHeight + var(--g-spacing-16));
  z-index: 10;
  width: 100%;
  border-radius: var(--g-spacing-16);
  background-color: var(--color-bg-secondary);
  overflow: hidden;
}

.optionsWrapperSecondary {
  top: calc($dropdownHeightSecondary + var(--g-spacing-16));
}

.options {
  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-bg-tertiary);
  }
}

.noResults {
  padding: var(--g-spacing-16);
  color: var(--color-text-primary);
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(v-bind(labelTranslatePx));
  }
  100% {
    transform: translateX(0%);
  }
}
</style>
