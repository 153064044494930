<template>
  <app-button
    :aria-label="$t('ariaLabel.header.promocodeButton')"
    :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Header, 'actionButton')"
    :text="actionButtonText"
    size="small"
    variation="button-primary"
    @click="onActionButtonClick"
  />
</template>

<script setup lang="ts">
import { OfferPageFrom, useAuthPageAnalytics, useSubscribeAnalytics } from '@SDK/analytics';

import getKmzaPageValue from '@/code/kmza/get-kmza-page-value';
import AppButton from '@/components/ui/AppButton.vue';
import { CookieName } from '@/platform/cookies/cookies';
import useAppCookie from '@/platform/cookies/use-app-cookie';
import { RouteQuery } from '@/platform/router/query';
import { AppRoute } from '@/platform/router/routes';
import { useOffersStore } from '@/stores/use-offers-store';
import { useSessionStore } from '@/stores/use-session-store';

const { $analyticSender } = useNuxtApp();

const offersStore = useOffersStore();
const sessionStore = useSessionStore();

const { currentOffer } = storeToRefs(offersStore);
const { isAuth } = storeToRefs(sessionStore);

const subscribeAnalytics = useSubscribeAnalytics($analyticSender);
const { onGotoAuthPage } = useAuthPageAnalytics($analyticSender);

const promocodeCookie = useAppCookie(CookieName.Promocode);
const actionButtonText = computed(() => currentOffer.value.ctaText);
const currentRoute = useRoute();

const onActionButtonClick = () => {
  const unAuthUserRoute = {
    name: AppRoute.AuthEnter,
    query: { [RouteQuery.BackRoute]: currentRoute.fullPath },
  };
  const page = getKmzaPageValue(currentRoute.name as AppRoute);

  const authUserRoute = promocodeCookie.value
    ? {
        name: AppRoute.OffersPromo,
        params: { promocode: promocodeCookie.value },
        query: { [RouteQuery.BackRoute]: currentRoute.fullPath },
      }
    : { name: AppRoute.Offers, query: { [RouteQuery.BackRoute]: currentRoute.fullPath } };

  if (isAuth.value) {
    subscribeAnalytics.onGotoOfferPage({
      page,
      from: OfferPageFrom.ActionButton,
    });
  } else {
    onGotoAuthPage(page, 'action_button');
  }

  return navigateTo(isAuth.value ? authUserRoute : unAuthUserRoute);
};
</script>
