<template>
  <div :class="$style.movieInfo">
    <app-link v-if="poster" :to="contentLink" @click="onLinkClick">
      <app-img
        :src="poster"
        :alt="title"
        :image-class="$style.poster"
        lazy
        :width="200"
        :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.MyChannel, 'contentPoster')"
      />
    </app-link>

    <app-skeleton v-else :class="$style.poster" />

    <div :class="$style.infoWrapper">
      <div :class="$style.textInfo">
        <app-link :class="$style.titleLink" :to="contentLink" @click="onLinkClick">
          <p
            :class="$style.title"
            :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.MyChannel, 'contentTitle')"
          >
            {{ title }}
          </p>
        </app-link>
        <p
          :class="$style.titleInfo"
          :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.MyChannel, 'contentDescription')"
        >
          {{ titleInfo }}
        </p>
      </div>
      <app-link :to="link">
        <app-button
          :text="buttonText"
          :class="$style.button"
          :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.MyChannel, 'kinomPrimaryButton')"
          @click="onWatchButtonClick"
        />
      </app-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { RouteLocationNamedRaw } from 'vue-router';

import { ElementTestIdentifierScope, getTestElementIdentifier } from '@/code/e2e-testing/element-test-identifier';
import AppSkeleton from '@/components/skeleton/AppSkeleton.vue';
import AppButton from '@/components/ui/AppButton.vue';
import AppImg from '@/components/ui/AppImg.vue';
import AppLink from '@/components/ui/AppLink.vue';
import { useLayoutStore } from '@/stores/use-layout-store';

const props = defineProps<{
  link: RouteLocationNamedRaw;
  contentLink: RouteLocationNamedRaw;
  title: string;
  titleInfo: string | number;
  buttonText: string;
  poster?: string;
  isModalMode?: boolean;
}>();

const emit = defineEmits<{
  (e: 'on-go-to-item-click'): void;
  (e: 'on-watch-button-click'): void;
}>();

const layoutStore = useLayoutStore();

const onLinkClick = () => {
  if (props.isModalMode) {
    layoutStore.setCurrentModalName(null);
  }

  emit('on-go-to-item-click');
};

const onWatchButtonClick = () => {
  emit('on-watch-button-click');
};
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '@/assets/breakpoints' as breakpoints;

.movieInfo {
  display: grid;
  align-items: center;
  grid-template-columns: 80px 1fr;
  column-gap: var(--g-spacing-16);
}

.infoWrapper {
  display: flex;
  flex-direction: column;
}

.textInfo {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--g-spacing-16);
}

.titleLink {
  display: inline;

  @include fonts.WebSubtitle-2;
}

.title {
  display: inline;
  margin-right: var(--g-spacing-6);
  max-width: 259px;
}

.titleInfo {
  color: var(--color-notheme-white-40);

  @include fonts.WebLabel-3;
}

.poster {
  display: block;
  height: 120px;
  border-radius: var(--g-border-round-16);
  overflow: hidden;
  object-fit: cover;
}

.button {
  width: fit-content;
}

@include breakpoints.max-width-1024 {
  .movieInfo {
    max-width: calc(100vw - 96px);
  }
}

@include breakpoints.max-width-480 {
  .movieInfo {
    grid-template-columns: 40px 1fr;
  }

  .poster {
    width: 40px;
    height: 60px;
    border-radius: var(--g-border-round-8);
  }

  .textInfo {
    margin-bottom: 0;
  }

  .title {
    @include fonts.WebSubtitle-2;
  }

  .button {
    display: none;
  }

  .infoWrapper {
    align-items: center;
  }
}
</style>
