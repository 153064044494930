<template>
  <abstract-my-channel-popup :on-close="onClose">
    <p :class="$style.title">{{ $t('header.myChannel.registration.title') }}</p>
    <p :class="$style.text">{{ $t('header.myChannel.registration.description') }}</p>

    <app-link :class="$style.link" :to="{ name: $AppRoute.AuthEnter, query: { [$RouteQuery.BackRoute]: backUrl } }">
      {{ $t('page.myChannel.register') }}
    </app-link>
    <app-slot-button :class="$style.close" @click="onClose()">
      <icon-close size="small"></icon-close>
    </app-slot-button>
  </abstract-my-channel-popup>
</template>

<script lang="ts" setup>
import IconClose from '../icons/IconClose.vue';
import AppLink from '../ui/AppLink.vue';
import AppSlotButton from '../ui/AppSlotButton.vue';
import AbstractMyChannelPopup from './AbstractMyChannelPopup.vue';

defineProps<{
  onClose: VoidFunction;
}>();

const route = useRoute();

const backUrl = computed(() => route.fullPath);
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;

.title {
  margin-bottom: var(--g-spacing-12);

  @include fonts.WebSubtitle-2;
}

.text {
  margin-bottom: var(--g-spacing-8);

  @include fonts.WebBody-2;
}

.link {
  display: block;
  padding: var(--g-spacing-12) 0;
  color: var(--color-text-link);

  @include fonts.WebLabel-2;

  &:hover {
    color: var(--color-states-link-hover);
  }
}

.close {
  position: absolute;
  top: var(--g-spacing-20);
  right: var(--g-spacing-20);
}
</style>
