<template>
  <div
    :class="{
      [$style.myChannelPopups]: true,
      [$style.myChannelPopupsMobile]: isMobile,
    }"
  >
    <my-channel-watch-and-rate-popup
      v-if="myChannelPopupState[LocalStorageKey.MyChannelWatchAndRatePopupShown]"
      key="rate"
      :on-close="() => closeMyChannelPopup(LocalStorageKey.MyChannelWatchAndRatePopupShown)"
    />
    <my-channel-like-popup
      v-if="myChannelPopupState[LocalStorageKey.MyChannelLikePopupShown]"
      key="like"
      :on-close="() => closeMyChannelPopup(LocalStorageKey.MyChannelLikePopupShown)"
    />
    <my-channel-dislike-popup
      v-if="myChannelPopupState[LocalStorageKey.MyChannelDislikePopupShown]"
      key="dislike"
      :on-close="() => closeMyChannelPopup(LocalStorageKey.MyChannelDislikePopupShown)"
    />
    <my-channel-collections-popup
      v-if="myChannelPopupState[LocalStorageKey.MyChannelCollectionPopupShown]"
      key="collections"
      :on-close="() => closeMyChannelPopup(LocalStorageKey.MyChannelCollectionPopupShown)"
    />
    <my-channel-registration-popup
      v-if="myChannelPopupState[LocalStorageKey.MyChannelRegistrationPopupShown]"
      key="registration"
      :on-close="() => closeMyChannelPopup(LocalStorageKey.MyChannelRegistrationPopupShown)"
    />
  </div>
</template>

<script setup lang="ts">
import useMyChannelOnboarding from '../../code/my-channel/use-my-channel-onboarding';
import useMobile from '../../platform/layout/use-mobile';
import { LocalStorageKey } from '../../platform/storage/local-storage';
import MyChannelCollectionsPopup from '../popups/MyChannelCollectionsPopup.vue';
import MyChannelDislikePopup from '../popups/MyChannelDislikePopup.vue';
import MyChannelLikePopup from '../popups/MyChannelLikePopup.vue';
import MyChannelRegistrationPopup from '../popups/MyChannelRegistrationPopup.vue';
import MyChannelWatchAndRatePopup from '../popups/MyChannelWatchAndRatePopup.vue';

const { myChannelPopupState, showMyChannelPopup, closeMyChannelPopup } = useMyChannelOnboarding();
const isMobile = useMobile();

onMounted(() => {
  showMyChannelPopup(LocalStorageKey.MyChannelWatchAndRatePopupShown);
});
</script>

<style module lang="scss">
.myChannelPopups {
  position: absolute;
  bottom: var(--g-spacing-24);
  right: 75px;
  z-index: 999999;
  display: grid;
  grid-gap: var(--g-spacing-10);
}

.myChannelPopupsMobile {
  bottom: var(--g-spacing-20);
  left: var(--g-spacing-16);
  right: var(--g-spacing-16);
}
</style>
