<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.5 10H9.50005V8.87502H10.2C10.7799 8.87502 11.25 8.4273 11.25 7.87502L11.25 4.37502C11.25 3.82273 10.7799 3.37502 10.2 3.37502H9.50005L9.50005 2H10.5C10.6327 2 10.7598 1.94732 10.8536 1.85355C10.9474 1.75979 11 1.63261 11 1.5C11 1.36739 10.9474 1.24021 10.8536 1.14645C10.7598 1.05268 10.6327 1 10.5 1H1.50005C1.36744 1 1.24026 1.05268 1.14649 1.14645C1.05273 1.24021 1.00005 1.36739 1.00005 1.5C1.00005 1.63261 1.05273 1.75979 1.14649 1.85355C1.24026 1.94732 1.36744 2 1.50005 2H2.50005L2.50005 3.37502H1.8C1.2201 3.37502 0.75 3.82273 0.75 4.37502L0.750018 7.87502C0.750018 8.4273 1.22012 8.87502 1.80002 8.87502H2.50005L2.50005 10H1.50005C1.36744 10 1.24026 10.0527 1.14649 10.1464C1.05273 10.2402 1.00005 10.3674 1.00005 10.5C1.00005 10.6326 1.05273 10.7598 1.14649 10.8536C1.24026 10.9473 1.36744 11 1.50005 11H10.5C10.6327 11 10.7598 10.9473 10.8536 10.8536C10.9474 10.7598 11 10.6326 11 10.5C11 10.3674 10.9474 10.2402 10.8536 10.1464C10.7598 10.0527 10.6327 10 10.5 10ZM8.50005 8.87502H6.50005V10H8.50005V8.87502ZM5.50005 8.87502H3.50005L3.50005 10H5.50005L5.50005 8.87502ZM8.50005 2L8.50005 3.37502H6.50005L6.50005 2H8.50005ZM5.50005 2L5.50005 3.37502H3.50005L3.50005 2H5.50005ZM5.27499 7.195C5.34465 7.23069 5.42172 7.24953 5.49999 7.25C5.60817 7.25 5.71344 7.21491 5.79999 7.15L6.79999 6.4C6.86209 6.35343 6.91249 6.29303 6.9472 6.22361C6.98192 6.15418 6.99999 6.07762 6.99999 6C6.99999 5.92238 6.98192 5.84582 6.9472 5.77639C6.91249 5.70697 6.86209 5.64657 6.79999 5.6L5.79999 4.85C5.7257 4.79429 5.63737 4.76036 5.54489 4.75202C5.45241 4.74368 5.35943 4.76126 5.27638 4.80279C5.19333 4.84431 5.12348 4.90815 5.07466 4.98713C5.02585 5.06612 4.99999 5.15714 4.99999 5.25V6.75C5.00027 6.84233 5.02611 6.93279 5.07465 7.01133C5.12319 7.08988 5.19253 7.15345 5.27499 7.195Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.0001 13.3335H12.6667V11.8335H13.6C14.3732 11.8335 15 11.2366 15 10.5002L15 5.83352C15 5.09714 14.3732 4.50018 13.6 4.50018H12.6667L12.6667 2.66683H14.0001C14.1769 2.66683 14.3464 2.59659 14.4715 2.47157C14.5965 2.34654 14.6667 2.17697 14.6667 2.00016C14.6667 1.82335 14.5965 1.65378 14.4715 1.52876C14.3464 1.40373 14.1769 1.3335 14.0001 1.3335H2.00007C1.82325 1.3335 1.65368 1.40373 1.52866 1.52876C1.40364 1.65378 1.3334 1.82335 1.3334 2.00016C1.3334 2.17697 1.40364 2.34654 1.52866 2.47157C1.65368 2.59659 1.82325 2.66683 2.00007 2.66683H3.3334L3.3334 4.50018H2.4C1.6268 4.50018 1 5.09714 1 5.83352L1.00002 10.5002C1.00002 11.2366 1.62683 11.8335 2.40002 11.8335H3.3334L3.3334 13.3335H2.00007C1.82325 13.3335 1.65368 13.4037 1.52866 13.5288C1.40364 13.6538 1.3334 13.8234 1.3334 14.0002C1.3334 14.177 1.40364 14.3465 1.52866 14.4716C1.65368 14.5966 1.82325 14.6668 2.00007 14.6668H14.0001C14.1769 14.6668 14.3464 14.5966 14.4715 14.4716C14.5965 14.3465 14.6667 14.177 14.6667 14.0002C14.6667 13.8234 14.5965 13.6538 14.4715 13.5288C14.3464 13.4037 14.1769 13.3335 14.0001 13.3335ZM11.3334 11.8335H8.66673L8.66673 13.3335H11.3334V11.8335ZM7.3334 11.8335H4.66673L4.66673 13.3335H7.3334L7.3334 11.8335ZM11.3334 2.66683L11.3334 4.50018H8.66673L8.66673 2.66683H11.3334ZM7.3334 2.66683L7.3334 4.50018H4.66673L4.66673 2.66683H7.3334ZM7.03332 9.5935C7.1262 9.64109 7.22896 9.6662 7.33332 9.66683C7.47756 9.66683 7.61792 9.62004 7.73332 9.5335L9.06665 8.5335C9.14945 8.4714 9.21665 8.39088 9.26294 8.29831C9.30922 8.20573 9.33332 8.10366 9.33332 8.00016C9.33332 7.89667 9.30922 7.79459 9.26294 7.70202C9.21665 7.60945 9.14945 7.52893 9.06665 7.46683L7.73332 6.46683C7.63427 6.39254 7.5165 6.34731 7.39319 6.33619C7.26988 6.32507 7.14591 6.34851 7.03517 6.40388C6.92444 6.45925 6.83131 6.54436 6.76622 6.64968C6.70113 6.75499 6.66665 6.87636 6.66665 7.00016V9.00016C6.66702 9.12327 6.70148 9.24388 6.7662 9.34861C6.83092 9.45333 6.92337 9.5381 7.03332 9.5935Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.0001 20H19.0001V17.75H20.4C21.5598 17.75 22.5 16.8546 22.5 15.75L22.5 8.75003C22.5 7.64546 21.5598 6.75003 20.4 6.75003H19.0001L19.0001 4H21.0001C21.2653 4 21.5197 3.89464 21.7072 3.70711C21.8947 3.51957 22.0001 3.26522 22.0001 3C22.0001 2.73478 21.8947 2.48043 21.7072 2.29289C21.5197 2.10536 21.2653 2 21.0001 2H3.0001C2.73488 2 2.48053 2.10536 2.29299 2.29289C2.10545 2.48043 2.0001 2.73478 2.0001 3C2.0001 3.26522 2.10545 3.51957 2.29299 3.70711C2.48053 3.89464 2.73488 4 3.0001 4H5.0001L5.0001 6.75003H3.6C2.4402 6.75003 1.5 7.64546 1.5 8.75003L1.50004 15.75C1.50004 16.8546 2.44024 17.75 3.60004 17.75H5.0001L5.0001 20H3.0001C2.73488 20 2.48053 20.1054 2.29299 20.2929C2.10545 20.4804 2.0001 20.7348 2.0001 21C2.0001 21.2652 2.10545 21.5196 2.29299 21.7071C2.48053 21.8946 2.73488 22 3.0001 22H21.0001C21.2653 22 21.5197 21.8946 21.7072 21.7071C21.8947 21.5196 22.0001 21.2652 22.0001 21C22.0001 20.7348 21.8947 20.4804 21.7072 20.2929C21.5197 20.1054 21.2653 20 21.0001 20ZM17.0001 17.75H13.0001L13.0001 20H17.0001V17.75ZM11.0001 17.75H7.0001L7.0001 20H11.0001V17.75ZM17.0001 4L17.0001 6.75003H13.0001L13.0001 4H17.0001ZM11.0001 4L11.0001 6.75003H7.0001L7.0001 4H11.0001ZM10.55 14.39C10.6893 14.4614 10.8434 14.4991 11 14.5C11.2163 14.5 11.4269 14.4298 11.6 14.3L13.6 12.8C13.7242 12.7069 13.825 12.5861 13.8944 12.4472C13.9638 12.3084 14 12.1552 14 12C14 11.8448 13.9638 11.6916 13.8944 11.5528C13.825 11.4139 13.7242 11.2931 13.6 11.2L11.6 9.7C11.4514 9.58857 11.2747 9.52072 11.0898 9.50404C10.9048 9.48736 10.7189 9.52252 10.5528 9.60557C10.3867 9.68863 10.247 9.81629 10.1493 9.97427C10.0517 10.1322 9.99998 10.3143 9.99998 10.5V13.5C10.0005 13.6847 10.0522 13.8656 10.1493 14.0227C10.2464 14.1798 10.3851 14.3069 10.55 14.39Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M28.0001 26.6665H25.3335V23.6665H27.2C28.7464 23.6665 30 22.4726 30 20.9999L30 11.6665C30 10.1938 28.7464 8.99988 27.2 8.99988H25.3335L25.3335 5.33317H28.0001C28.3538 5.33317 28.6929 5.1927 28.9429 4.94265C29.193 4.6926 29.3335 4.35346 29.3335 3.99984C29.3335 3.64622 29.193 3.30708 28.9429 3.05703C28.6929 2.80698 28.3538 2.6665 28.0001 2.6665H4.00013C3.64651 2.6665 3.30737 2.80698 3.05732 3.05703C2.80727 3.30708 2.6668 3.64622 2.6668 3.99984C2.6668 4.35346 2.80727 4.6926 3.05732 4.94265C3.30737 5.1927 3.64651 5.33317 4.00013 5.33317H6.6668L6.6668 8.99988H4.8C3.2536 8.99988 2 10.1938 2 11.6665L2.00005 20.9999C2.00005 22.4726 3.25365 23.6665 4.80005 23.6665H6.6668L6.6668 26.6665H4.00013C3.64651 26.6665 3.30737 26.807 3.05732 27.057C2.80727 27.3071 2.6668 27.6462 2.6668 27.9998C2.6668 28.3535 2.80727 28.6926 3.05732 28.9426C3.30737 29.1927 3.64651 29.3332 4.00013 29.3332H28.0001C28.3538 29.3332 28.6929 29.1927 28.9429 28.9426C29.193 28.6926 29.3335 28.3535 29.3335 27.9998C29.3335 27.6462 29.193 27.3071 28.9429 27.057C28.6929 26.807 28.3538 26.6665 28.0001 26.6665ZM22.6668 23.6665H17.3335L17.3335 26.6665H22.6668V23.6665ZM14.6668 23.6665H9.33346L9.33346 26.6665H14.6668L14.6668 23.6665ZM22.6668 5.33317L22.6668 8.99988H17.3335L17.3335 5.33317H22.6668ZM14.6668 5.33317L14.6668 8.99988H9.33346L9.33346 5.33317H14.6668ZM14.0666 19.1865C14.2524 19.2817 14.4579 19.3319 14.6666 19.3332C14.9551 19.3332 15.2358 19.2396 15.4666 19.0665L18.1333 17.0665C18.2989 16.9423 18.4333 16.7813 18.5259 16.5961C18.6184 16.411 18.6666 16.2068 18.6666 15.9998C18.6666 15.7928 18.6184 15.5887 18.5259 15.4036C18.4333 15.2184 18.2989 15.0574 18.1333 14.9332L15.4666 12.9332C15.2685 12.7846 15.033 12.6941 14.7864 12.6719C14.5398 12.6497 14.2918 12.6965 14.0703 12.8073C13.8489 12.918 13.6626 13.0882 13.5324 13.2989C13.4023 13.5095 13.3333 13.7522 13.3333 13.9998V17.9998C13.334 18.2461 13.403 18.4873 13.5324 18.6967C13.6618 18.9062 13.8467 19.0757 14.0666 19.1865Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import AppIcon from './AppIcon.vue';
import { type IconSize, getBoundsByIconSize, getSVGViewboxByIconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
