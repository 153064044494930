import type { BaseEndpoint, EndpointQueryMap } from '@package/sdk/src/api/endpoints';
import type { SearchParameters } from 'ofetch';

import { RequestId } from '../../code/requestId';

export enum HttpRequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export type HttpRequestBody = RequestInit['body'] | Record<string, any>;

export type HttpRequestEndpoint = BaseEndpoint;

export type HttpRequestOptions<K extends keyof EndpointQueryMap> = {
  /**
   * Request parameters to be inserted into the path template
   * Example, https://endpoint/{id} => https://endpoint/1
   */
  params?: SearchParameters;
  /**
   * Request parameters that are passed along with the request
   * Example, https://endpoint => https://endpoint?id=1
   */
  query?: EndpointQueryMap[K];
  /**
   * A parameter indicating that the AuthInterceptor should assign platform
   * validation headers (Content-Type, Http-Date, APIAuth, Authorization: APIAuth (not Bearer))
   */
  signRequest?: boolean;

  isForceAPIAuthToken?: boolean;
  /**
   * A record containing additional headers to be assigned to the request.
   * Priority of identical headers:
   *  -1 - default ofetch headers,
   *   0 - headers from request parameters (this is the HttpRequestOptions field)
   *   1 - headers assigned in interceptors
   */
  headers?: Record<string, string>;
  /**
   * Id of the request that can be used to cancel the request
   */
  requestId?: RequestId;
  parseResponse?: (responseText: string) => any;
  response?: boolean;
  retry?: number | false;
  cacheStrategy?: 'default' | 'no-store' | 'max-age';
};

declare module 'ofetch' {
  export interface FetchOptions {
    signRequest?: boolean;
    isForceAPIAuthToken?: boolean;
    requestId?: RequestId;
  }
}
