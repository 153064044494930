import { useKinomAnalytics } from '@package/sdk/src/analytics';

import getKmzaPageValue from '@/code/kmza/get-kmza-page-value';
import type { CommonMoment } from '@/code/moments/moments';
import { AppRoute } from '@/platform/router/routes';
import { useSessionStore } from '@/stores/use-session-store';

import type { LikesContext } from './likes';
import { useLocalLikes } from './use-local-likes';
import { useRemoteLikes } from './use-remote-likes';

export function useLikes() {
  const { isAuth } = storeToRefs(useSessionStore());
  const { context: remoteLikesContext } = useRemoteLikes();
  const { context: localLikesContext, uploadLocalLikes } = useLocalLikes();
  const { $http, $analyticSender } = useNuxtApp();
  const route = useRoute();

  const kinomAnalytics = useKinomAnalytics($analyticSender);

  const likesContext: LikesContext = isAuth.value ? remoteLikesContext : localLikesContext;

  const like = (moment: CommonMoment) => {
    const { id } = moment;

    kinomAnalytics.onClickLikeKinomSet({
      page: getKmzaPageValue(route.name as AppRoute),
      moment,
    });

    $http.cache.clear();

    return likesContext.addLike(id);
  };

  const dislike = (moment: CommonMoment) => {
    const { id } = moment;

    kinomAnalytics.onClickKinomDislikeSet({
      page: getKmzaPageValue(route.name as AppRoute),
      moment,
    });

    $http.cache.clear();

    return likesContext.addDislike(id);
  };

  const removeLike = (moment: CommonMoment) => {
    const { id } = moment;

    kinomAnalytics.onClickLikeKinomRemove({
      page: getKmzaPageValue(route.name as AppRoute),
      moment,
    });

    $http.cache.clear();

    return likesContext.removeLike(id);
  };

  const removeDislike = (moment: CommonMoment) => {
    const { id } = moment;

    kinomAnalytics.onClickKinomDislikeRemove({
      page: getKmzaPageValue(route.name as AppRoute),
      moment,
    });

    $http.cache.clear();

    return likesContext.removeDislike(id);
  };

  return {
    like,
    dislike,
    removeLike,
    removeDislike,
    uploadLocalLikes,
  };
}
