<template>
  <div :class="$style.moment">
    <div ref="playerEl" :class="$style.player" />
  </div>
</template>

<script lang="ts" setup>
import type { VijuPlayer } from '@package/media-player/src/player/modules/instance/player';
import type { Moment } from '@package/sdk/src/api';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';

import type { CommonMoment } from '@/code/moments/moments';

import useAsyncPlayer from '../../code/player/use-async-player';
import usePlayerEventHooks from '../../code/player/use-player-event-hooks';

const props = defineProps<{
  activeMoment: CommonMoment;
}>();

const { loadPlayer } = useAsyncPlayer();
const { WebVijuPlayer } = await loadPlayer();

const emit = defineEmits<{
  (e: 'on-ready', player: VijuPlayer): void;
  (e: 'on-ended', player: VijuPlayer, index?: number): void;
  (e: 'on-can-play', player: VijuPlayer): void;
  (e: 'on-pause'): void;
  (e: 'on-play'): void;
}>();

const player = new WebVijuPlayer({
  projector: 'my-channel',
  loop: false,
  autoplay: true,
  muted: true,
  autoStartLoad: true,
});

markRaw(player);
usePlayerEventHooks({ player });

const playerEl = ref<HTMLElement>();

player.setConfigProperties({
  'content.media': props.activeMoment,
  'experimental.feature.ambientMode': true,
  'experimental.feature.lazyInteractionLoading': false,
  'experimental.feature.viewportIntersectionPlayback': false,
  'video.controls': {
    skipPlayback: false,
    fullscreen: false,
    videoState: true,
  },
});

const onEnded = () => {
  emit('on-ended', player);
  player.pause();
};

const onCanPlay = () => {
  emit('on-can-play', player);
};

const onPause = () => {
  emit('on-pause');
};

const onPlay = () => {
  emit('on-play');
};

const onPlayerMounted = async () => {
  await nextTick();

  const src = props.activeMoment.hls;

  if (!src) {
    throw new UnexpectedComponentStateError('src');
  }

  player.load({ src });
};

const onMomentChanged = async (moment: Moment) => {
  await nextTick();

  const src = moment.hls;

  if (!src) {
    throw new UnexpectedComponentStateError('src');
  }

  player.setConfigProperty('content.media', moment);
  player.load({ src });
};

player.on('mounted', onPlayerMounted);
player.on('ended', onEnded);
player.on('can-play', onCanPlay);
player.on('pause', onPause);
player.on('play', onPlay);

watch(() => props.activeMoment, onMomentChanged);

onMounted(() => {
  player.mount(playerEl.value as HTMLElement);
  emit('on-ready', player);
});

onBeforeUnmount(() => player.dispose());
</script>

<style lang="scss" module>
@use '../../assets/breakpoints' as breakpoints;

.moment {
  position: relative;
  display: flex;
  width: 100%;
  height: var(--my-channel-player-height);
  max-height: var(--my-channel-player-max-height);
  overflow: hidden;
  justify-content: center;
}

.player {
  width: 100%;
  height: var(--my-channel-player-height);
  max-height: var(--my-channel-player-max-height);
  border-radius: var(--g-border-round-20);
}

@include breakpoints.max-width-1024 {
  .player {
    border-radius: 0;
  }
}
</style>
