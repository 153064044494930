<template>
  <app-modal-wrapper
    :max-width="440"
    :with-padding="false"
    :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.WatchAllEpisodeModal, 'modalWrapper')"
  >
    <div
      :class="{
        [$style.imageWrapper]: true,
        [$style.imageWrapperHidden]: isImageHidden,
      }"
    >
      <div :class="$style.blackout" />
      <app-img :image-class="$style.poster" :alt="$t('alt.modals.watchAllEpisodes.promo')" :src="posterSrc" />
    </div>

    <div :class="$style.contentBlock">
      <modal-title :title="$t('watchAllEpisodesModal.title')" :class="$style.title" />
      <modal-description :description="$t('watchAllEpisodesModal.description')" :class="$style.description" />

      <app-badge
        :class="{
          [$style.badge]: true,
          [$style.imageWrapperHidden]: isImageHidden,
        }"
        :is-accent="false"
        type="small"
        :label="$t('contentPage.availableAfterRegistration')"
      />

      <app-button
        :class="$style.button"
        :text="$t('watchAllEpisodesModal.button')"
        size="extra-large"
        :aria-label="$t('ariaLabel.watchAllEpisodesModals.startWatching')"
        :title="$t('ariaLabel.watchAllEpisodesModals.startWatching')"
        :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.WatchAllEpisodeModal, 'startWatchingButton')"
        @click="onButtonClick"
      />

      <p :class="$style.subtitle">
        {{ $t('watchAllEpisodesModal.subtitle') }}
      </p>
    </div>
  </app-modal-wrapper>
</template>

<script lang="ts" setup>
import { AnalyticPageName, OfferPageFrom, useSubscribeAnalytics } from '@SDK/analytics';
import { useWindowSize } from '@vueuse/core';

import useAuthActions from '@/code/auth/use-auth-actions';
import { ElementTestIdentifierScope, getTestElementIdentifier } from '@/code/e2e-testing/element-test-identifier';
import ModalDescription from '@/components/modals/ModalDescription.vue';
import ModalTitle from '@/components/modals/ModalTitle.vue';
import AppBadge from '@/components/ui/AppBadge.vue';
import AppButton from '@/components/ui/AppButton.vue';
import AppImg from '@/components/ui/AppImg.vue';
import { useLayoutStore } from '@/stores/use-layout-store';
import { useOffersStore } from '@/stores/use-offers-store';
import { useSessionStore } from '@/stores/use-session-store';

const { $analyticSender } = useNuxtApp();

const { setCurrentModalName, modalOptions } = useLayoutStore();
const { doRedirectOfferPage, sendToRegistrationPage } = useAuthActions();
const { isAuth } = storeToRefs(useSessionStore());
const { isActiveSubscription } = storeToRefs(useOffersStore());

const subscribeAnalytics = useSubscribeAnalytics($analyticSender);

const posterSrc = computed(() => modalOptions.posterSrc || '/images/watch-all-serial-banner.png');

const { height: _height, width: _width } = useWindowSize();

const MODAL_WINDOW_MIN_HEIGHT = 450;

const isImageHidden = computed(() => _width.value > _height.value && _height.value < MODAL_WINDOW_MIN_HEIGHT);

const onButtonClick = () => {
  setCurrentModalName(null);

  if (!isAuth.value) {
    return sendToRegistrationPage({ isRedirectFromFreeEpisode: true });
  }

  if (!isActiveSubscription.value) {
    subscribeAnalytics.onGotoOfferPage({
      page: AnalyticPageName.Item,
      from: OfferPageFrom.AfterFreeWatch,
    });

    doRedirectOfferPage();
  }
};
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '@/assets/breakpoints' as breakpoints;

.imageWrapper {
  position: relative;
}

.blackout {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-notheme-dim-black-40);
}

.poster {
  width: 100%;
  height: 247px;
}

.contentBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: var(--g-spacing-32);
}

.badge {
  position: absolute;
  top: var(--g-spacing-20);
  left: var(--g-spacing-24);
}

.button {
  margin: var(--g-spacing-32) 0 var(--g-spacing-20) 0;
  width: 100%;
}

.title {
  @include fonts.WebTitle-2;
}

.subtitle {
  @include fonts.WebBody-2;
}

.description {
  @include fonts.WebBody-1;
}

@include breakpoints.max-width-1024 {
  .imageWrapperHidden {
    display: none;
  }
}

@include breakpoints.max-width-540 {
  .contentBlock {
    padding: var(--g-spacing-24);
  }
}
</style>
