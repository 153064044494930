<template>
  <div :class="$style.carouselItem" :data-active-moment="isActiveMoment" :style="{ height: $toPixel(height) }">
    <div v-if="isActiveMoment" id="player-target"></div>
    <img
      v-if="false"
      :class="{
        [$style.image]: true,
        [$style.imageHorizontal]: orientation === 'horizontal',
        [$style.imageHidden]: isActiveMoment && isVideoPlaying,
      }"
      :src="previewSrc"
      alt="title"
    />
  </div>
</template>

<script lang="ts" setup>
import type { VijuPlayer } from '@package/media-player/src/player/modules/instance/player';

import useScreenOrientation from '../../platform/layout/use-screen-orientation';

const props = withDefaults(
  defineProps<{
    player?: VijuPlayer;
    isVideoPlaying: boolean;
    previewSrc?: string;
    index: number;
    currentIndex: number;
    height: number;
  }>(),
  {
    isVideoPlaying: true,
    previewSrc: '',
  },
);

const isActiveMoment = computed(() => props.currentIndex === props.index);

const { orientation } = useScreenOrientation();
</script>

<style lang="scss" module>
.carouselItem {
  position: relative;
  display: block;
  overflow: hidden;
}

.image {
  position: absolute;
  top: calc(50% + 50px);
  z-index: var(--z-index-moment-card-player-target-img-shown);
  transform: scale(1.5) translateY(-50%);
}

.imageHorizontal {
  top: 0;
  z-index: var(--z-index-moment-card-player-target-img-hidden);
  transform: scale(1);
}

.imageHidden {
  z-index: var(--z-index-moment-card-player-target-img-hidden);
}
</style>
