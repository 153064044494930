import { SubscriptionPlatform } from '@package/sdk/src/api';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';

import useLocale from '@/platform/localization/use-locale';
import { useOffersStore } from '@/stores/use-offers-store';
import { useSessionStore } from '@/stores/use-session-store';

import { useOffersApi } from '../offer/use-offers-api';

export function useSubscriptionManagement() {
  const offersApi = useOffersApi();
  const sessionStore = useSessionStore();
  const { subscription, isSubscriptionRecurrent } = storeToRefs(useOffersStore());
  const { formatDate } = useLocale();
  const { translate } = useLocale();

  function getCancellationSubscriptionText(platform: SubscriptionPlatform) {
    const { translate } = useLocale();

    switch (platform) {
      case SubscriptionPlatform.Apple:
        return translate('lkPage.account.appleSubscriptionCancel');
      case SubscriptionPlatform.Google:
        return translate('lkPage.account.googleSubscriptionCancel');
      case SubscriptionPlatform.Partner:
        return translate('lkPage.account.partnerSubscriptionCancel');
      default:
        return '';
    }
  }

  function getRenewalSubscriptionText(platform: SubscriptionPlatform) {
    const { translate } = useLocale();

    switch (platform) {
      case SubscriptionPlatform.Partner:
        return translate('lkPage.account.partnerSubscriptionRenewal');
      default:
        return '';
    }
  }

  const getPaymentDate = () => {
    const endsAt = subscription.value?.endsAt;

    if (!endsAt) {
      throw new UnexpectedComponentStateError('endsAt');
    }

    return formatDate(new Date(endsAt));
  };

  const subscriptionBannerSubtitle = () => {
    return `${
      isSubscriptionRecurrent.value
        ? translate('lk.subscriptionManage.autoRenewal')
        : translate('lk.subscriptionCard.activeAt')
    } ${getPaymentDate()}`;
  };

  const toggleAutoRenew = async (cancelReasonsIds?: string[], reasonText?: string) => {
    return await offersApi
      .toggleAutoRenew(subscription.value?.id, cancelReasonsIds, reasonText)
      .then(async () => await sessionStore.updateCurrentUser());
  };

  const cancelSberAutoRenew = async () => {
    return await offersApi
      .cancelSberAutoRenew(subscription.value?.id)
      .then(async () => await sessionStore.updateCurrentUser());
  };

  return {
    getPaymentDate,
    toggleAutoRenew,
    cancelSberAutoRenew,
    getRenewalSubscriptionText,
    getCancellationSubscriptionText,
    subscriptionBannerSubtitle,
  };
}
