import useLogger from '@package/logger/src/use-logger';
import { useAccountPageAnalytics } from '@package/sdk/src/analytics';
import type {
  BlocksConfiguration,
  CloudPaymentsInvoice,
  CustomerReceipt,
  CustomerReceiptItem,
  PaymentBlocksOptions,
  PaymentOptions,
} from '@package/sdk/src/api';
import { CloudpaymentsWidgetEventName } from '@package/sdk/src/api';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import * as Sentry from '@sentry/browser';
import type { AnyFn } from '@vueuse/core';
import { useScriptTag } from '@vueuse/core';

import { NotificationLevel } from '@/code/notifications/notification';
import { useCloudPaymentsApi } from '@/code/payments/use-cloudpayments-api';
import { ApiError } from '@/platform/http/errors';
import { getLanguageByISO639Format } from '@/platform/localization/language';
import useLocale from '@/platform/localization/use-locale';
import { AppVariation } from '@/platform/variation/interfaces';
import useHostVariation from '@/platform/variation/use-host-variation';
import { BroadcastChannelEvent } from '@/plugins/broadcast-channel';
import { useLayoutStore } from '@/stores/use-layout-store';
import usePaymentsStore from '@/stores/use-payments-store';
import { usePromocodeStore } from '@/stores/use-promocode-store';
import { useSessionStore } from '@/stores/use-session-store';

export interface IGetCloudpaymentsInvoiceOptions {
  offerId: string;
  bonusId?: string;
}

export interface IPrepareCloudpaymentsWidgetOptions {
  userId: string;
  email?: string;
  phone?: string;
  language: 'ru-RU' | 'en-US' | 'hy-HY';
}

export interface IRawCloudpaymentsApplyingOfferOptions {
  blockOptions: PaymentOptions;
  onSuccessCallback: AnyFn;
}

export function useCloudPayments() {
  const cloudPaymentsApi = useCloudPaymentsApi();
  const sessionStore = useSessionStore();
  const layoutStore = useLayoutStore();
  const paymentsStore = usePaymentsStore();
  const logger = useLogger('use-cloudpayments');
  const { $broadcastChannel, $analyticSender } = useNuxtApp();

  const accountPageAnalytics = useAccountPageAnalytics($analyticSender);

  const { appVariation, appLanguage } = useHostVariation();
  const { translate } = useLocale();
  const { user } = storeToRefs(sessionStore);

  const { appliedBonusInfo } = storeToRefs(usePromocodeStore());

  const config: BlocksConfiguration = {
    components: {
      paymentButton: {
        text: translate('subscription.buttons.pay'),
        fontSize: '18px',
      },
      paymentForm: {
        labelFontSize: '16px',
        activeLabelFontSize: '16px',
        fontSize: '16px',
      },
    },
    appearance: {
      colors: {
        primaryButtonColor: '#00c7b2',
        primaryButtonTextColor: '#0c2729',
        primaryHoverButtonColor: '#4dd8c9',
        primaryButtonHoverTextColor: '#0c2729',
        activeInputColor: '#ffffff',
        inputBackground: '#29383c',
        inputColor: '#ffffff80',
        inputBorderColor: '#0b272a',
        errorColor: '#da615c',
      },
      borders: {
        radius: '12px',
      },
    },
  };

  const cloudpaymentsScript = useScriptTag('https://widget.cloudpayments.ru/bundles/paymentblocks.js', () => {}, {
    manual: true,
  });

  const getCloudpaymentsInvoice = (options: IGetCloudpaymentsInvoiceOptions) =>
    cloudPaymentsApi.getInvoice({
      offer_id: options.offerId,
      user_bonus_id: options.bonusId ? options.bonusId : undefined,
    });

  const prepareReceiptInfo = (
    cloudPaymentsInvoiceResponse: CloudPaymentsInvoice,
    options: IPrepareCloudpaymentsWidgetOptions,
  ): CustomerReceipt => {
    const { amount, description } = cloudPaymentsInvoiceResponse;
    const { email, phone } = options;

    // здесь некоторые параметры захардкожены - это константы, так и должно быть
    const receiptItem: CustomerReceiptItem = {
      label: description,
      price: amount,
      amount,
      quantity: 1,
      vat: 20,
      method: 4,
      object: 4,
      measurementUnit: 'услуга',
    };

    return {
      Items: [receiptItem],
      calculationPlace: appVariation === AppVariation.Am ? 'viju.am' : 'viju.ru',
      ...(email && { email }),
      ...(phone && { phone }),
      amounts: {
        electronic: amount,
        advancePayment: 0,
        credit: 0,
        provision: 0,
      },
    };
  };

  const prepareWidgetData = async (
    cloudPaymentsInvoiceResponse: CloudPaymentsInvoice,
    options: IPrepareCloudpaymentsWidgetOptions,
  ) => {
    const { data } = await cloudPaymentsApi.getPaymentInfo();

    const { invoiceId, amount, description, currency, transactionType } = cloudPaymentsInvoiceResponse;
    const { userId, email, language, phone } = options;
    const { public_id: publicId } = data;

    paymentsStore.setCloudpaymentsPaymentInfo(cloudPaymentsInvoiceResponse);

    const preparedReceiptData = prepareReceiptInfo(cloudPaymentsInvoiceResponse, options);

    const blocksOptions: PaymentBlocksOptions = {
      publicId,
      auth: transactionType === 'auth',
      accountId: userId,
      invoiceId,
      description,
      amount,
      currency,
      autoClose: 3,
      data: {
        CloudPayments: {
          ...(phone && { UserPhone: phone }),
          ...(appVariation === AppVariation.Ru && { customerReceipt: preparedReceiptData }), // отправляем чеки Cloudpayments только для РФ
        },
      },
      language,
    };

    if (email) {
      blocksOptions.email = email;
    }

    return blocksOptions;
  };

  const prepareWidgetDataForExternalPayment = (blockOptions: PaymentOptions) => {
    paymentsStore.setCloudpaymentsPaymentInfo({
      invoiceId: blockOptions.invoiceId,
      amount: blockOptions.amount,
      description: blockOptions.description,
      currency: blockOptions.currency,
      transactionType: 'auth',
    });

    const preparedOptions: PaymentOptions = {
      language: getLanguageByISO639Format(appLanguage.value),
      ...blockOptions,
    };

    return preparedOptions;
  };

  const createCPWidget = (
    blocksOptions: PaymentOptions,
    onSuccessPaymentCallback: AnyFn,
    closeWidgetAfterSuccessPayment = true,
  ) => {
    const paymentBlockWidgetEl = new window.cp.PaymentBlocks(blocksOptions, config);
    const container = document.querySelector('#cloudpaymentsWidget');

    if (!container) {
      Sentry.captureException({ message: 'Calling createCPWidget, but no #cloudpaymentsWidget found' });

      logger.error('createCPWidget', 'Expect #cloudpaymentsWidget element to be defined');

      return layoutStore.addNotification({
        level: NotificationLevel.Info,
        message: translate('payment.error'),
        hideTimeoutMs: 4000,
        position: 'top',
      });
    }

    paymentBlockWidgetEl.mount(container);

    const onInitialize = () => {
      // чтобы скрывать скролл, который появляется внутри iframe, прхиодится сетать таймаут
      window.setTimeout(() => {
        paymentsStore.setIsCloudpaymentsWidgetMounted(true);
        layoutStore.setIsCloseableModal(true);
      }, 2000);
    };

    const onDestroy = () => {
      paymentsStore.setIsCloudpaymentsWidgetMounted(false);
      paymentsStore.setCloudpaymentsPaymentInfoToDefault();
    };

    const onSuccessPayment = () => {
      Reflect.apply(onSuccessPaymentCallback, undefined, []);

      if (closeWidgetAfterSuccessPayment) {
        layoutStore.setCurrentModalName(null);
      }

      $broadcastChannel.postMessage({ event: BroadcastChannelEvent.SubscriptionSuccess });

      paymentsStore.setCloudpaymentsPaymentInfoToDefault();
    };

    paymentBlockWidgetEl.on(CloudpaymentsWidgetEventName.Init, onInitialize);
    paymentBlockWidgetEl.on(CloudpaymentsWidgetEventName.Destroy, onDestroy);
    paymentBlockWidgetEl.on(CloudpaymentsWidgetEventName.Success, onSuccessPayment);
  };

  const openExternalPaymentCPWidget = (options: IRawCloudpaymentsApplyingOfferOptions) => {
    if (!window.cp) {
      Sentry.captureException({ message: 'Calling openCloudPaymentsWidget, but window.cp undefined' });

      return layoutStore.addNotification({
        level: NotificationLevel.Info,
        message: translate('payment.error'),
        hideTimeoutMs: 4000,
        position: 'top',
      });
    }

    const { blockOptions, onSuccessCallback } = options;

    const preparedData = prepareWidgetDataForExternalPayment(blockOptions);

    createCPWidget(preparedData, onSuccessCallback, false);
  };

  const openCPWidget = async (onSuccessPaymentCallback: AnyFn, offerId?: string) => {
    if (!window.cp) {
      Sentry.captureException({ message: 'Calling openCloudPaymentsWidget, but window.cp undefined' });

      logger.error('openCPWidget', 'expect window.cp to be defined');

      return layoutStore.addNotification({
        level: NotificationLevel.Info,
        message: translate('payment.error'),
        hideTimeoutMs: 4000,
        position: 'top',
      });
    }

    let cloudPaymentsInvoiceResponse: CloudPaymentsInvoice | undefined;
    const bonusId = appliedBonusInfo.value?.id;

    try {
      cloudPaymentsInvoiceResponse = offerId
        ? await getCloudpaymentsInvoice({ offerId, bonusId })
        : await cloudPaymentsApi.addPaymentCard();
    } catch (error) {
      if (error instanceof ApiError) {
        layoutStore.addNotification({
          level: NotificationLevel.Error,
          message: error.message,
          hideTimeoutMs: 3000,
          position: 'top',
        });
      }

      return logger.error(error);
    }

    if (!cloudPaymentsInvoiceResponse) {
      throw new UnexpectedComponentStateError('cloudPaymentsInvoiceResponse');
    }

    const blocksOptions = await prepareWidgetData(cloudPaymentsInvoiceResponse, {
      userId: user.value?.id as string,
      email: user.value?.email as string,
      phone: user.value?.phoneNumber as string,
      language: getLanguageByISO639Format(appLanguage.value),
    });

    createCPWidget(blocksOptions, () =>
      onSuccessPaymentCallback({
        orderPrice: blocksOptions.amount,
        currency: blocksOptions.currency,
        orderId: blocksOptions.invoiceId,
      }),
    );

    accountPageAnalytics.onAutoSubscribeCloudPaymentsDisplayed();
  };

  const loadCloudpaymentsScriptWithCallback = (callback: AnyFn) => {
    layoutStore.setCurrentModalName('CloudpaymentsWidgetModal', { hasCloseIcon: false });

    // Если cp уже инициализирован, повторно виджет не загружаем
    if (window.cp) {
      return Reflect.apply(callback, undefined, []);
    }

    cloudpaymentsScript.load(true).then(() => callback());
  };

  return { openCPWidget, openExternalPaymentCPWidget, loadCloudpaymentsScriptWithCallback };
}
