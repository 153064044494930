<template>
  <article :class="$style.contentCard">
    <app-link
      :to="getContentRoute(content, { isPlayerPage: shouldRedirectToPlayerPage(content.accessKind) })"
      :class="$style.contentCardContainer"
      :aria-label="$t('ariaLabel.content.continueWatch', { title })"
      :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.Main, 'continueWatchItem')"
    >
      <div :class="$style.contentCardMedia">
        <app-img
          v-if="isContentUnavailable"
          :src="imageSrc"
          :alt="contentImageAlt"
          :width="isMobile ? 350 : 400"
          :image-class="$style.contentCardImage"
        />

        <content-cover
          v-if="isContentUnavailable"
          :availability-message="availabilityMessage"
          :darkened="isContentUnavailable"
          :class="$style.contentCardCover"
          variant="episode"
        />

        <template v-if="!isContentUnavailable">
          <app-img
            :src="imageSrc"
            :alt="contentImageAlt"
            :width="isMobile ? 350 : 400"
            :image-class="$style.contentCardImage"
          />

          <div :class="$style.blackout" />
        </template>

        <p
          v-if="duration"
          :class="[$style.contentCardWatchProgress, { [$style.hideBlock]: isContentUnavailableOrAvailableSoon }]"
        >
          {{ timeLeft }}
        </p>

        <div
          :class="{
            [$style.contentCardProgressWrapper]: true,
            [$style.hideBlock]: isContentUnavailableOrAvailableSoon,
          }"
        >
          <div :style="{ width: toPercent(watchProgressPercentage) }" :class="$style.contentCardProgress" />
        </div>
      </div>

      <p v-if="title" :class="$style.contentCardTitle">
        {{ title }}
      </p>

      <content-info-subtitle :year="content.year" :genres="content.genres" />
    </app-link>

    <div
      :class="{
        [$style.deleteItemButton]: true,
        [$style.deleteItemButtonHidden]: !isMobile,
      }"
    >
      <icon-close
        size="small"
        :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.Common, 'removeButton')"
        @click="emit('clear-item')"
      />
    </div>
  </article>
</template>

<script lang="ts" setup>
import { useWatchingItemHelpers } from '@package/content-utils/src/code/use-watching-item-helpers';
import * as playerHelpers from '@package/media-player/src/player/helpers';
import { type Episode, type Media } from '@package/sdk/src/api';
import { MediaContentType } from '@package/sdk/src/api';
import { toPercent } from '@package/sdk/src/core';

import { ElementTestIdentifierScope, getTestElementIdentifier } from '@/code/e2e-testing/element-test-identifier';
import useLDJSONMarkup from '@/code/linked-data-json-markup/use-ld-json-markup';
import ContentCover from '@/components/content-page/ContentCover.vue';
import ContentInfoSubtitle from '@/components/content-page/ContentInfoSubtitle.vue';
import IconClose from '@/components/icons/IconClose.vue';
import AppImg from '@/components/ui/AppImg.vue';
import AppLink from '@/components/ui/AppLink.vue';
import useMobile from '@/platform/layout/use-mobile';
import useLocale from '@/platform/localization/use-locale';
import { useRouteUtils } from '@/platform/router/use-route-utils';

const props = defineProps<{
  content: Media;
}>();

const emit = defineEmits<{
  (e: 'clear-item'): void;
}>();

const { getContentRoute, shouldRedirectToPlayerPage } = useRouteUtils();
const { getContentTimeUnits } = useLDJSONMarkup();
const { translate } = useLocale();
const { getProgressPercentage } = useWatchingItemHelpers();

const isMobile = useMobile();

const { getAvailabilityMessage, showAvailabilityOverlay, isUnavailable, isAvailableSoon } =
  playerHelpers.useContentAvailability();

const contentType = computed(() => props.content.contentType);
const duration = computed(() => props.content.duration);
const imageSrc = computed(() => props.content.preview || props.content.background);

const offset = computed(() => props.content.watchingItem?.offset as number);

const title = computed(() => {
  if (props.content.contentType === MediaContentType.MOVIE) {
    return props.content.title;
  }

  const serialTitle = (props.content as Episode).serialTitle;
  return `${serialTitle}. ${props.content.seasonNumber} сезон ${props.content.number} серия`;
});

const year = computed(() => props.content.year);

const contentImageAlt = computed(() => {
  const titleAndYear = `${title.value} ${year.value}`;

  return (contentType.value === 'episode' ? translate('common.serial') : translate('common.movie')) + titleAndYear;
});

const timeLeft = computed(() => {
  const secondsLeft = duration.value - offset.value;
  const [hours, minutes] = getContentTimeUnits(secondsLeft);

  if (hours === 1) {
    return translate('content.watchingItems.timeLeftWithHour', { hours, minutes });
  }

  if (hours) {
    return translate('content.watchingItems.timeLeftWithHourPlural', { hours, minutes });
  }

  return translate('content.watchingItems.timeLeftMinutes', { minutes });
});

const watchProgressPercentage = computed(() => getProgressPercentage(offset.value, duration.value));

const isContentUnavailableOrAvailableSoon = computed(
  () => isUnavailable(props.content) || isAvailableSoon(props.content),
);

const isContentUnavailable = computed(() =>
  isContentUnavailableOrAvailableSoon.value ? showAvailabilityOverlay(props.content) : '',
);
const availabilityMessage = computed(() => getAvailabilityMessage(props.content));
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts' as fonts;
@use '@/assets/aspect-ratio' as aspect-ratio;
@use '@/assets/breakpoints' as breakpoints;

.contentCard {
  position: relative;
  transform: scale(1);
  display: flex;
  transition: all ease-in-out 0.2s;
  flex-direction: column;

  &:hover {
    .contentCardTitle {
      color: var(--color-text-accent);
    }

    .deleteItemButton {
      opacity: 1;
    }
  }
}

.blackout {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
  background: linear-gradient(180deg, rgba(18, 33, 33, 0) 0%, rgba(0, 0, 0, 1) 100%);
}

.deleteItemButton {
  position: absolute;
  top: var(--g-spacing-16);
  right: var(--g-spacing-16);
  z-index: var(--z-index-carousel-clear);
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: var(--g-border-round-12);
  background: var(--color-bg-secondary-80);
  transition: all ease 0.2s;
  cursor: pointer;
  justify-content: center;
}

.deleteItemButtonHidden {
  opacity: 0;
}

.contentCardContainer {
  position: relative;
  cursor: pointer;
}

.contentCardMedia {
  position: relative;
  display: block;
  width: initial !important;
  border-radius: var(--g-border-round-24);
  border: 1px solid var(--color-stroke-div-primary);
  background-color: var(--color-bg-secondary);
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover, 80px;
  background-position: top, center;
  object-position: center;

  @include aspect-ratio.ratio(1.7, 1);
}

.contentCardImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.1);
  width: auto;
  height: 100%;
}

.contentCardCover {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.contentCardProgressWrapper {
  position: absolute;
  bottom: var(--g-spacing-16);
  width: calc(100% - var(--g-spacing-32));
  height: var(--g-spacing-6);
  border-radius: var(--g-border-round-8);
  background: var(--color-bg-ghost);
  margin-left: var(--g-spacing-16);
}

.contentCardProgress {
  position: relative;
  padding: 0 var(--g-spacing-16);
  height: 100%;
  border-radius: var(--g-border-round-8);
  background-color: var(--color-bg-accent);
}

.contentCardWatchProgress {
  position: absolute;
  bottom: var(--g-spacing-32);
  right: var(--g-spacing-16);
  color: var(--color-notheme-text-primary);

  @include fonts.WebBody-3;
}

.contentCardTitle {
  display: inline-block;
  margin: var(--g-spacing-10) 0 var(--g-spacing-4);
  width: fit-content;
  color: var(--color-text-primary);
  transition: all ease 0.2s;

  @include fonts.WebBody-1;
}

.offset {
  color: var(--color-text-primary);
}

.hideBlock {
  display: none;
}

@include breakpoints.max-width-800 {
  .contentCardMedia {
    background-size: cover, 40px;
  }
}
</style>
