<template>
  <div ref="kinomsRef" :class="$style.kinoms">
    <div ref="dropdownRef" :class="$style.dropdownWrapper">
      <dropdown-skeleton v-if="!selectedDropdownOption" />
      <app-dropdown
        v-if="selectedDropdownOption"
        :option="selectedDropdownOption"
        :options="playlists"
        :class="$style.dropdown"
        :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.MyChannel, 'sidebarCategoriesDropdown')"
        :parent-element-ref="kinomsRef"
        :diff-height="DROPDOWN_HEADER_HEIGHT"
        :with-fade="true"
        :max-options-height="MAX_OPTIONS_HEIGHT"
        @select="onCategorySelect"
        @header-click="onDropdownHeaderClick"
      />

      <my-channel-episodes-button
        :is-active="isMomentsListOpened"
        :is-disabled="isMyChannelEpisodesButtonDisabled"
        :class="$style.episodesButton"
        @click="toggleIsMomentsListOpened"
      />
    </div>
    <div
      ref="cardsWrapperRef"
      :class="{
        [$style.cardsWrapper]: true,
        [$style.opened]: isMomentsListOpened,
        [$style.withoutSmoothScroll]: isScrolling,
      }"
      @scrollend="onScrollEnd"
    >
      <div ref="cardsRef" :class="$style.cards">
        <my-channel-kinom-card
          v-for="(moment, index) in moments"
          :key="`${moment.id}-${index}`"
          :ref="(comp) => (kinomCardRef = comp?.$el)"
          :info="getKinomCardInfo(moment)"
          :is-selected="activeMoment?.id === moment.id && activeMomentIndex === index"
          :is-card-pressed-to-top="isCardPressedToTop && activeMoment?.id === moment.id"
          @click="onMomentCardClick(moment, index)"
        />
      </div>

      <template v-if="isLoading && !moments?.length">
        <my-channel-kinom-card-skeleton
          v-for="item in ConstantsConfigInstanceWeb.getProperty('myChannelPerPageItems')"
          :key="item"
        />
      </template>

      <div v-if="!isLoadingFinished" :class="$style.loader">
        <app-loader-circle-icon />
      </div>

      <my-channel-empty-my-collection v-if="isMyCollectionEmpty" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import ConstantsConfigInstanceWeb from '@package/constants/code/constants-config-web';
import { useInfiniteScroll } from '@vueuse/core';

import { ElementTestIdentifierScope, getTestElementIdentifier } from '@/code/e2e-testing/element-test-identifier';
import type { CommonMoment } from '@/code/moments/moments';
import { type MyChannelKinomCardInfo, MyChannelKinomCardDisplayedTitle } from '@/code/my-channel/my-channel';
import AppDropdown from '@/components/app-dropdown/AppDropdown.vue';
import MyChannelEmptyMyCollection from '@/components/my-channel/MyChannelEmptyMyCollection.vue';
import MyChannelEpisodesButton from '@/components/my-channel/MyChannelEpisodesButton.vue';
import MyChannelKinomCard from '@/components/my-channel/MyChannelKinomCard.vue';
import DropdownSkeleton from '@/components/skeleton/DropdownSkeleton.vue';
import MyChannelKinomCardSkeleton from '@/components/skeleton/MyChannelKinomCardSkeleton.vue';
import AppLoaderCircleIcon from '@/components/ui/AppLoaderCircleIcon.vue';
import { type DropdownOption } from '@/stores/use-layout-store';

const DROPDOWN_HEADER_HEIGHT = 124;
const PAGINATION_TRIGGER_ELEMENTS_HEIGHT = 300;
const MAX_OPTIONS_HEIGHT = 450;
const EXTRA_MARGIN = 1;

const props = defineProps<{
  playlists: DropdownOption[];
  moments: CommonMoment[];
  activeMomentIndex: number;
  isMyCollectionEmpty: boolean;
  kinomCardDisplayedTitle: MyChannelKinomCardDisplayedTitle;
  selectedDropdownOption?: DropdownOption;
  activeMoment?: CommonMoment;
  isLoadingFinished?: boolean;
  isMyChannelEpisodesButtonDisabled?: boolean;
  isLoading?: boolean;
}>();

const emit = defineEmits<{
  (e: 'on-category-select', category: DropdownOption): void;
  (e: 'on-dropdown-header-click'): void;
  (e: 'on-moments-list-toggled', value: boolean): void;
  (e: 'on-moment-card-click', moment: CommonMoment, index: number): void;
  (e: 'on-load-more'): void;
}>();

const isMomentsListOpened = ref(false);
const isScrolling = ref(false);
const isCardPressedToTop = ref(false);

const kinomsRef = ref<HTMLDivElement>();
const cardsRef = ref<HTMLDivElement>();
const kinomCardScrollHeight = ref(0);
const kinomCardRef = ref<HTMLDivElement>();
const cardsWrapperRef = ref<HTMLDivElement>();
const dropdownRef = ref<HTMLDivElement>();

const onCategorySelect = (category: DropdownOption | DropdownOption[]) => {
  isMomentsListOpened.value = false;
  emit('on-category-select', category as DropdownOption);
};

const onDropdownHeaderClick = () => {
  emit('on-dropdown-header-click');
};

const onMomentCardClick = (moment: CommonMoment, index: number) => {
  isMomentsListOpened.value = false;
  emit('on-moment-card-click', moment, index);
};

const onScrollEnd = () => {
  isScrolling.value = false;
};

const calculateKinomCardScrollHeight = () => {
  const childrenHTMLCollection = cardsRef.value?.children;

  if (!childrenHTMLCollection) {
    return;
  }

  if (kinomCardScrollHeight.value === 0) {
    const children = Array.from(childrenHTMLCollection);
    const el = children[0];
    kinomCardScrollHeight.value = el?.scrollHeight || 0;
  }
};

const toggleIsMomentsListOpened = async () => {
  isMomentsListOpened.value = !isMomentsListOpened.value;

  calculateKinomCardScrollHeight();
  await setKinomCardsWrapperScrollTo();

  emit('on-moments-list-toggled', isMomentsListOpened.value);
};

const getGenre = (moment: CommonMoment) => {
  if (moment.genres?.length) {
    return moment.genres[0]?.title;
  }

  return '';
};

const getKinomCardInfo = (moment: CommonMoment): MyChannelKinomCardInfo => {
  const momentPreview = moment.preview || '';

  switch (props.kinomCardDisplayedTitle) {
    case MyChannelKinomCardDisplayedTitle.Title:
      return {
        title: moment.title,
        preview: momentPreview,
      };
    case MyChannelKinomCardDisplayedTitle.ContentTitle:
      return {
        title: moment.contentTitle,
        preview: momentPreview,
        year: moment.contentYear,
        genre: getGenre(moment),
      };
    default:
      return {} as MyChannelKinomCardInfo;
  }
};

const kinomCardMarginsBottom = computed(() => {
  if (!kinomCardRef.value) {
    return 0;
  }

  const kinomCardMarginInPx = window.getComputedStyle(kinomCardRef.value).marginBottom;
  const kinomCardMarginPixelsNumber = Number(kinomCardMarginInPx.replace('px', ''));

  return kinomCardMarginPixelsNumber * props.activeMomentIndex;
});

const onScrollListener = () => {
  calculateKinomCardScrollHeight();

  const cardsWrapperScrollTop = cardsWrapperRef.value?.scrollTop || 0;
  const scrollHeightToSelectedCard = kinomCardScrollHeight.value * props.activeMomentIndex;
  isCardPressedToTop.value = cardsWrapperScrollTop > scrollHeightToSelectedCard + kinomCardMarginsBottom.value;
};

const scrollToActiveElement = () => {
  if (!kinomsRef.value || !dropdownRef.value || !kinomCardRef.value || !cardsWrapperRef.value) {
    return;
  }

  const activeMomentCardScrollHeight =
    kinomCardRef.value.scrollHeight * props.activeMomentIndex + kinomCardMarginsBottom.value - EXTRA_MARGIN;

  if (props.activeMomentIndex !== 0) {
    cardsWrapperRef.value.scroll(0, activeMomentCardScrollHeight);
  }
};

const setCardsWrapperScrollToTop = (scrollTop: number) => {
  if (!cardsWrapperRef.value) {
    return;
  }

  cardsWrapperRef.value.scrollTop = scrollTop;
};

const setKinomCardsWrapperScrollTo = async () => {
  if (!kinomCardRef.value) {
    return;
  }

  isScrolling.value = true;
  await nextTick();
  const scrollTop = kinomCardRef.value?.clientHeight * props.activeMomentIndex + kinomCardMarginsBottom.value;
  setCardsWrapperScrollToTop(scrollTop);

  isScrolling.value = false;
};

const getKinomSidebarEls = (): HTMLElement[] | Element[] => {
  if (!cardsRef.value?.children) {
    return [];
  }

  const options = Array.from(cardsRef.value.children);

  if (!options) {
    return [];
  }

  return options;
};

const onLoadMore = () => {
  emit('on-load-more');
};

useInfiniteScroll(cardsWrapperRef, onLoadMore, { distance: PAGINATION_TRIGGER_ELEMENTS_HEIGHT });

defineExpose({
  isScrolling,
  isCardPressedToTop,
  scrollToActiveElement,
  getKinomSidebarEls,
  setCardsWrapperScrollToTop,
});

onMounted(() => {
  cardsWrapperRef.value?.addEventListener('scroll', onScrollListener);
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '@/assets/breakpoints' as breakpoints;

.dropdownWrapper {
  position: relative;
  z-index: var(--z-index-my-channel-dropdown);
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: var(--g-spacing-24);
  height: fit-content;
  background-color: var(--color-notheme-bg-primary);
}

.episodesButton {
  margin-left: var(--g-spacing-8);
  display: none;
}

.dropdown {
  width: 100%;
  max-width: 352px;
  height: 56px;
}

.kinoms {
  z-index: var(--z-index-my-channel-kinoms);
  display: grid;
  width: var(--my-channel-desktop-kinoms-width);
  background-color: var(--color-notheme-bg-primary);
  overflow: scroll;
  grid-template-rows: 104px 1fr;

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none; // для Firefox
}

.cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cardsWrapper {
  position: relative;
  z-index: var(--z-index-my-channel-kinom-cards);
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;

  // для Firefox
  scrollbar-color: var(--color-bg-secondary) var(--color-notheme-bg-primary) !important;
  scrollbar-width: thin !important;
}

.withoutSmoothScroll {
  scroll-behavior: unset;
}

.loader {
  display: flex;
  margin: var(--g-spacing-24) 0;
  width: 100%;
  justify-content: center;
}

@include breakpoints.max-width-1024 {
  .episodesButton {
    display: block;
  }

  .dropdownWrapper {
    padding: var(--g-spacing-16);
  }

  .dropdown {
    width: 315px;
    height: 48px;

    :global {
      p {
        @include fonts.WebLabel-2;
      }
    }
  }

  .kinoms {
    display: block;
    width: fit-content;
    margin-left: auto;
    overflow: visible;
  }

  .cardsWrapper {
    position: absolute;
    top: 80px;
    right: 0;
    transform: translateX(100%);
    width: var(--my-channel-mobile-kinoms-width);
    height: calc(100% - 80px);

    background-color: var(--color-notheme-bg-primary);
    transition: 0.3s transform ease;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .opened {
    transform: translateX(0);
  }
}

@include breakpoints.max-width-480 {
  .cardsWrapper {
    width: 100%;
  }

  .kinoms {
    margin-left: 0;
    width: 100%;
  }

  .dropdown {
    width: 100%;
    max-width: 100%;
  }

  .dropdownWrapper {
    grid-template-columns: calc(100% - 56px) auto;
  }
}
</style>
