<template>
  <section :class="$style.promoKinomsSlider">
    <app-slider
      ref="slider"
      :items="items"
      :breakpoints="sliderBreakpoints"
      :loop="true"
      :autoplay="true"
      :scroll-on-wheel="false"
      @on-slide-change="onPageChange"
      @on-slide-left="$emit('on-slide-left')"
      @on-slide-right="$emit('on-slide-right')"
    >
      <template #default="{ item, index }">
        <div
          :class="{
            [$style.promoKinomCard]: true,
            [$style.promoKinomCardMobile]: $isMobile,
          }"
        >
          <app-img
            v-if="(!isClient && index === 0) || currentPage !== index"
            :src="item.preview"
            :image-class="$style.posterImage"
            :width="$isMobile ? 360 : 1200"
          />
          <viju-kinom-video-player
            :current-slide-index="currentPage"
            :show-fullscreen-button="false"
            :has-redirect-kinom-offset="false"
            :slide-index="index"
            :auto-start-load="false"
            :autoplay="true"
            :use-intersect="true"
            :is-first-timeout-loading="index === 0"
            :player-height-css="playerHeightCss"
            :lazy-interaction-loading="true"
            :kinom="item"
            :loop="false"
            :allow-fullscreen="false"
            :muted="true"
            :poster="item.preview"
            :src="item.hls"
            @ready="onPlayerReady"
            @ended="selectNextItem"
          />
          <app-link
            v-if="item"
            :to="getContentRoute(item, { isPlayerPage: shouldRedirectToPlayerPage(item.accessKind) })"
            @click="onContentLinkClick(item, index)"
          >
            <div :class="$style.infoWrapper">
              <app-img
                v-if="item.logo"
                :src="item.logo"
                :width="330"
                :image-class="$style.logoImage"
                :lazy="index !== 0"
              />
              <p v-else :class="$style.logoStub">{{ item.contentTitle }}</p>
              <app-button
                :text="isTinyView ? ' ' : primaryButtonText"
                :class="$style.primaryButton"
                :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'kinomSliderPrimaryButton')"
              >
                <template #icon>
                  <icon-video-state v-if="isTinyView" mode="play" size="small" />
                </template>
              </app-button>
            </div>
          </app-link>
        </div>
      </template>
    </app-slider>
  </section>
</template>

<script lang="ts" setup>
import useContentMediaButtonTexts from '@package/content-utils/src/code/content-media-button-texts';
import { VijuPlayer } from '@package/media-player/src/player/modules/instance/player';
import { useContentPageAnalytics } from '@package/sdk/src/analytics';
import type { ContentMoment } from '@package/sdk/src/api';
import { throttle } from '@package/sdk/src/core';
import { isClient, useWindowSize } from '@vueuse/core';

import type { SliderBreakpoints } from '@/components/app-slider/AppSlider.vue';
import IconVideoState from '@/components/icons/IconVideoState.vue';
import AppButton from '@/components/ui/AppButton.vue';
import AppImg from '@/components/ui/AppImg.vue';
import AppLink from '@/components/ui/AppLink.vue';
import VijuKinomVideoPlayer from '@/components/video-player/VijuKinomVideoPlayer.vue';
import useMobile from '@/platform/layout/use-mobile';
import useLocale from '@/platform/localization/use-locale';
import { useRouteUtils } from '@/platform/router/use-route-utils';
import { useCatalogStore } from '@/stores/use-catalog-store';

const { $analyticSender } = useNuxtApp();
const contentPageAnalytics = useContentPageAnalytics($analyticSender);
const catalogStore = useCatalogStore();

const isMobile = useMobile();
const { getContentRoute, shouldRedirectToPlayerPage } = useRouteUtils();
const { getMyChannelPlayButtonText } = useContentMediaButtonTexts();
const { width } = useWindowSize();
const { translateContentButtonText } = useLocale();

withDefaults(
  defineProps<{
    items: ContentMoment[];
  }>(),
  {
    items: () => [],
  },
);

const emit = defineEmits<{
  (e: 'on-slide-change', value: number): void;
  (e: 'on-slide-left'): void;
  (e: 'on-slide-right'): void;
  (e: 'on-player-ready', player: VijuPlayer): void;
}>();

const sliderBreakpoints: SliderBreakpoints = {
  mobile: 1,
  smallTablet: 1,
  mediumTablet: 1,
  bigTablet: 1,
  smallDesktop: 1,
  mediumDesktop: 1,
  bigDesktop: 1,
  largeDesktop: 1,
};

const currentPage = ref(0);
const slider = ref<ComponentPublicInstance<{ currentSlide: number; nextSlide: () => void }>>();

const isTinyView = computed(() => width.value < 380);

const playerHeightCss = computed(() => {
  if (isMobile) {
    return '100%';
  }

  return '75vh';
});

const primaryButtonText = computed(() =>
  translateContentButtonText(
    getMyChannelPlayButtonText({
      isForceSimpleWatchButtonText: true,
    }),
  ),
);

const onPageChange = (slide: number) => {
  currentPage.value = slide;
  emit('on-slide-change', currentPage.value);
};

const onContentLinkClick = (item: ContentMoment, index: number) => {
  catalogStore.setCurrentLinkedContentKinom(item);
  contentPageAnalytics.onGotoItemPageFromPromoSlider(item, index);
};

const selectNextItem = throttle(() => {
  if (!slider.value) {
    return;
  }

  slider.value?.nextSlide();
}, 500);

const onPlayerReady = (plr: VijuPlayer) => {
  emit('on-player-ready', plr);
};
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '@/assets/breakpoints' as breakpoints;

.promoKinomsSlider {
  padding: 0 var(--g-spacing-48);
  overflow: hidden;
}

.promoKinomCard {
  position: relative;
  height: v-bind(playerHeightCss);
  border-radius: var(--g-border-round-32);
  overflow: hidden;
}

.promoKinomCardMobile {
  aspect-ratio: 16/9;
}

.infoWrapper {
  position: absolute;
  bottom: var(--g-spacing-48);
  left: var(--g-spacing-48);
}

.logoImage {
  width: 295px;
}

.logoStub {
  @include fonts.WebSubtitle-1;
}

.primaryButton {
  margin-top: var(--g-spacing-24);
}

.posterImage {
  transform: scale(1.25);
  width: 100%;
  height: 100%;
}

@include breakpoints.max-width-800 {
  .promoKinomsSlider {
    padding: 0 var(--g-spacing-20);
  }

  .infoWrapper {
    bottom: var(--g-spacing-16);
    left: var(--g-spacing-16);
  }
}

@include breakpoints.max-width-540 {
  .infoWrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    cursor: pointer;
    width: calc(100% - 32px);
  }

  .logoImage {
    max-width: 130px;
  }

  .primaryButton {
    min-width: fit-content;
    margin: 0 0 0 var(--g-spacing-8);
    padding: var(--g-spacing-12) !important;
  }
}
</style>
