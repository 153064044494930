import { PromoCodeState } from '@package/sdk/src/api';

import type { AuthLoginType } from '@/code/auth/use-session-actions';
import { UserPresence } from '@/code/auth/use-session-actions';

export const useAuthRegistrationStore = defineStore('auth-registration', () => {
  const login = ref('');
  const password = ref('');
  const promocode = ref('');

  const isImmediatePaymentRedirect = ref(false);
  const setIsImmediatePaymentRedirect = (value: boolean) => {
    isImmediatePaymentRedirect.value = value;
  };

  const isPasswordRestoreByPhone = ref(false);
  const setIsRestorePasswordByPhone = (value: boolean) => {
    isPasswordRestoreByPhone.value = value;
  };

  const phoneCodeCounter = ref(30);
  const setPhoneCodeCounter = (value: number) => {
    phoneCodeCounter.value = value;
  };

  const tempSessionToken = ref('');
  const setTempSessionToken = (value: string) => {
    tempSessionToken.value = value;
  };

  const phoneCode = ref('');
  const setPhoneCode = (value: string) => {
    phoneCode.value = value;
  };

  const passwordRestoreToken = ref('');
  const setPasswordRestoreToken = (value: string) => {
    passwordRestoreToken.value = value;
  };

  const promocodeState = ref(PromoCodeState.NotSettled);
  const setPromocodeState = (value: PromoCodeState) => {
    promocodeState.value = value;
  };

  const promoOfferErrorMessage = ref('');
  const setPromoOfferErrorMessage = (value: string) => {
    promoOfferErrorMessage.value = value;
  };

  const loginType = ref<AuthLoginType>('phone');
  const setLoginType = (value: AuthLoginType) => {
    loginType.value = value;
  };

  const presenceType = ref(UserPresence.NotFound);
  const setPresenceType = (value: UserPresence) => {
    presenceType.value = value;
  };

  const isUserRegistered = computed(() => presenceType.value === UserPresence.Exist);

  const setLogin = (value: string) => {
    login.value = value;
  };

  const setPassword = (value: string) => {
    password.value = value;
  };

  const setPromocode = (value: string) => {
    promocode.value = value;
  };

  const reset = () => {
    login.value = '';
    promocode.value = '';
    password.value = '';
    presenceType.value = UserPresence.NotFound;
    loginType.value = 'phone';
    passwordRestoreToken.value = '';
    phoneCode.value = '';
    isPasswordRestoreByPhone.value = false;
    promocodeState.value = PromoCodeState.NotSettled;
    tempSessionToken.value = '';
  };

  return {
    setPasswordRestoreToken,
    setPromocodeState,
    setPromoOfferErrorMessage,
    setLoginType,
    setLogin,
    setPassword,
    setPromocode,
    setPresenceType,
    setIsRestorePasswordByPhone,
    setPhoneCode,
    reset,
    setTempSessionToken,
    setPhoneCodeCounter,
    setIsImmediatePaymentRedirect,
    isImmediatePaymentRedirect,
    phoneCodeCounter,
    tempSessionToken,
    phoneCode,
    promocodeState,
    passwordRestoreToken,
    isPasswordRestoreByPhone,
    promoOfferErrorMessage,
    isUserRegistered,
    loginType,
    login,
    password,
    promocode,
  };
});
