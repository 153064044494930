<template>
  <app-modal-wrapper :class="$style.appModalWrapper" :max-width="480">
    <div :class="$style.cloudpaymentsWidgetWrapper">
      <div v-if="isHeaderShown" :class="$style.header">
        <div :class="$style.logo">
          <icon-logo size="mini" />
        </div>
        <div :class="$style.textWrapper">
          <p :class="$style.description">
            {{ cloudpaymentsPaymentInfo.description }}
          </p>
          <p :class="$style.amount">
            {{ amountWithCurrency }}
          </p>
        </div>
      </div>

      <app-loader :is-loaded="isCloudpaymentsWidgetMounted" />
      <!--   биндим opacity 0 пока виджет не загрузился, чтобы после завершения лоадера виджет уже был вмаунчен   -->
      <div :class="{ [$style.transparent]: !isCloudpaymentsWidgetMounted }">
        <div id="cloudpaymentsWidget" :class="$style.cloudpaymentsWidget" />
      </div>
    </div>
  </app-modal-wrapper>
</template>

<script setup lang="ts">
import { watchOnce } from '@vueuse/core';

import { useLayoutStore } from '../../stores/use-layout-store';
import usePaymentsStore from '../../stores/use-payments-store';
import AppLoader from '../app-loader/AppLoader.vue';
import AppModalWrapper from '../app-modal/AppModalWrapper.vue';
import IconLogo from '../icons/IconLogo.vue';

const paymentStore = usePaymentsStore();
const layoutStore = useLayoutStore();
const { isCloudpaymentsWidgetMounted, cloudpaymentsPaymentInfo } = storeToRefs(paymentStore);
const { currentModalName } = storeToRefs(layoutStore);

const route = useRoute();

const isHeaderShown = computed(
  () => isCloudpaymentsWidgetMounted.value && Boolean(cloudpaymentsPaymentInfo.value.invoiceId),
);

const amountWithCurrency = computed(
  () => `${cloudpaymentsPaymentInfo.value.amount} ${cloudpaymentsPaymentInfo.value.currency}`,
);

const closeModal = () => {
  if (currentModalName.value !== 'CloudpaymentsWidgetModal') {
    return;
  }

  layoutStore.setCurrentModalName(null);
  paymentStore.setIsCloudpaymentsWidgetMounted(false);
  paymentStore.setCloudpaymentsPaymentInfoToDefault();
};

onMounted(async () => {
  await nextTick();

  watchOnce(
    () => route.fullPath,
    () => closeModal(),
    { deep: false, immediate: false },
  );
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '../../assets/breakpoints' as breakpoints;

.appModalWrapper {
  padding-bottom: 0;
}

.cloudpaymentsWidgetWrapper {
  width: 100%;
  min-height: 500px;
}

.cloudpaymentsWidget {
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.header {
  margin-bottom: var(--g-spacing-24);
  display: grid;
  gap: 16px;
  grid-template-columns: 80px 1fr;
}

.logo {
  display: flex;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: var(--g-border-round-16);
  background-color: var(--color-text-primary); // нужно заменить лого на иконку с фоном, тут фон убрать
  justify-content: center;
}

.description {
  @include fonts.WebBody-1;
}

.amount {
  @include fonts.WebSubtitle-1;
}

.transparent {
  opacity: 0;
}

@include breakpoints.max-width-800 {
  .cloudpaymentsWidgetWrapper {
    width: 100%;
  }

  .cloudpaymentsWidget {
    height: 100%;
  }

  .textWrapper {
    margin-right: var(--g-spacing-32);
  }
}
</style>
