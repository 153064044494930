<template>
  <header
    :class="{
      [$style.header]: true,
      [$style.headerHidden]: isHeaderHidden,
      [$style.primaryColor]: hasPrimaryColor && !headerComp?.isMenuOpen,
      [$style.headerBackground]: isBackgroundShown,
      [$style.headerHover]: isBackgroundHoverShown && !$isMobile,
    }"
  >
    <app-header-container />
  </header>
</template>

<script lang="ts" setup>
import ConstantsConfigInstanceWeb from '@package/constants/code/constants-config-web';
import { isClient } from '@vueuse/core';

import { AppTheme } from '@/code/layout/app-theme';
import useThemeColor from '@/code/layout/use-theme-color';
import { CookieName } from '@/platform/cookies/cookies';
import useAppCookie from '@/platform/cookies/use-app-cookie';
import { AppRoute } from '@/platform/router/routes';
import { useLayoutStore } from '@/stores/use-layout-store';

import AppHeaderContainer from './AppHeaderContainer.vue';

const { setThemeColor } = useThemeColor();
const { currentModalName } = storeToRefs(useLayoutStore());

const headerComp = ref<
  ComponentPublicInstance<{
    isMenuOpen: Ref<boolean>;
  }>
>();

const route = useRoute();
const appThemeCookie = useAppCookie(CookieName.AppTheme);

const HEADER_PRIMARY_COLORED_ROUTES = [AppRoute.MyChannelMoment];
const HEADER_HIDDEN_ROUTES = [AppRoute.MoviePlayer, AppRoute.SerialPlayer];

const HOVERED_BACKGROUND_EXCLUDED_ROUTES = [AppRoute.MyChannelMoment, AppRoute.Offers, AppRoute.OffersPromo];

const isBackgroundShown = ref(false);

const isBackgroundHoverShown = computed(() => !HOVERED_BACKGROUND_EXCLUDED_ROUTES.includes(route.name as AppRoute));
const isHeaderHidden = computed(() => HEADER_HIDDEN_ROUTES.includes(route.name as AppRoute));
const hasPrimaryColor = computed(() => route.name && HEADER_PRIMARY_COLORED_ROUTES.includes(route.name as AppRoute));

const onScroll = (event: Event) => {
  const target = event.target as HTMLElement;
  isBackgroundShown.value =
    target.scrollTop >= ConstantsConfigInstanceWeb.getProperty('scrollHeightToShowHeaderBackgroundPx');
};

watch(
  hasPrimaryColor,
  (val) => {
    if (val) {
      return setThemeColor('#000000');
    }
    const color = appThemeCookie.value === AppTheme.Light ? 'rgba(242, 235, 233, 1)' : 'rgba(8, 17, 16, 1)';
    setThemeColor(color);
  },
  { immediate: isClient },
);

watch(currentModalName, (value, prevValue) => {
  if (value === 'MyChannelModal') {
    isBackgroundShown.value = false;
    return;
  }

  if (!value && prevValue === 'MyChannelModal') {
    isBackgroundShown.value = true;
  }
});

onMounted(() => {
  const element = document.querySelector('#inner-body');

  if (element) {
    element.addEventListener('scroll', onScroll);
  }
});

onBeforeUnmount(() => {
  const element = document.querySelector('#inner-body');

  if (element) {
    element.removeEventListener('scroll', onScroll);
  }
});
</script>

<style lang="scss" module>
@use '../../assets/breakpoints' as breakpoints;

.header {
  position: absolute;
  top: 0;
  z-index: var(--z-index-header);
  width: 100%;
}

.headerHidden {
  display: none;
}

.headerHover {
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: var(--color-bg-primary);
  }
}

.headerBackground {
  background-color: var(--color-bg-primary);
  transition: background-color 0.2s;
}

@include breakpoints.max-width-1024 {
  .primaryColor {
    background-color: var(--color-notheme-bg-primary);
  }
}
</style>
