<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.55806 1.55806C3.80214 1.31398 4.19786 1.31398 4.44194 1.55806L6 3.11612L7.55806 1.55806C7.80214 1.31398 8.19786 1.31398 8.44194 1.55806C8.68602 1.80214 8.68602 2.19786 8.44194 2.44194L7.38388 3.5H9.75C10.1642 3.5 10.5 3.83579 10.5 4.25V9.75C10.5 10.1642 10.1642 10.5 9.75 10.5H2.25C1.83579 10.5 1.5 10.1642 1.5 9.75V4.25C1.5 3.83579 1.83579 3.5 2.25 3.5H4.61612L3.55806 2.44194C3.31398 2.19786 3.31398 1.80214 3.55806 1.55806Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.38128 1.38128C4.72299 1.03957 5.27701 1.03957 5.61872 1.38128L8 3.76256L10.3813 1.38128C10.723 1.03957 11.277 1.03957 11.6187 1.38128C11.9604 1.72299 11.9604 2.27701 11.6187 2.61872L9.98744 4.25H13.625C14.2463 4.25 14.75 4.75368 14.75 5.375V13.625C14.75 14.2463 14.2463 14.75 13.625 14.75H2.375C1.75368 14.75 1.25 14.2463 1.25 13.625V5.375C1.25 4.75368 1.75368 4.25 2.375 4.25H6.01256L4.38128 2.61872C4.03957 2.27701 4.03957 1.72299 4.38128 1.38128Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.29289 3.29289C7.68342 2.90237 8.31658 2.90237 8.70711 3.29289L12 6.58579L15.2929 3.29289C15.6834 2.90237 16.3166 2.90237 16.7071 3.29289C17.0976 3.68342 17.0976 4.31658 16.7071 4.70711L14.4142 7H19.5C20.3284 7 21 7.67157 21 8.5V19.5C21 20.3284 20.3284 21 19.5 21H4.5C3.67157 21 3 20.3284 3 19.5V8.5C3 7.67157 3.67157 7 4.5 7H9.58579L7.29289 4.70711C6.90237 4.31658 6.90237 3.68342 7.29289 3.29289Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.44945 3.78262C9.9376 3.29447 10.7291 3.29447 11.2172 3.78262L16 8.5654L20.7828 3.78262C21.2709 3.29447 22.0624 3.29447 22.5505 3.78262C23.0387 4.27078 23.0387 5.06223 22.5505 5.55039L19.1844 8.9165H26.625C27.7986 8.9165 28.75 9.8679 28.75 11.0415V26.6248C28.75 27.7984 27.7986 28.7498 26.625 28.7498H5.375C4.20139 28.7498 3.25 27.7984 3.25 26.6248V11.0415C3.25 9.8679 4.20139 8.9165 5.375 8.9165H12.8156L9.44945 5.55039C8.96129 5.06223 8.96129 4.27078 9.44945 3.78262Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import AppIcon from './AppIcon.vue';
import { type IconSize, getBoundsByIconSize, getSVGViewboxByIconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
