import type { ApiSerial, ApiStreamManifest, FilteredMedia, StreamManifest, Year } from '@package/sdk/src/api';
import { MediaContentType, SerialMapper, StreamManifestMapper } from '@package/sdk/src/api';
import { coalesce } from '@SDK/core';

import { type EndpointQueryMap, ENDPOINTS } from '@/platform/http';

import { RequestId } from '../requestId';
import { useSeoApi } from '../seo/use-seo-api';

export function useSerialApi() {
  const { $http } = useNuxtApp();
  const seoApi = useSeoApi();

  const getStreamManifest = (id: string): Promise<StreamManifest> => {
    return $http
      .get<ApiStreamManifest>(ENDPOINTS.CONTENT_SERIAL_MANIFEST, { params: { id } })
      .then(StreamManifestMapper.map);
  };

  const fetchSerial = (id: string) => {
    return $http
      .get<ApiSerial>(ENDPOINTS.CONTENT_SERIALS_SLUG, {
        params: { id },
        query: {
          with_content_match_percent_by_profile: true,
          platform: 'web',
        },
      })
      .then(SerialMapper.map);
  };

  const fetchSerials = async ({
    order,
    page,
    years,
    countries,
    genres,
    with_locked,
    per_page,
  }: any): Promise<FilteredMedia> => {
    const query: EndpointQueryMap['CONTENT_SERIALS'] = {
      years: years?.map((year: Year) => ({ from: year.from, to: year.to })),
      genres_slugs: coalesce(genres?.map((genre: string) => genre.replaceAll('_', '-'))),
      countries_slugs: coalesce(countries?.map((country: string) => country.replaceAll('_', '-'))),
      order,
      page,
      with_locked,
      with_seasons: false,
      per_page,
    };

    const result = await seoApi.fetchCatalogSeoData({ contentType: MediaContentType.SERIAL, query });

    const { data, headers } = await $http.sendRawRequest<{ data: ApiSerial[]; headers: Record<string, any> }>(
      'GET',
      ENDPOINTS.CONTENT_SERIALS,
      undefined,
      { requestId: RequestId.GetSerialList, query },
    );

    return {
      pageTitle: result?.base,
      contents: (data || []).map(SerialMapper.map),
      totalCount: Number(headers['total-count'] || 0),
    } as FilteredMedia;
  };

  return { fetchSerial, getStreamManifest, fetchSerials };
}
