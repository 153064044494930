<template>
  <app-slot-button
    :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Header, 'accountButton')"
    :class="{
      [$style.lkButton]: !isAuth,
      [$style.lkButtonAuth]: isAuth,
      [$style.lkButtonLkPage]: isLkPage,
    }"
    @click="onClickLoginButton()"
  >
    <div v-if="isAuth" :class="$style.accountBlock">
      <icon-user size="small" />
      <span :class="$style.accountText">{{ $t('header.tabs.account') }}</span>
    </div>

    <span v-else :class="$style.buttonText">{{ $t('header.tabs.login') }}</span>
  </app-slot-button>
</template>

<script lang="ts" setup>
import { useAccountPageAnalytics, useAuthPageAnalytics } from '@package/sdk/src/analytics';

import getKmzaPageValue from '@/code/kmza/get-kmza-page-value';
import { AdriverAnalyticEvent, useKmzaDriverEvents } from '@/code/kmza/use-driver-kmza';
import { AppRoute } from '@/platform/router/routes';

import IconUser from '../icons/IconUser.vue';
import AppSlotButton from '../ui/AppSlotButton.vue';
import useAppHeaderVariables from './use-app-header-variables';

const props = defineProps<{
  isAuth: boolean;
}>();

const { $analyticSender } = useNuxtApp();
const { profileLinkRoute } = useAppHeaderVariables();
const { onGotoAccountPage } = useAccountPageAnalytics($analyticSender);
const { onGotoAuthPage } = useAuthPageAnalytics($analyticSender);
const { injectAdriverScript } = useKmzaDriverEvents();
const router = useRouter();
const route = useRoute();

const lkRoutes = [
  AppRoute.Account,
  AppRoute.AccountInfo,
  AppRoute.AccountDevices,
  AppRoute.AccountSubscriptionInfo,
  AppRoute.AccountSubscriptionManage,
  AppRoute.AccountBonuses,
  AppRoute.AccountBonusesReferral,
  AppRoute.AccountBonusesPromocode,
  AppRoute.AccountProfiles,
];

const isLkPage = computed(() => lkRoutes.includes(router.currentRoute.value.name as AppRoute));

const onClickLoginButton = async () => {
  await router.push(profileLinkRoute.value);

  const page = getKmzaPageValue(route.name as AppRoute);

  if (props.isAuth) {
    onGotoAccountPage(page);
  } else {
    onGotoAuthPage(page, 'sign_in');
    injectAdriverScript(AdriverAnalyticEvent.AuthButton);
  }
};
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '../../assets/breakpoints' as breakpoints;

.lkButton {
  padding: var(--g-spacing-12) 0;
  height: 40px;
  background-color: transparent;

  &:hover {
    .buttonText {
      color: var(--color-text-primary);
    }
  }
}

.lkButtonAuth {
  min-width: 40px;
  height: 40px;
  border-radius: var(--g-border-round-12);
  background-color: var(--color-bg-secondary-80);
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: var(--color-states-bg-secondary-hover-70);
  }
}

.lkButtonLkPage {
  border: 2px solid var(--color-stroke-border-accent);
}

.accountBlock {
  display: flex;
  align-items: center;
}

.accountText {
  display: none;
}

.buttonText {
  color: var(--color-text-additional);
  white-space: nowrap;
  transition: color 0.3s ease-in-out;

  @include fonts.WebLabel-2;
}

@include breakpoints.max-width-1024 {
  .lkButtonAuth {
    flex-shrink: 0;
  }
}

@include breakpoints.max-width-800 {
  .lkButton {
    padding: var(--g-spacing-8) var(--g-spacing-10);
    border-radius: var(--g-border-round-12);
    background-color: var(--color-notheme-bg-secondary-80);
  }

  .buttonText {
    color: var(--color-text-primary);
  }

  .accountText {
    display: inline-block;
    margin-left: var(--g-spacing-8);

    @include fonts.WebLabel-2;
  }
}
</style>
