<template>
  <div id="root-layout">
    <template v-if="isClient || !isWebviewMode">
      <labels-container-view :ref="(comp) => (labelEl = comp?.$el as HTMLElement)" />

      <transition name="fade">
        <app-notification-container v-if="currentNotification" />
      </transition>

      <transition name="fade">
        <app-modal v-if="currentModalName" />
      </transition>

      <transition name="fade">
        <tv-connection-notification v-if="isTvConnectNotificationShown" />
      </transition>
    </template>

    <app-header v-if="!isWebviewMode" />
    <div
      id="inner-body"
      ref="bodyEl"
      :class="{
        [$style.body]: true,
        [$style.bodyWebview]: isWebviewMode,
      }"
    >
      <main :key="currentPageKey" :class="{ [$style.main]: isMyChannelPage }">
        <nuxt-layout :class="{ [$style.layoutWrapper]: isMyChannelPage }">
          <client-only>
            <nuxt-loading-indicator color="var(--color-bg-accent)" />
          </client-only>
          <nuxt-page :class="$style.page" />
        </nuxt-layout>
      </main>
      <app-footer v-if="!isWebviewMode">
        <app-breadcrumbs />
      </app-footer>
    </div>
    <app-flocktory-user v-if="isAuth" />
  </div>
</template>

<script lang="ts" setup>
import '@/assets/main.scss';

import ConstantsConfigInstanceWeb from '@package/constants/code/constants-config-web';
import { timeout } from '@package/sdk/src/core';
import useLocalStorage from '@package/sdk/src/core/dom/use-local-storage';
import type { AnyFn } from '@vueuse/core';
import { isClient, watchAtMost } from '@vueuse/core';
import type { RouteLocationNormalized } from 'vue-router';

import useAuthActions from '@/code/auth/use-auth-actions';
import { CityAdsAnalyticEvent, useKmzaCityAdsEvents } from '@/code/kmza/use-citya-kmza';
import { AdriverAnalyticEvent, useKmzaDriverEvents } from '@/code/kmza/use-driver-kmza';
import { useKmzaGet4ClickEvents } from '@/code/kmza/use-get-four-kmza';
import { useKmzaGnezdoEvents } from '@/code/kmza/use-nest-kmza';
import { useKmzaWeboramaEvents, WeboramaAnalyticEvent } from '@/code/kmza/use-webama-kmza';
import { useMyChannelModal } from '@/code/my-channel/use-my-channel-modal';
import useMyChannelOnboarding from '@/code/my-channel/use-my-channel-onboarding';
import useReferralModal from '@/code/referral/use-referral-modal';
import AppFooter from '@/components/app-footer/AppFooter.vue';
import AppHeader from '@/components/app-header/AppHeader.vue';
import AppModal from '@/components/app-modal/AppModal.vue';
import AppNotificationContainer from '@/components/app-notification/AppNotificationContainer.vue';
import AppBreadcrumbs from '@/components/breadcrumbs/AppBreadcrumbs.vue';
import AppFlocktoryUser from '@/components/flocktory/AppFlocktoryUser.vue';
import TvConnectionNotification from '@/components/notifications/TvConnectionNotification.vue';
import LabelsContainerView from '@/components/ui/LabelsContainerView.vue';
import { pick } from '@/platform/base/function';
import { UtmQueryParameter } from '@/platform/base/utm';
import { AppRoute } from '@/platform/router/routes';
import type { LocalStorageValueMap } from '@/platform/storage/local-storage';
import { LocalStorageKey } from '@/platform/storage/local-storage';
import useHostVariation from '@/platform/variation/use-host-variation';
import { useLayoutStore } from '@/stores/use-layout-store';
import { useMyChannelStore } from '@/stores/use-my-channel-store';
import { useSessionStore } from '@/stores/use-session-store';
import { useTvCodeConnectionStore } from '@/stores/use-tv-code-connection-store';

const router = useRouter();
const route = useRoute();

const { $emitter } = useNuxtApp();
const layoutStore = useLayoutStore();
const { isWebviewMode, shouldResetPromocode, resetPromocode } = useHostVariation();
const authActions = useAuthActions();
const myChannelStore = useMyChannelStore();
const localStorage = useLocalStorage<LocalStorageKey, LocalStorageValueMap>();
const { injectAdriverScript } = useKmzaDriverEvents();
const { injectWeboramaPixel } = useKmzaWeboramaEvents();
const { injectCityAdsPixel, saveCityAdsClickId } = useKmzaCityAdsEvents();
const { injectGnezdoPixel } = useKmzaGnezdoEvents();
const { injectCrossmailScript } = useKmzaGet4ClickEvents();

const { resolveMyChannelOnboardingModal, myChannelPopupState } = useMyChannelOnboarding();

const { currentNotification, currentModalName, currentPageKey, hasActiveLayoutModal } = storeToRefs(layoutStore);
const { isTvConnectNotificationShown } = storeToRefs(useTvCodeConnectionStore());

if (shouldResetPromocode.value) {
  resetPromocode();
}

watchAtMost(
  () => route.name,
  async () => {
    if (myChannelPopupState.value[LocalStorageKey.MyChannelOnboardingPopupShown]) {
      myChannelStore.setMyChannelPopupState(LocalStorageKey.MyChannelOnboardingPopupShown, false);
      localStorage.setValue(LocalStorageKey.MyChannelOnboardingPopupShown, { value: true });
    } else {
      await nextTick();

      if (!hasActiveLayoutModal.value) {
        resolveMyChannelOnboardingModal();
      }
    }
  },
  { count: 4 },
);

watchAtMost(
  hasActiveLayoutModal,
  () => {
    if (!hasActiveLayoutModal.value) {
      resolveMyChannelOnboardingModal();
    }
  },
  { count: 2 },
);

const { fetchReferralProgram, onReferralAvailable } = useReferralModal();

const { isAuth } = storeToRefs(useSessionStore());
const { isMyChannelPage } = useMyChannelModal();

const isMainPage = computed(() => route.name === AppRoute.Index);
const labelEl = ref<HTMLElement>();
const bodyEl = ref<HTMLElement>();

provide('labelEl', labelEl);
provide('bodyEl', bodyEl);

const saveScrollPositionRouterHook = (_: RouteLocationNormalized, from: RouteLocationNormalized, next: AnyFn) => {
  const element = document.querySelector('#inner-body');

  if (element) {
    layoutStore.saveScrollPosition(from.name as AppRoute, { top: element.scrollTop, left: element.scrollLeft });
  }

  next();
};

const utmCampaignRouterHook = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: AnyFn) => {
  const toQuery = pick(to.query, Object.values(UtmQueryParameter));
  const fromQuery = pick(from.query, Object.values(UtmQueryParameter));
  const isToQueryEmpty = !Object.values(toQuery).length;
  const isFromQueryEmpty = !Object.values(fromQuery).length;

  if (!isToQueryEmpty || isFromQueryEmpty) {
    return next();
  }

  return next({ ...to, query: Object.assign(to.query, fromQuery) });
};

watch(isMainPage, onReferralAvailable, { immediate: isClient });

const { finish: finishLoadingIndicator } = useLoadingIndicator();

onMounted(() => {
  resolveMyChannelOnboardingModal();
  fetchReferralProgram();
  injectAdriverScript(AdriverAnalyticEvent.AppCounter);
  injectWeboramaPixel(WeboramaAnalyticEvent.AppCounter);
  injectCityAdsPixel(CityAdsAnalyticEvent.Common);
  injectGnezdoPixel();
  injectCrossmailScript();
  saveCityAdsClickId();

  router.beforeEach(saveScrollPositionRouterHook);
  router.beforeEach(utmCampaignRouterHook);

  const resetLoadingIndicator = async () => {
    // подчищаем индикатор, если вдруг он сам там не почистился
    await timeout(2000);
    finishLoadingIndicator();
  };

  router.afterEach(resetLoadingIndicator);

  $emitter.on('user.logout.complete', authActions.onDidUserLogout);
  $emitter.on('user.login.complete', authActions.onDidUserLogin);
  $emitter.on('user.registration.complete', authActions.onDidUserRegister);

  window.setTimeout(() => {
    injectWeboramaPixel(WeboramaAnalyticEvent.AppCounterAfterTimer);
  }, ConstantsConfigInstanceWeb.getProperty('weboramaSuccessfullyVisitorTimeoutMs'));
});

onBeforeUnmount(() => {
  $emitter.off('user.logout.complete', authActions.onDidUserLogout);
  $emitter.off('user.login.complete', authActions.onDidUserLogin);
  $emitter.off('user.registration.complete', authActions.onDidUserRegister);
});
</script>

<style lang="scss" module>
@use 'assets/breakpoints' as breakpoints;

.body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  background-color: var(--color-bg-primary);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: var(--app-header-height);

  &::-webkit-scrollbar {
    display: none;
  }
}

.bodyWebview {
  padding-top: 0;
}

.page {
  position: relative;
  display: flex;
  height: 100%;
  flex-grow: 1;
  flex-direction: column;
}

.main {
  height: 100%;
}

.layoutWrapper {
  height: 100%;
}

@include breakpoints.max-width-800 {
  .body {
    padding-top: var(--app-header-height--mobile);
  }

  .bodyWebview {
    padding-top: 0;
  }
}
</style>
