import useLogger from '@package/logger/src/use-logger';
import type { Playlist } from '@package/sdk/src/api';
import { isUndefinedOrNull, UnexpectedComponentStateError } from '@package/sdk/src/core';

import type { FetchMomentsPayload } from '@/code/moments/use-feed-api';
import { useFeedApi } from '@/code/moments/use-feed-api';
import type { MyChannelPopupKey } from '@/code/my-channel/use-my-channel-onboarding';
import { usePlaylistsApi } from '@/code/playlists/use-playlists-api';
import { LocalStorageKey } from '@/platform/storage/local-storage';

export const useMyChannelStore = defineStore('my-channel', () => {
  const feedApi = useFeedApi();
  const playlistsApi = usePlaylistsApi();
  const logger = useLogger('use-my-channel-store');
  const playlists = ref<Playlist[]>([]);

  const fetchPlaylists = async (): Promise<void> => {
    try {
      playlists.value = await playlistsApi.fetchPlaylists();
    } catch (error) {
      logger.error(error);

      playlists.value = [];
    }
  };

  const myChannelPopupState = ref<Record<MyChannelPopupKey, boolean>>({
    // Попап, что добавлилив коллекцию
    [LocalStorageKey.MyChannelCollectionPopupShown]: false,
    // Попап, что поставили лайк
    [LocalStorageKey.MyChannelLikePopupShown]: false,
    // Попап, что поставили дизлайк
    [LocalStorageKey.MyChannelDislikePopupShown]: false,
    // Попап онбоардинга
    [LocalStorageKey.MyChannelOnboardingPopupShown]: false,
    // Попап при заходе на страницу моего канала
    [LocalStorageKey.MyChannelWatchAndRatePopupShown]: false,
    // Про регистрацию
    [LocalStorageKey.MyChannelRegistrationPopupShown]: false,
  });

  const resetMyChannelPopupState = () => {
    myChannelPopupState.value[LocalStorageKey.MyChannelOnboardingPopupShown] = false;
    myChannelPopupState.value[LocalStorageKey.MyChannelLikePopupShown] = false;
    myChannelPopupState.value[LocalStorageKey.MyChannelDislikePopupShown] = false;
    myChannelPopupState.value[LocalStorageKey.MyChannelCollectionPopupShown] = false;
    myChannelPopupState.value[LocalStorageKey.MyChannelWatchAndRatePopupShown] = false;
    myChannelPopupState.value[LocalStorageKey.MyChannelRegistrationPopupShown] = false;
  };

  const setMyChannelPopupState = (key: MyChannelPopupKey, value: boolean) => {
    if (isUndefinedOrNull(myChannelPopupState.value[key])) {
      throw new UnexpectedComponentStateError(key);
    }

    resetMyChannelPopupState();
    myChannelPopupState.value[key] = value;
  };

  const fetchMoments = (payload: FetchMomentsPayload) => {
    try {
      return feedApi.fetchMoments(payload);
    } catch (error) {
      logger.error(error);
      return [];
    }
  };

  return {
    myChannelPopupState,
    playlists,
    fetchPlaylists,
    setMyChannelPopupState,
    fetchMoments,
    resetMyChannelPopupState,
  };
});
