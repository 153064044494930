<template>
  <div :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'continueWatch')">
    <h2 :class="$style.continueWatchTitle">{{ $t('content.watchingItems.continueWatch') }}</h2>

    <app-slider
      :breakpoints="sliderBreakpoints"
      :items="innerItems"
      :class="$style.carousel"
      @on-slide-left="emit('on-slide-left')"
    >
      <template v-slot="{ item, index }">
        <watch-progress-card
          :id="item.id"
          :key="item.id"
          :content="item as Media"
          @clear-item="onClearItem(item as Media)"
          @click="onContentCardClick(item as Media, index)"
        />
      </template>
    </app-slider>
  </div>
</template>

<script lang="ts" setup>
import useLogger from '@package/logger/src/use-logger';
import {
  AnalyticPageName,
  ItemPageFrom,
  useContentPageAnalytics,
  useMainPageAnalytics,
} from '@package/sdk/src/analytics';
import type { Media } from '@package/sdk/src/api';
import { MediaContentType } from '@package/sdk/src/api';

import { NotificationLevel } from '@/code/notifications/notification';
import { useWatchingItemsApi } from '@/code/watching-items/use-watching-items-api';
import type { SliderBreakpoints } from '@/components/app-slider/AppSlider.vue';
import WatchProgressCard from '@/components/content-page/WatchProgressCard.vue';
import useLocale from '@/platform/localization/use-locale';
import { useLayoutStore } from '@/stores/use-layout-store';

const props = defineProps<{
  items: Media[];
}>();

const emit = defineEmits<{
  (e: 'on-slide-left'): void;
}>();

const sliderBreakpoints: SliderBreakpoints = {
  mobile: 1,
  smallTablet: 2,
  mediumTablet: 3,
  bigTablet: 4,
  smallDesktop: 4,
  mediumDesktop: 4,
  bigDesktop: 4,
  largeDesktop: 4,
};

const { $analyticSender } = useNuxtApp();
const watchingItemsApi = useWatchingItemsApi();
const mainPageAnalytics = useMainPageAnalytics($analyticSender);
const { onGotoItemPage } = useContentPageAnalytics($analyticSender);
const layoutStore = useLayoutStore();
const { translate } = useLocale();

const logger = useLogger('ContinueWatch');
const { $contentCacheManager } = useNuxtApp();

const innerItems = ref<Media[]>(props.items);

const onClearItem = async (item: Media) => {
  const { id } = item;

  try {
    await watchingItemsApi.hideItem(id);

    const currentIndex = innerItems.value.findIndex((item) => item.id === id);
    innerItems.value.splice(currentIndex, 1);

    mainPageAnalytics.onContinueWatchItemHide(item);

    const message =
      item.contentType === MediaContentType.MOVIE
        ? translate('page.main.continueWatch.movieDeleted')
        : translate('page.main.continueWatch.serialDeleted');
    layoutStore.addNotification({
      level: NotificationLevel.Info,
      message,
      hideTimeoutMs: 3000,
      position: 'top',
      hasCloseIcon: false,
    });
  } catch (err) {
    logger.error(err);
  }
};

const onContentCardClick = (item: Media, index: number) => {
  $contentCacheManager.addToCache(item);

  const serialId = item.serialId;
  const season = item.watchingItem?.seasonNumber;
  const episode = item.watchingItem?.episodeNumber;

  onGotoItemPage({
    title: item.contentType !== MediaContentType.EPISODE ? item.title : (item.serialTitle as string),
    contentType: item.contentType,
    from: ItemPageFrom.ContinueWatch,
    page: AnalyticPageName.Main,
    itemId: serialId || item.id,
    episodeId: item.contentType === MediaContentType.EPISODE ? item.id : undefined,
    ...(season && { season }),
    ...(episode && { episode }),
    position: index,
  });
};
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;

.continueWatchTitle {
  @include fonts.WebTitle-2;
}

.carousel {
  margin-top: 30px;
}
</style>
