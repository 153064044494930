<template>
  <footer
    v-show="isFooterShown"
    :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Footer, 'block')"
    :class="$style.footer"
  >
    <slot />

    <section :class="$style.footerWrapper">
      <div :class="$style.footerBlock">
        <div :class="$style.textAndStores">
          <div :class="$style.smartTvStoreBlock">
            <p :class="$style.smartTvStoreText" v-html="$t('footer.watchVijuOnTv')" />
            <app-link :to="{ name: AppRoute.ConnectTv }">
              <icon-smart-tv-store :class="$style.storeIcon" />
            </app-link>
          </div>

          <p :class="$style.footerText">
            {{ $t('footer.mainText') }}
          </p>

          <div v-if="appStoreLinks.length > 0" :class="$style.footerStores">
            <app-link
              v-for="store in appStoreLinks"
              :key="store.title"
              :to="store.link"
              :title="store.title"
              :aria-label="store.title"
              :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Footer, 'storeItem')"
              @click="onFooterLinkClick(store.analyticEventName)"
            >
              <component :is="store.icon" :class="$style.storeIcon" />
            </app-link>
          </div>
        </div>

        <ul role="list" :class="$style.footerList">
          <li :class="$style.footerListItem">
            <p>{{ $t('footer.service') }}</p>
          </li>
          <li :class="$style.footerListItem">
            <app-link
              :to="{ name: $AppRoute.MyChannel }"
              :class="$style.footerListItemLink"
              :aria-label="$t('ariaLabel.pages.goToPage', { pageName: $t('footer.myChannel') })"
              :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Footer, 'myChannelLink')"
              @click="onFooterLinkClick($AnalyticEventNameNew.GoToMyChannelPage)"
            >
              {{ $t('footer.myChannel') }}
            </app-link>
          </li>
          <li :class="$style.footerListItem">
            <app-link
              :to="{ name: $AppRoute.Movies }"
              :class="$style.footerListItemLink"
              :aria-label="$t('ariaLabel.pages.goToPage', { pageName: $t('footer.movies') })"
              :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Footer, 'moviesLink')"
              @click="onFooterLinkClick($AnalyticEventNameNew.GotoCatalogPage)"
            >
              {{ $t('footer.movies') }}
            </app-link>
          </li>
          <li :class="$style.footerListItem">
            <app-link
              :to="{ name: $AppRoute.Serials }"
              :class="$style.footerListItemLink"
              :aria-label="$t('ariaLabel.pages.goToPage', { pageName: $t('footer.series') })"
              :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Footer, 'seriesLink')"
              @click="onFooterLinkClick($AnalyticEventNameNew.GotoCatalogPage)"
            >
              {{ $t('footer.series') }}
            </app-link>
          </li>
          <li :class="$style.footerListItem">
            <app-link
              :to="{ name: $AppRoute.TV }"
              :class="$style.footerListItemLink"
              :aria-label="$t('ariaLabel.pages.goToPage', { pageName: $t('footer.tv') })"
              :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Footer, 'tvLink')"
              @click="onFooterLinkClick($AnalyticEventNameNew.GotoTvPage)"
            >
              {{ $t('footer.tv') }}
            </app-link>
          </li>
          <li :class="$style.footerListItem">
            <app-link
              :to="{ name: $AppRoute.Compilations }"
              :class="[$style.footerListItemLink, $style.footerListItemLinkLast]"
              :aria-label="$t('ariaLabel.pages.goToPage', { pageName: $t('footer.compilations') })"
              :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Footer, 'compilationsLink')"
              @click="onFooterLinkClick($AnalyticEventNameNew.GoToGeneralCompilationPage)"
            >
              {{ $t('footer.compilations') }}
            </app-link>
          </li>
        </ul>

        <ul role="list" :class="$style.footerList">
          <li :class="$style.footerListItem">
            <p>{{ $t('footer.information') }}</p>
          </li>
          <li :class="$style.footerListItem">
            <app-link
              :to="{ name: $AppRoute.UserAgreement }"
              :class="$style.footerListItemLink"
              :aria-label="$t('ariaLabel.pages.goToPage', { pageName: $t('footer.userAgreement') })"
              :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Footer, 'userAgreementLink')"
              target="_blank"
              @click="onFooterLinkClick($AnalyticEventNameNew.GotoUserAgreement)"
            >
              {{ $t('footer.userAgreement') }}
            </app-link>
          </li>
          <li :class="$style.footerListItem">
            <app-link
              :to="{ name: $AppRoute.PrivacyPolicy }"
              :class="$style.footerListItemLink"
              :aria-label="$t('ariaLabel.pages.goToPage', { pageName: $t('footer.privacyPolicy') })"
              :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Footer, 'privacyPolicyLink')"
              target="_blank"
              @click="onFooterLinkClick($AnalyticEventNameNew.GotoPrivacyPolicy)"
            >
              {{ $t('footer.privacyPolicy') }}
            </app-link>
          </li>
          <li v-if="isRecommendationsTechShown" :class="$style.footerListItem">
            <app-link
              :to="{ name: $AppRoute.RecommendationTechnologies }"
              :class="$style.footerListItemLink"
              :aria-label="$t('ariaLabel.pages.goToPage', { pageName: $t('footer.recommendationTechnologies') })"
              :data-tid="
                $getTestElementIdentifier($ElementTestIdentifierScope.Footer, 'recommendationTechnologiesLink')
              "
              target="_blank"
              @click="onFooterLinkClick($AnalyticEventNameNew.GotoRecommendationTechnologies)"
            >
              {{ $t('footer.recommendationTechnologies') }}
            </app-link>
          </li>
          <li :class="$style.footerListItem">
            <app-link
              :to="{ name: $AppRoute.Contacts }"
              :class="[$style.footerListItemLink, $style.footerListItemLinkLast]"
              :aria-label="$t('ariaLabel.pages.goToPage', { pageName: $t('footer.contacts') })"
              :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Footer, 'contactsLink')"
              @click="onFooterLinkClick($AnalyticEventNameNew.GotoContactsPage)"
            >
              {{ $t('footer.contacts') }}
            </app-link>
          </li>
        </ul>
      </div>
      <div
        :class="{
          [$style.footerBlock]: true,
          [$style.footerBlockLast]: true,
        }"
      >
        <ul v-if="isSocialsBlockShown" role="list" :class="$style.footerSocials">
          <li v-for="item in socialMediaLinks" :key="item.name">
            <app-link
              :to="item.href"
              :title="$t(`ariaLabel.socialMedia.${item.name}`)"
              :aria-label="$t(`ariaLabel.socialMedia.${item.name}`)"
              :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Footer, 'socialsItem')"
              target="_blank"
              @click="injectAdriverScript(AdriverAnalyticEvent.GoToSocialNetworks)"
            >
              <p :class="$style.footerSocialsIcon" v-html="item.icon" />
            </app-link>
          </li>
        </ul>

        <p
          :class="{
            [$style.appVersion]: true,
            [$style.appVersionWithoutSocials]: !isSocialsBlockShown,
          }"
          @click="onCopyDebugInfo()"
        >
          {{ $t('footer.appVersion', { appVersion }) }}
        </p>

        <p :class="$style.footerCopyright">{{ $t('footer.copyRight', { currentYear }) }}</p>
      </div>
    </section>
  </footer>
</template>

<script lang="ts" setup>
import { AnalyticEventNameNew } from '@package/sdk/src/analytics';
import { copyToClipboard } from '@package/sdk/src/core';
import { getYear } from 'date-fns';

import useDebugInformation from '@/code/debug/use-debug-information';
import getKmzaPageValue from '@/code/kmza/get-kmza-page-value';
import { AdriverAnalyticEvent, useKmzaDriverEvents } from '@/code/kmza/use-driver-kmza';
import IconSmartTvStore from '@/components/icons/connect-tv/IconSmartTvStore.vue';
import useEnvironment from '@/platform/environment/use-environment';
import { AppRoute } from '@/platform/router/routes';
import useHostVariation from '@/platform/variation/use-host-variation';
import useVariationInfo from '@/platform/variation/use-variation-info';
import { useCatalogStore } from '@/stores/use-catalog-store';

import AppLink from '../ui/AppLink.vue';
const { getAppStoreLinks, getSocialMediaLinks } = useVariationInfo();
const route = useRoute();
const { $sendAnalyticEvent } = useNuxtApp();
const { appVersion } = useEnvironment();
const { isRecommendationsTechShown, appVariation } = useHostVariation();

const { injectAdriverScript } = useKmzaDriverEvents();

const catalogStore = useCatalogStore();

const FOOTER_EXCLUDED_ROUTES = [
  AppRoute.Account,
  AppRoute.AccountInfo,
  AppRoute.AccountDevices,
  AppRoute.AccountProfiles,
  AppRoute.AccountSubscriptionManage,
  AppRoute.AccountSubscriptionInfo,
  AppRoute.AccountBonuses,
  AppRoute.AccountBonusesReferral,
  AppRoute.AccountBonusesPromocode,
  AppRoute.AccountLanguage,
  AppRoute.Offers,
  AppRoute.OffersPromo,
  AppRoute.OffersPay,
  AppRoute.AuthEnter,
  AppRoute.Auth,
  AppRoute.AuthEnterPassword,
  AppRoute.AuthPasswordLinkExpired,
  AppRoute.AuthRestorePassword,
  AppRoute.AuthPasswordRestoreEmail,
  AppRoute.AuthPasswordRestorePhone,
  AppRoute.MyChannel,
  AppRoute.MyChannelMoment,
  AppRoute.Referral,
  AppRoute.InternalServerError,
  AppRoute.TVCodePage,
  AppRoute.GoTVCodePage,
];

const isFooterShown = computed(() => route.name && !FOOTER_EXCLUDED_ROUTES.includes(route.name as AppRoute));
const currentYear = getYear(new Date());

const appStoreLinks = getAppStoreLinks(appVariation);

const socialMediaLinks = getSocialMediaLinks(appVariation);

const isSocialsBlockShown = computed(() => socialMediaLinks.length > 0);

const onFooterLinkClick = async (eventName: AnalyticEventNameNew) => {
  catalogStore.selectedSlugsWatcher.pause();
  catalogStore.resetSlugs();
  await nextTick();
  catalogStore.selectedSlugsWatcher.resume();

  $sendAnalyticEvent({
    event: eventName,
    page: getKmzaPageValue(route.name as AppRoute),
  });

  if (!appStoreLinks.some((item) => item.analyticEventName === eventName)) {
    return;
  }

  injectAdriverScript(AdriverAnalyticEvent.GoToAppStores);
};

const onCopyDebugInfo = () => {
  const info = useDebugInformation();

  let text = '';

  Object.entries(info).forEach(([key, value]) => {
    const res = `${key}: ${value}, \n`;
    text += res;
  });

  copyToClipboard(text);
};
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '../../assets/breakpoints' as breakpoints;

.footer {
  padding: 0 var(--g-spacing-48) var(--g-spacing-20) var(--g-spacing-48);
  margin-top: auto;
}

.footerBlock {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  padding: var(--g-spacing-24) 0;
  border-top: 1px solid var(--color-bg-tertiary);
}

.footerBlockLast {
  padding-bottom: 0;
  align-items: center;
}

.textAndStores {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.smartTvStoreBlock {
  display: flex;
  align-items: center;
  margin-bottom: var(--g-spacing-16);
}

.smartTvStoreText {
  margin-right: var(--g-spacing-12);
  color: var(--color-text-tertiary);

  @include fonts.WebLabel-3;
}

.storeIcon {
  color: var(--color-icon-secondary);
  transition: color 0.3s ease-in-out;

  &:hover {
    color: var(--color-icon-primary);
  }
}

.footerText {
  margin: 0 var(--g-spacing-24) 0 0;
  max-width: 580px;
  color: var(--color-text-tertiary);
  text-align: left;

  @include fonts.WebLabel-3;
}

.footerStores {
  display: flex;
  gap: var(--g-spacing-16);
  margin-top: var(--g-spacing-16);
}

.footerListItem:not(:last-child) {
  margin-bottom: var(--g-spacing-24);
}

.footerListItemLink {
  color: var(--color-text-tertiary);
  transition: color 0.15s linear;
  white-space: nowrap;

  &:hover {
    color: var(--color-text-primary);
  }
}

.footerSocials {
  display: flex;
  align-items: center;
}

.footerSocialsIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: var(--g-spacing-16);
  border-radius: var(--g-border-round-12);
  background-color: var(--color-bg-button);
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: var(--color-states-bg-secondary-hover);
  }
}

.appVersion {
  color: var(--color-text-tertiary);

  @include fonts.WebBody-3;
}

.appVersionWithoutSocials {
  grid-column-start: 2;
}

.footerCopyright {
  color: var(--color-text-tertiary);

  @include fonts.WebBody-3;
}

.recommendationDescription {
  font-weight: 300;
  color: var(--color-text-tertiary);
  text-align: center;
}

@include breakpoints.max-width-1024 {
  .footerBlock {
    grid-template-areas: 'a a a a' 'b b c c';
    grid-template-columns: 1fr 1fr;
  }

  .textAndStores {
    grid-area: a;
  }

  .footerText {
    margin: 0 0 var(--g-spacing-24) 0;
  }

  .footerStores {
    margin: 0;
  }

  .footerList {
    padding-top: var(--g-spacing-24);
  }

  .footerListItemLink {
    display: block;
    margin: 0 0 var(--g-spacing-10) 0;
  }

  .footerSocials {
    grid-area: a;
  }

  .footerCopyright {
    display: block;
    margin: var(--g-spacing-24) 0 var(--g-spacing-10) 0;
  }

  .footerListItemLinkLast {
    margin: 0;
  }

  .appVersion {
    margin-top: var(--g-spacing-24);
  }
}

@include breakpoints.max-width-800 {
  .footer {
    padding: var(--g-spacing-20);
  }
}

@include breakpoints.max-width-540 {
  .footerBlock {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0;
    border: none;
  }

  .footerList {
    width: 100%;
    padding-bottom: var(--g-spacing-24);
    border-top: 1px solid var(--color-bg-tertiary);
  }

  .textAndStores {
    padding-top: var(--g-spacing-24);
    padding-bottom: var(--g-spacing-24);
    border-top: 1px solid var(--color-bg-tertiary);
  }

  .footerStores {
    flex-direction: column;
  }

  .footerSocials {
    width: 100%;
    padding-top: var(--g-spacing-24);
    border-top: 1px solid var(--color-bg-tertiary);
  }
}
</style>
