import type { RouterConfig } from '@nuxt/schema';
import useLogger from '@package/logger/src/use-logger';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { isObject } from '@vueuse/core';

import { AppRoute } from '@/platform/router/routes';
import { useLayoutStore } from '@/stores/use-layout-store';

const SCROLLABLE_BODY_ELEMENT_ID = '#inner-body';

const routesWithSavingPosition = [
  AppRoute.Movies,
  AppRoute.MovieFilter,
  AppRoute.Serials,
  AppRoute.SerialFilter,
  AppRoute.Index,
];

const logger = useLogger('router.options.ts', 'web');
logger.level = 1;

// const routesWithoutSavingPosition = [
//   AppRoute.SerialSeasonCard,
//   AppRoute.TV,
//   AppRoute.TVChannel,
//   AppRoute.MovieCard,
//   AppRoute.SerialCard,
//   AppRoute.Search,
//   AppRoute.Compilations,
//   AppRoute.CompilationsItem,
//   AppRoute.MyCollectionKinom,
//   AppRoute.MyCollectionContent,
//   AppRoute.AccountDevices,
//   AppRoute.ConnectTv,
//   AppRoute.ConnectTvXiaomi,
//   AppRoute.ConnectTvHisense,
//   AppRoute.ConnectTvYandex,
//   AppRoute.ConnectTvSber,
//   AppRoute.ConnectTvAppleTv,
//   AppRoute.ConnectTvSamsung,
//   AppRoute.ConnectTvAndroidTv,
//   AppRoute.ConnectTvLg,
// ];

/**
 * @description
 *
 * Кастомный scrollBehaviour, так как у нас в приложение скролится теперь не body, а <div id="inner-body">
 */
export default <RouterConfig>{
  strict: false,
  scrollBehavior(to) {
    const app = useNuxtApp();
    const layoutStore = useLayoutStore();
    const { scrollPositionMap } = storeToRefs(layoutStore);
    const hashElement = document.getElementById(to.hash);
    const bodyEl = document.querySelector(SCROLLABLE_BODY_ELEMENT_ID);

    if (hashElement) {
      logger.info('ScrollToHash position');
      hashElement.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    /**
     * При САМОМ первом заходе, страница в SPA режиме еще пустая (без элементов). Отсюда и #inner-body недоступен.
     * Происходит это только при первой загрузке.
     */
    if (!bodyEl) {
      return logger.trace(new UnexpectedComponentStateError('bodyEl'));
    }

    return new Promise((resolve) => {
      app.hooks.hookOnce('page:finish', async () => {
        await nextTick();

        const isSavedPositionRoute =
          isObject(scrollPositionMap?.value) &&
          Reflect.has(scrollPositionMap.value, to.name as AppRoute) &&
          routesWithSavingPosition.includes(to.name as AppRoute);

        if (isSavedPositionRoute) {
          logger.info('Restore position');

          bodyEl.scrollTo(scrollPositionMap.value[to.name as AppRoute]);
          return resolve();
        }

        logger.info('Reset position');
        bodyEl.scrollTo({ top: 0 });
        resolve();
      });
    });
  },
};
