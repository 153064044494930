<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2 4.75C2.69036 4.75 3.25 5.30964 3.25 6V6.001C3.25 6.69136 2.69036 7.251 2 7.251C1.30964 7.251 0.75 6.69136 0.75 6.001V6C0.75 5.30964 1.30964 4.75 2 4.75ZM6 4.75C6.69036 4.75 7.25 5.30964 7.25 6V6.001C7.25 6.69136 6.69036 7.251 6 7.251C5.30964 7.251 4.75 6.69136 4.75 6.001V6C4.75 5.30964 5.30964 4.75 6 4.75ZM11.25 6C11.25 5.30964 10.6904 4.75 10 4.75C9.30964 4.75 8.75 5.30964 8.75 6V6.001C8.75 6.69136 9.30964 7.251 10 7.251C10.6904 7.251 11.25 6.69136 11.25 6.001V6Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2 6.25C2.9665 6.25 3.75 7.0335 3.75 8V8.001C3.75 8.9675 2.9665 9.751 2 9.751C1.0335 9.751 0.25 8.9675 0.25 8.001V8C0.25 7.0335 1.0335 6.25 2 6.25ZM8 6.25C8.9665 6.25 9.75 7.0335 9.75 8V8.001C9.75 8.9675 8.9665 9.751 8 9.751C7.0335 9.751 6.25 8.9675 6.25 8.001V8C6.25 7.0335 7.0335 6.25 8 6.25ZM15.75 8C15.75 7.0335 14.9665 6.25 14 6.25C13.0335 6.25 12.25 7.0335 12.25 8V8.001C12.25 8.9675 13.0335 9.751 14 9.751C14.9665 9.751 15.75 8.9675 15.75 8.001V8Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4 10C5.10457 10 6 10.8954 6 12V12.001C6 13.1056 5.10457 14.001 4 14.001C2.89543 14.001 2 13.1056 2 12.001V12C2 10.8954 2.89543 10 4 10ZM12 10C13.1046 10 14 10.8954 14 12V12.001C14 13.1056 13.1046 14.001 12 14.001C10.8954 14.001 10 13.1056 10 12.001V12C10 10.8954 10.8954 10 12 10ZM22 12C22 10.8954 21.1046 10 20 10C18.8954 10 18 10.8954 18 12V12.001C18 13.1056 18.8954 14.001 20 14.001C21.1046 14.001 22 13.1056 22 12.001V12Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.66675 13.5C6.04746 13.5 7.16675 14.6193 7.16675 16V16.001C7.16675 17.3817 6.04746 18.501 4.66675 18.501C3.28604 18.501 2.16675 17.3817 2.16675 16.001V16C2.16675 14.6193 3.28604 13.5 4.66675 13.5ZM16 13.5C17.3807 13.5 18.5 14.6193 18.5 16V16.001C18.5 17.3817 17.3807 18.501 16 18.501C14.6193 18.501 13.5 17.3817 13.5 16.001V16C13.5 14.6193 14.6193 13.5 16 13.5ZM29.8333 16C29.8333 14.6193 28.714 13.5 27.3333 13.5C25.9525 13.5 24.8333 14.6193 24.8333 16V16.001C24.8333 17.3817 25.9525 18.501 27.3333 18.501C28.714 18.501 29.8333 17.3817 29.8333 16.001V16Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import AppIcon from './AppIcon.vue';
import { type IconSize, getBoundsByIconSize, getSVGViewboxByIconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
