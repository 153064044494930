<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small'"></template>
      <template v-if="size === 'small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.99845 8.00301C9.65499 8.00301 10.9979 6.66012 10.9979 5.00358C10.9979 3.34704 9.65499 2.00415 7.99845 2.00415C6.34192 2.00415 4.99903 3.34704 4.99903 5.00358C4.99903 6.66012 6.34192 8.00301 7.99845 8.00301ZM12.4835 9.04249L12.7577 9.33899C13.2433 10.0441 13.4974 10.8136 13.4974 11.5025C13.4974 13.4352 10.7594 15.0019 7.99846 15.0019C5.23757 15.0019 2.49951 13.4352 2.49951 11.5025C2.49951 10.792 2.76983 9.99569 3.28561 9.27281L3.45272 9.08274C3.85294 8.62753 4.56146 8.63932 5.05197 8.99538C5.87074 9.58974 6.87803 9.94024 7.96717 9.94024C9.06658 9.94024 10.0826 9.58311 10.9055 8.97852C11.3858 8.6256 12.0789 8.60485 12.4835 9.04249Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.9977 12.0046C14.4825 12.0046 16.4968 9.9903 16.4968 7.50549C16.4968 5.02068 14.4825 3.00635 11.9977 3.00635C9.51289 3.00635 7.49855 5.02068 7.49855 7.50549C7.49855 9.9903 9.51289 12.0046 11.9977 12.0046ZM18.7253 13.5636L19.1366 14.0083C19.8649 15.0659 20.2461 16.2202 20.2461 17.2536C20.2461 20.1526 16.139 22.5026 11.9977 22.5026C7.85635 22.5026 3.74927 20.1526 3.74927 17.2536C3.74927 16.1878 4.15474 14.9934 4.92842 13.909L5.17909 13.6239C5.77941 12.9411 6.84219 12.9588 7.57795 13.4929C8.80612 14.3844 10.317 14.9102 11.9508 14.9102C13.5999 14.9102 15.1239 14.3745 16.3582 13.4676C17.0787 12.9382 18.1183 12.9071 18.7253 13.5636Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large'"></template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import AppIcon from './AppIcon.vue';
import { type IconSize, getBoundsByIconSize, getSVGViewboxByIconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
