import type { ApiSession, ApiUser, Credentials, Session, User } from '@package/sdk/src/api';
import { SessionMapper, UserMapper } from '@package/sdk/src/api';

import { ENDPOINTS } from '@/platform/http';
import type { HttpRequestOptions } from '@/platform/http/http-request';
import { useSessionStore } from '@/stores/use-session-store';

import { OAuthProviderType } from '../o-auth/o-auth';

export interface IUpdateUser {
  oldPassword?: string;
  password?: string;
  name?: string;
  otp?: string;
  phoneNumber?: string;
  email?: string;
}

export interface IUserCreateSession {
  ga_cid?: string;
  ym_uid?: string;
  phone_number?: string;
  email?: string;
  password?: string;
  user_parameters?: any;
  otp?: string;
  device: {
    type: string;
    os?: string;
    app_version: string;
    name?: string;
    uid: string;
  };
  invitation_token?: string;
  credentials?: {
    type: OAuthProviderType;
    oauth_token?: string;
    silent_token?: string;
    uuid?: string;
  };
}

export interface ISendPhoneCode {
  code: string;
}

export function useUsersApi() {
  const app = useNuxtApp();
  const sessionStore = useSessionStore();

  const patchAuthInfo = (info: IUserCreateSession) => {
    const userInfo: IUserCreateSession = {
      ...info,
    };

    app.$yandexMetricaEventSender.setYandexMetricaID((clientId) => {
      sessionStore._ymUserId = clientId;
    });

    if (sessionStore.gaClientId) {
      userInfo.ga_cid = sessionStore.gaClientId;
    }

    if (sessionStore.ymUserId) {
      userInfo.ym_uid = sessionStore.ymUserId;
    }

    return userInfo;
  };

  const signUp = (userInfo: IUserCreateSession): Promise<Session> => {
    const info = patchAuthInfo(userInfo);

    return app.$http
      .post<ApiSession>(ENDPOINTS.SESSIONS_SIGN_UP, info, {
        signRequest: true,
      })
      .then(SessionMapper.map);
  };

  const signIn = (userInfo: IUserCreateSession): Promise<Session> => {
    const info = patchAuthInfo(userInfo);

    return app.$http
      .post<ApiSession>(ENDPOINTS.SESSIONS_BASE, info, {
        signRequest: true,
      })
      .then(SessionMapper.map);
  };

  const signOut = (userId: string): Promise<void> =>
    app.$http.delete<void>(ENDPOINTS.SESSIONS_ID, { params: { id: userId } });

  const updateUser = (
    { oldPassword, password, name, otp, phoneNumber, email }: IUpdateUser,
    options: HttpRequestOptions<'SESSIONS_USERS'> = {},
  ): Promise<User> => {
    const userInfo: Record<string, unknown> = { password };
    if (oldPassword) {
      userInfo.old_password = oldPassword;
    }

    if (name) {
      userInfo.name = name;
    }

    if (otp) {
      userInfo.otp = otp;
    }

    if (phoneNumber) {
      userInfo.phone_number = phoneNumber;
    }

    if (email) {
      userInfo.email = email;
    }

    return app.$http.put<ApiUser>(ENDPOINTS.SESSIONS_USERS, { ...userInfo }, options).then(UserMapper.map);
  };

  const fetchCurrentUser = (): Promise<User> => {
    return app.$http.get<ApiUser>(ENDPOINTS.SESSIONS_USER_CURRENT).then(UserMapper.map);
  };

  const sendResetPasswordEmail = (email: string) => {
    return app.$http.post(
      ENDPOINTS.SESSIONS_PASSWORDS_SEND_TOKEN,
      { email },
      {
        signRequest: true,
      },
    );
  };

  const sendOtp = (email?: string, phoneNumber?: string) => {
    const body = { email, phone_number: phoneNumber?.replace(' ', '') };
    return app.$http.post<ISendPhoneCode>(ENDPOINTS.SESSIONS_PASSWORD_SEND_OTP, body, {
      signRequest: true,
    });
  };

  const resetPassword = (password: string, token: string): Promise<void> => {
    return app.$http.post<undefined>(
      ENDPOINTS.SESSIONS_PASSWORDS,
      { password, token },
      {
        signRequest: true,
      },
    );
  };

  const deleteAccount = () => {
    return app.$http.post<undefined>(
      ENDPOINTS.SESSIONS_USERS_DELETE_ACCOUNT,
      {},
      {
        signRequest: true,
      },
    );
  };

  const sendConfirmationCode = (phoneNumber: string): Promise<string> => {
    return app.$http
      .post<{
        code: string;
      }>(ENDPOINTS.SESSIONS_USERS_SEND_CONFIRMATION_CODE, { phone_number: phoneNumber })
      .then(({ code }: { code: string }) => code);
  };

  const confirmCredential = (data: Credentials): Promise<User> => {
    return app.$http
      .post<ApiUser>(
        ENDPOINTS.SESSIONS_USERS_CONFIRM_CREDENTIAL,
        { ...data },
        {
          signRequest: true,
        },
      )
      .then(UserMapper.map);
  };

  const checkResetPasswordToken = (token: string): Promise<void> => {
    return app.$http.post<void>(
      ENDPOINTS.SESSIONS_CHECK_RESET_PASSWORD_TOKEN,
      {},
      {
        query: { token },
      },
    );
  };

  const confirmTvToken = (code: string) => {
    return app.$http.post<void>(ENDPOINTS.SMART_TV_SESSIONS_CONFIRM, { code });
  };

  return {
    deleteAccount,
    signUp,
    signIn,
    signOut,
    updateUser,
    sendResetPasswordEmail,
    resetPassword,
    fetchCurrentUser,
    confirmTvToken,
    sendConfirmationCode,
    confirmCredential,
    sendOtp,
    checkResetPasswordToken,
  };
}
