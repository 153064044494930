<template>
  <div ref="wrapperEl"></div>
</template>

<script lang="ts" setup>
import type {
  MediaPlayRequestedEvent,
  VideoPlayerExternalEvent,
} from '@package/media-player/src/player/modules/event/external-event';
import { VijuPlayer } from '@package/media-player/src/player/modules/instance/player';
import { AnalyticPageName, ItemPageFrom, useContentPageAnalytics } from '@package/sdk/src/analytics';
import type { ContentMoment, Moment } from '@package/sdk/src/api';
import { isDefined, UnexpectedComponentStateError } from '@package/sdk/src/core';
import { isClient } from '@vueuse/core';
import type { RouteLocationNamedRaw } from 'vue-router';

import useAuthActions from '@/code/auth/use-auth-actions';
import { AdriverAnalyticEvent, useKmzaDriverEvents } from '@/code/kmza/use-driver-kmza';
import useSafeScrollableBodyElement from '@/code/layout/use-safe-scrollable-body-element';
import type { CommonMoment } from '@/code/moments/moments';
import useAsyncPlayer from '@/code/player/use-async-player';
import usePlayerEventHooks from '@/code/player/use-player-event-hooks';
import useMobile from '@/platform/layout/use-mobile';
import { RouteQuery } from '@/platform/router/query';
import { AppRoute } from '@/platform/router/routes';
import { useCatalogStore } from '@/stores/use-catalog-store';
import { useOffersStore } from '@/stores/use-offers-store';
import { useSessionStore } from '@/stores/use-session-store';

const props = withDefaults(
  defineProps<{
    showFullscreenButton?: boolean;
    hasRedirectKinomOffset?: boolean;
    playerHeightCss?: string;
    currentSlideIndex?: number;
    slideIndex?: number;
    autoStartLoad?: boolean;
    kinom: CommonMoment;
    kinoms?: Moment[] | ContentMoment[];

    lazyInteractionLoading?: boolean;
    playbackMyMouse?: boolean;
    useIntersect?: boolean;

    isFirstTimeoutLoading?: boolean;

    src?: string;
    loop?: boolean;
    muted?: boolean;
    autoplay?: boolean;
    poster?: string;
  }>(),
  {
    showFullscreenButton: true,
    hasRedirectKinomOffset: true,
    playerHeightCss: 'initial',
    lazyInteractionLoading: false,
    isFirstTimeoutLoading: false,
    playbackMyMouse: false,
    userIntersect: true,
    autoStartLoad: true,
    src: '',
    loop: true,
    muted: true,
    autoplay: true,
  },
);

const { loadPlayer } = useAsyncPlayer();
const { WebVijuPlayer } = await loadPlayer();
const { bodyEl } = useSafeScrollableBodyElement();

const player = new WebVijuPlayer({
  projector: 'kinom',
  autoplay: props.autoplay,
  autoStartLoad: props.autoStartLoad,
  muted: props.muted,
  loop: props.loop,
  src: !props.isFirstTimeoutLoading ? props.src : undefined,
});

markRaw(player);

const router = useRouter();
const wrapperEl = ref<HTMLElement>();
const { $contentCacheManager } = useNuxtApp();
const { isAuth } = storeToRefs(useSessionStore());
const { isActiveSubscription } = storeToRefs(useOffersStore());
const { $analyticSender } = useNuxtApp();

const contentPageAnalytics = useContentPageAnalytics($analyticSender);
const catalogStore = useCatalogStore();
const isMobile = useMobile();

usePlayerEventHooks({ player });

const { injectAdriverScript } = useKmzaDriverEvents();
const { doRedirectOfferPage } = useAuthActions();

const emit = defineEmits<{
  (event: 'ready', player: VijuPlayer): void;
  (event: 'created', player: VijuPlayer): void;
  (event: 'ended', player: VijuPlayer): void;
}>();

emit('created', player);

const onEnded = () => emit('ended', player);

const onMediaPlayRequested = (event: VideoPlayerExternalEvent<MediaPlayRequestedEvent>) => {
  const { type, serial, slug = '', startOffset } = event.data;

  if (type === 'kinom') {
    return;
  }

  if (props.kinom) {
    catalogStore.setCurrentLinkedContentKinom(props.kinom as Moment);
    $contentCacheManager.addToCache(props.kinom);
  }

  if (!isAuth.value || !isActiveSubscription.value) {
    return doRedirectOfferPage();
  }

  const routeName = (() => {
    if (serial) {
      if (serial.season && serial.episode) {
        return AppRoute.SerialEpisodeCard;
      }
    }

    if (type === 'movie') {
      return AppRoute.MovieCard;
    }

    return AppRoute.SerialCard;
  })();

  const params = { id: slug };

  if (serial?.season) {
    Reflect.set(params, 'seasonId', serial.season);
  }

  if (serial?.episode) {
    Reflect.set(params, 'episodeId', serial.season);
  }

  const route: RouteLocationNamedRaw = {
    name: routeName,
    params,
  };

  if (props.hasRedirectKinomOffset && startOffset) {
    route.query = {
      [RouteQuery.VideoStartOffset]: startOffset,
    };
  }
  if (props.kinom) {
    const media = props.kinom as Moment;

    contentPageAnalytics.onGotoItemPage({
      title: media.contentTitle,
      contentType: media.contentType,
      page: AnalyticPageName.Main,
      itemId: media.contentId,
      kinomId: media.id,
      from: ItemPageFrom.Promo,
    });

    injectAdriverScript(AdriverAnalyticEvent.PromoWatchButton);
  }

  if (player.isFullscreen) {
    player.closeFullscreen();
    nextTick(() => {
      bodyEl.value.scrollTo({ top: 0, behavior: 'smooth' });
      player.pause();
    });
  }

  router.push(route);
};

const doMountPlayer = () => {
  if (!wrapperEl.value) {
    throw new UnexpectedComponentStateError('wrapperEl');
  }

  if (player.mounted) {
    return;
  }

  player.mount(wrapperEl.value as HTMLElement);
  emit('ready', player);
};

player.on('ended', onEnded);
player.on('media-play-requested', onMediaPlayRequested);

player.setConfigProperties({
  'video.controls': { skipPlayback: false, fullscreen: props.showFullscreenButton },
  'experimental.feature.lazyInteractionLoading': props.lazyInteractionLoading,
  'experimental.feature.viewportIntersectionPlayback': props.useIntersect,
  'content.media': props.kinom as Moment,
  'experimental.feature.playbackMyMouse': props.playbackMyMouse,
});

const isSliderLoading = isDefined(props.slideIndex) && isDefined(props.currentSlideIndex);

if (isSliderLoading) {
  watch(
    () => props.currentSlideIndex,
    () => {
      if (props.currentSlideIndex !== props.slideIndex) {
        return;
      }

      doMountPlayer();
    },
    { immediate: false },
  );
}

const loadSource = (options: { src: string; moment: Moment }) => {
  const { src, moment } = options;

  player.load({ src, id: moment.id });
  player.setConfigProperty('video.poster', moment.preview);
  player.setConfigProperty('content.media', moment);
};

let firstLoadTimeoutId: number;

const loadWithTimeout = () => {
  const timeoutLoadingOffsetMilliseconds = isMobile ? 5000 : 3000;

  firstLoadTimeoutId = window.setTimeout(() => {
    if (firstLoadTimeoutId) {
      window.clearTimeout(firstLoadTimeoutId);
    }

    player.load({ id: props.kinom.id, src: props.src });

    const manifestParsedEvent = player.on('manifest-parsed', () => {
      player.startLoad();
      manifestParsedEvent.dispose();
    });
  }, timeoutLoadingOffsetMilliseconds);
};

let timeoutLoadTimeTimeoutId: number;

if (!isSliderLoading) {
  watch(
    () => props.src,
    (src) => {
      if (!src || !isClient) {
        return;
      }

      const moment = props.kinom as Moment;

      if (player.mounted) {
        loadSource({ moment, src });
      }
    },
    { immediate: true },
  );
}

onMounted(() => {
  if (!wrapperEl.value) {
    throw new UnexpectedComponentStateError('wrapperEl');
  }

  if (props.isFirstTimeoutLoading) {
    loadWithTimeout();
  }

  wrapperEl.value.style.setProperty('height', props.playerHeightCss);

  if (!isSliderLoading || props.currentSlideIndex === props.slideIndex) {
    return doMountPlayer();
  }
});

onBeforeUnmount(() => {
  if (player.mounted) {
    player.dispose();
  }

  if (firstLoadTimeoutId) {
    window.clearTimeout(firstLoadTimeoutId);
  }

  if (timeoutLoadTimeTimeoutId) {
    window.clearTimeout(timeoutLoadTimeTimeoutId);
  }
});
</script>
