import type { Compilation, Media, Serial } from '@package/sdk/src/api';
import { MediaContentType } from '@package/sdk/src/api';
import { isNumber } from '@package/sdk/src/core';
import { isClient } from '@vueuse/core';

import type { CreateCatalogContentTitleOptions } from '@/code/content/use-fetch-catalog-content';
import useFetchCatalogContent from '@/code/content/use-fetch-catalog-content';
import { useRouteUtils } from '@/platform/router/use-route-utils';
import type { BreadcrumbsItem } from '@/stores/use-layout-store';

import { usePageMeta } from '../seo/use-page-meta';
import type {
  LDMarkupBreadcrumbObject,
  LDMarkupCatalogContentObject,
  LDMarkupCompilationObject,
} from './linked-data-interface';

interface IGenerateBreadcrumbLinkedDataJSONOptions {
  urlMapperFunc?: (str: string) => string;
}

interface IGenerateContentLinkedDataJson extends CreateCatalogContentTitleOptions {
  url: string;
}

function generateBreadcrumbLinkedDataJSON(
  breadcrumbs: readonly BreadcrumbsItem[],
  options?: IGenerateBreadcrumbLinkedDataJSONOptions,
): string {
  const defaultUrlMapperFunc = (str: string) => str;
  const urlMapperFunc = options?.urlMapperFunc || defaultUrlMapperFunc;

  const result: LDMarkupBreadcrumbObject = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map((breadcrumb, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: urlMapperFunc(breadcrumb.url),
      name: breadcrumb.label,
    })),
  };

  return JSON.stringify(result);
}

const getContentDuration = (value: number) => {
  const h = Math.floor(value / 3600);
  const m = Math.floor((value % 3600) / 60);
  const s = Math.floor((value % 3600) % 60);

  return `T${h}H${m}M${s}S`;
};

export default function useLDJSONMarkup() {
  const { createExternalLink, getContentExternalLink } = useRouteUtils();
  const { createCatalogContentTitle } = useFetchCatalogContent();
  const { useHeadRaw } = usePageMeta();
  const app = useNuxtApp();

  const generateCompilationLinkedDataJSON = (compilation: Compilation) => {
    const result: LDMarkupCompilationObject = {
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      itemListElement: compilation.contents.map((content, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@type': 'Movie',
          name: content.title,
          dateCreated: content.year,
          image: content.poster,
          url: getContentExternalLink(content),
        },
      })),
    };

    return JSON.stringify(result);
  };

  const generateContentLinkedDataJSON = (content: Media, options: IGenerateContentLinkedDataJson) => {
    const director = content.directors[0];
    const writers = content.writers || [];

    const title = createCatalogContentTitle(content, options);

    const isMovieContent = content.contentType === MediaContentType.MOVIE;
    const isSerialContent = !isMovieContent;

    const ageLimit = content.ageLimit || 0;

    const result: LDMarkupCatalogContentObject = {
      '@context': 'https://schema.org',
      '@type': isMovieContent ? 'Movie' : 'TVSeries',
      url: options.url,
      isFamilyFriendly: ageLimit >= 18 ? 'False' : 'True',
      name: title,
      description: content.description,
      director: director
        ? {
            '@type': 'Person',
            name: director.name,
          }
        : undefined,
      genre: content.genres.length > 0 ? content.genres.map((genre) => genre.title) : undefined,
      actor:
        content.actors.length > 0
          ? content.actors.map((actor) => ({ '@type': 'Person', name: actor.name }))
          : undefined,
      author: writers.length > 0 ? writers.map((writer) => ({ '@type': 'Person', name: writer.name })) : undefined,
    };

    if (isMovieContent) {
      if (isNumber(content.duration)) {
        result.duration = getContentDuration(content.duration);
      }

      if (content.releaseDate) {
        result.dateCreated = content.releaseDate;
        result.datePublished = content.releaseDate;
      }
    }

    if (isSerialContent) {
      const serial = content as Serial;

      result.numberOfSeasons = serial.seasons.length;

      if (options.isSeasonPage) {
        const currentSeason = serial.seasons.find((season) => season.number === options.season);

        if (currentSeason) {
          const currentSeasonEpisodesLength = currentSeason.episodes.length;

          result.containsSeason = {
            '@type': 'TVSeason',
            numberOfEpisodes: currentSeasonEpisodesLength,
            name: currentSeason.title,
            seasonNumber: currentSeason.number,
            startDate: currentSeason.releaseDate,
          };

          if (options.isEpisodePage) {
            const episode = currentSeason.episodes.find((episode) => episode.number === options.episode);

            if (episode) {
              result.episode = {
                '@type': 'TVEpisode',
                name: episode.title,
                episodeNumber: episode.number,
                duration: episode.duration,
              };
            }
          }
        }
      }
    }

    return JSON.stringify(result);
  };

  const removeServerRenderedLDJSONMarkup = (type: 'breadcrumbs' | 'content' | 'compilation') => {
    if (!isClient) {
      return;
    }

    const { head } = document;
    const ldScript = head.querySelector(`script[data-ld-type="${type}"]`);

    ldScript?.remove();
  };

  const getContentTimeUnits = (value: number) => {
    const h = Math.floor(value / 3600);
    const m = Math.floor((value % 3600) / 60);
    const s = Math.floor((value % 3600) % 60);

    return [h, m, s];
  };

  const setContentMarkup = (content: Media, options: IGenerateContentLinkedDataJson) => {
    removeServerRenderedLDJSONMarkup('content');

    return callWithNuxt(app, () => {
      useHeadRaw({
        script: [
          {
            type: 'application/ld+json',
            innerHTML: generateContentLinkedDataJSON(content, options),
            'data-ld-type': 'content',
          },
        ],
      });
    });
  };

  const setBreadcrumbMarkup = (breadcrumbs: readonly BreadcrumbsItem[]) => {
    // удаляем старую микроразметку
    removeServerRenderedLDJSONMarkup('breadcrumbs');

    return callWithNuxt(app, () => {
      useHeadRaw({
        script: [
          {
            type: 'application/ld+json',
            innerHTML: generateBreadcrumbLinkedDataJSON(breadcrumbs, { urlMapperFunc: createExternalLink }),
            'data-ld-type': 'breadcrumbs',
          },
        ],
      });
    });
  };

  const setCompilationMarkup = (compilation: Compilation) => {
    removeServerRenderedLDJSONMarkup('compilation');

    return callWithNuxt(app, () => {
      useHeadRaw({
        script: [
          {
            type: 'application/ld+json',
            innerHTML: generateCompilationLinkedDataJSON(compilation),
            'data-ld-type': 'compilation',
          },
        ],
      });
    });
  };

  onBeforeUnmount(() => {
    removeServerRenderedLDJSONMarkup('breadcrumbs');
    removeServerRenderedLDJSONMarkup('content');
    removeServerRenderedLDJSONMarkup('compilation');
  });

  return {
    getContentTimeUnits,
    setContentMarkup,
    setCompilationMarkup,
    setBreadcrumbMarkup,
  };
}
