<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.0303 2.46967C11.3232 2.76256 11.3232 3.23744 11.0303 3.53033L5.03033 9.53033C4.73744 9.82322 4.26256 9.82322 3.96967 9.53033L0.96967 6.53033C0.676777 6.23744 0.676777 5.76256 0.96967 5.46967C1.26256 5.17678 1.73744 5.17678 2.03033 5.46967L4.5 7.93934L9.96967 2.46967C10.2626 2.17678 10.7374 2.17678 11.0303 2.46967Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.4571 2.79289C15.8476 3.18342 15.8476 3.81658 15.4571 4.20711L6.45711 13.2071C6.06658 13.5976 5.43342 13.5976 5.04289 13.2071L0.542893 8.70711C0.152369 8.31658 0.152369 7.68342 0.542893 7.29289C0.933417 6.90237 1.56658 6.90237 1.95711 7.29289L5.75 11.0858L14.0429 2.79289C14.4334 2.40237 15.0666 2.40237 15.4571 2.79289Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.7955 5.2045C22.2348 5.64384 22.2348 6.35616 21.7955 6.7955L9.7955 18.7955C9.35616 19.2348 8.64384 19.2348 8.2045 18.7955L2.2045 12.7955C1.76517 12.3562 1.76517 11.6438 2.2045 11.2045C2.64384 10.7652 3.35616 10.7652 3.7955 11.2045L9 16.409L20.2045 5.2045C20.6438 4.76517 21.3562 4.76517 21.7955 5.2045Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M29.7223 6.52773C30.2592 7.0647 30.2592 7.9353 29.7223 8.47227L12.7223 25.4723C12.1853 26.0092 11.3147 26.0092 10.7777 25.4723L2.27773 16.9723C1.74076 16.4353 1.74076 15.5647 2.27773 15.0277C2.8147 14.4908 3.6853 14.4908 4.22227 15.0277L11.75 22.5555L27.7777 6.52773C28.3147 5.99076 29.1853 5.99076 29.7223 6.52773Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import AppIcon from './AppIcon.vue';
import { type IconSize, getBoundsByIconSize, getSVGViewboxByIconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
