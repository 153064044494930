<template>
  <div v-if="selectedItem" :class="$style.playerPlaylist">
    <div :class="$style.playerContainer">
      <viju-kinom-video-player
        :auto-start-load="false"
        :has-redirect-kinom-offset="false"
        :is-first-timeout-loading="false"
        :kinom="selectedItem"
        :kinoms="items"
        :loop="false"
        :muted="true"
        :use-intersect="true"
        :lazy-interaction-loading="true"
        :poster="selectedItem.preview"
        :src="selectedItem.hls"
        :class="$style.player"
        @created="onPlayerCreated"
        @ready="onPlayerReady"
        @ended="onEnded"
      />
    </div>

    <div :class="$style.sidebarContainer">
      <player-sidebar
        ref="playlistSidebarEl"
        :items="items"
        :use-observer="useKeyboardNavigation"
        :link="link"
        :selected-item="selectedItem"
        :class="$style.playlistSidebar"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Playlists, 'itemsListBlock')"
        @click-content-link="$emit('click-content-link')"
        @select-item="(item: ContentMoment | Moment) => $emit('select-item', item)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {
  FullscreenEvent,
  MediaPlayRequestedEvent,
  VideoPlayerExternalEvent,
} from '@package/media-player/src/player/modules/event/external-event';
import type { VijuPlayer } from '@package/media-player/src/player/modules/instance/player';
import type { ContentMoment, Moment } from '@package/sdk/src/api';
import { useWindowSize } from '@vueuse/core';

import VijuKinomVideoPlayer from '@/components/video-player/VijuKinomVideoPlayer.vue';

import useWindowWidthPlayerRatio from '../../code/player/use-window-width-player-ratio';
import useMobile from '../../platform/layout/use-mobile';
import useScreenOrientation from '../../platform/layout/use-screen-orientation';
import PlayerSidebar from './PlayerSidebar.vue';

const props = defineProps({
  useKeyboardNavigation: { type: Boolean, required: false, default: false },
  items: { type: Array as PropType<ContentMoment[] | Moment[]>, default: () => [] },
  selectedItem: { type: Object as PropType<ContentMoment | Moment>, default: null },
  title: { type: String, default: '' },
  playlistTitle: { type: String, default: '' },
  link: { type: [String, Object], required: true },
});

const emit = defineEmits<{
  (e: 'change-selected', id: string): void;
  (e: 'ready', player: VijuPlayer): void;
  (e: 'click-content-link'): void;
  (e: 'ended', isLastItem: boolean): void;
  (e: 'select-item', item: ContentMoment | Moment): void;
}>();

const player = ref<VijuPlayer>();
const playlistSidebarEl = ref<ComponentPublicInstance<{ sidebarEl: HTMLElement }>>();

const { orientation } = useScreenOrientation();
const { playerHeight } = useWindowWidthPlayerRatio();
const { width: windowWidth, height: windowHeight } = useWindowSize();

const isMobile = useMobile();

const aspectRatio = computed(() => {
  if (isMobile && orientation.value === 'horizontal') {
    const ratio = windowWidth.value / windowHeight.value;

    if (ratio >= 2) {
      return '18 / 9';
    }
  }

  return '16 / 9';
});

const isLastItem = computed(() => {
  const selectedItemIndex = props.items.findIndex((moment) => moment.id === props.selectedItem.id);

  return selectedItemIndex === props.items.length - 1;
});

const onEnded = () => {
  if (!playlistSidebarEl.value) {
    return;
  }

  if (isLastItem.value) {
    (playlistSidebarEl.value.sidebarEl as HTMLElement).scrollTo({ top: 0 });
  }

  emit('ended', isLastItem.value);
};

const onFullscreen = (event: VideoPlayerExternalEvent<FullscreenEvent>) => {
  player.value?.setConfigProperty('video.kinom.isDescriptionShown', event.data.fullscreen);
};

const onMediaPlayRequested = (event: VideoPlayerExternalEvent<MediaPlayRequestedEvent>) => {
  const { type, id } = event.data;

  if (type !== 'kinom') {
    return;
  }

  emit('change-selected', id);
};

const onPlayerReady = (plr: VijuPlayer) => emit('ready', plr);

const onPlayerCreated = (plr: VijuPlayer) => {
  player.value = plr;

  const playlist = {
    title: props.playlistTitle,
    contentMomentsList: props.items,
  };

  plr.setConfigProperties({
    'content.playlists': [playlist],
  });

  plr.on('fullscreen', onFullscreen);
  plr.on('media-play-requested', onMediaPlayRequested);
};
</script>

<style lang="scss" module>
@use '../../assets/breakpoints' as breakpoints;

.playerPlaylist {
  display: flex;
  border-radius: var(--g-border-round-32);
  background-color: var(--color-bg-secondary);
  overflow: hidden;

  --sidebar-width: 500px;
  --sidebar-width-wide-display: 680px;
  --sidebar-width-small-display: 420px;
}

.playerContainer {
  width: calc(100% - var(--sidebar-width));
  border-radius: 30px;
  background-color: var(--color-notheme-bg-banner-button-pressed);
  overflow: hidden;
}

.player {
  width: 100%;
  height: v-bind(playerHeight);
  aspect-ratio: v-bind(aspectRatio);
}

.sidebarContainer {
  position: relative;
  width: var(--sidebar-width);
}

.playlistSidebar {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

@include breakpoints.min-width-1921 {
  .playerContainer {
    width: calc(100% - var(--sidebar-width-wide-display));
  }

  .sidebarContainer {
    width: var(--sidebar-width-wide-display);
  }
}

@include breakpoints.max-width-1280 {
  .playerContainer {
    width: calc(100% - var(--sidebar-width-small-display));
  }

  .sidebarContainer {
    width: var(--sidebar-width-small-display);
  }
}

@include breakpoints.max-width-1024 {
  .playerPlaylist {
    flex-direction: column;
  }

  .playerContainer {
    width: 100%;
  }

  .sidebarContainer {
    width: 100%;
    height: 464px;
  }
}

@include breakpoints.max-width-480 {
  .sidebarContainer {
    height: 277px;
  }
}
</style>
