import { AnalyticEventNameNew } from '@package/sdk/src/analytics';
import { isString } from '@SDK/core';
import type { RouteLocationRaw } from 'vue-router';

import { AdriverAnalyticEvent, useKmzaDriverEvents } from '@/code/kmza/use-driver-kmza';
import { RouteQuery } from '@/platform/router/query';
import { AppRoute } from '@/platform/router/routes';
import { useCatalogStore } from '@/stores/use-catalog-store';
import { useSessionStore } from '@/stores/use-session-store';

import useAuthActions from '../../code/auth/use-auth-actions';
import getKmzaPageValue from '../../code/kmza/get-kmza-page-value';
import useMobile from '../../platform/layout/use-mobile';
import useLocale from '../../platform/localization/use-locale';
import useHostVariation from '../../platform/variation/use-host-variation';

export interface AppHeaderItem {
  name: AppRoute;
  target: string | RouteLocationRaw;
  description: string;
  ariaLabel: string;
}

export type ContentRouteName = AppRoute.Movies | AppRoute.Serials | AppRoute.MovieFilter | AppRoute.SerialFilter;

const hiddenHeaderRoutes = [
  AppRoute.AuthEnter,
  AppRoute.AuthEnterPassword,
  AppRoute.AuthRestorePassword,
  AppRoute.AuthPasswordRestoreEmail,
  AppRoute.AuthPasswordRestorePhone,
  AppRoute.AuthPasswordLinkExpired,
  AppRoute.TVCodePage,
  AppRoute.Offers,
  AppRoute.OffersPromo,
  AppRoute.GoTVCodePage,
];

export default function useAppHeaderVariables() {
  const isMobile = useMobile();
  const { isAuth } = storeToRefs(useSessionStore());
  const catalogStore = useCatalogStore();
  const route = useRoute();
  const router = useRouter();
  const { $sendAnalyticEvent } = useNuxtApp();
  const { injectAdriverScript } = useKmzaDriverEvents();
  const { getBackRouteRedirectUrl } = useAuthActions();
  const { translate } = useLocale();
  const { languageList } = useHostVariation();

  const isMobileMyChannelPage = computed(() => isMobile && router.currentRoute.value.name === AppRoute.MyChannel);

  const navItems: AppHeaderItem[] = [
    {
      name: AppRoute.Index,
      target: '/',
      description: translate('header.tabs.mainPage'),
      ariaLabel: translate('header.tabs.mainPage.ariaLabel'),
    },
    {
      name: AppRoute.MyChannel,
      target: { name: AppRoute.MyChannel },
      description: translate('header.tabs.myChannelPage'),
      ariaLabel: translate('header.tabs.myChannelPage.ariaLabel'),
    },
    {
      name: AppRoute.Movies,
      target: { name: AppRoute.Movies },
      description: translate('header.tabs.moviePage'),
      ariaLabel: translate('header.tabs.moviePage.ariaLabel'),
    },
    {
      name: AppRoute.Serials,
      target: { name: AppRoute.Serials },
      description: translate('header.tabs.serialPage'),
      ariaLabel: translate('header.tabs.serialPage.ariaLabel'),
    },
    {
      name: AppRoute.TV,
      target: { name: AppRoute.TV },
      description: translate('header.tabs.tvPage'),
      ariaLabel: translate('header.tabs.tvPage.ariaLabel'),
    },
    {
      name: AppRoute.MyCollectionKinom,
      target: { name: AppRoute.MyCollectionKinom },
      description: translate('header.tabs.myCollection'),
      ariaLabel: translate('header.tabs.myCollection.ariaLabel'),
    },
  ];

  const isHeaderShown = computed(() => !hiddenHeaderRoutes.includes(route.name as AppRoute));

  const isMultipleLanguageVariation = computed(() => languageList.value.length > 1);

  const isHeaderLanguagePickerShown = computed(() => {
    if (!isMultipleLanguageVariation.value) {
      return false;
    }

    return !isAuth.value;
  });

  const isAccountLanguageMenuShown = computed(() => {
    if (!isMultipleLanguageVariation.value) {
      return false;
    }

    return isAuth.value;
  });

  const headerLinkAnalyticEventNameDict: Record<string, AnalyticEventNameNew> = {
    [AppRoute.Index]: AnalyticEventNameNew.GotoMainPage,
    [AppRoute.Movies]: AnalyticEventNameNew.GotoCatalogPage,
    [AppRoute.Serials]: AnalyticEventNameNew.GotoCatalogPage,
    [AppRoute.MyChannel]: AnalyticEventNameNew.GoToMyChannelPage,
    [AppRoute.MyCollection]: AnalyticEventNameNew.GotoCollectionsPage,
    [AppRoute.MyCollectionKinom]: AnalyticEventNameNew.GotoCollectionsPage,
    [AppRoute.TV]: AnalyticEventNameNew.GotoTvPage,
    [AppRoute.AccountInfo]: AnalyticEventNameNew.GotoAccountPage,
  };

  const headerLinkAdriverEventNameDict: Record<string, AdriverAnalyticEvent> = {
    [AppRoute.Index]: AdriverAnalyticEvent.GoToMainPage,
    [AppRoute.MyChannel]: AdriverAnalyticEvent.GoToMyChannelPage,
    [AppRoute.Movies]: AdriverAnalyticEvent.GoToMoviesPage,
    [AppRoute.Serials]: AdriverAnalyticEvent.GoToSerialsPage,
    [AppRoute.TV]: AdriverAnalyticEvent.GoToTVPage,
  };

  const isLinkActive = (link: AppHeaderItem) => {
    if (link.name === AppRoute.MyCollectionKinom) {
      return route.name === AppRoute.MyCollectionKinom || route.name === AppRoute.MyCollectionContent;
    }

    if (isString(route.name)) {
      return route.name.includes(link.name);
    }
  };

  const onAppHeaderLinkClick = async (item: AppHeaderItem) => {
    catalogStore.selectedSlugsWatcher.pause();

    $sendAnalyticEvent({
      event: headerLinkAnalyticEventNameDict[item.name],
      page: getKmzaPageValue(route.name as AppRoute),
    });

    injectAdriverScript(AdriverAnalyticEvent.HeaderButtonNavigationClick);
    injectAdriverScript(headerLinkAdriverEventNameDict[item.name]);

    catalogStore.resetSlugs();
    await nextTick();
    catalogStore.selectedSlugsWatcher.resume();
  };

  const profileLinkRoute = computed<RouteLocationRaw>(() => {
    const { path } = getBackRouteRedirectUrl();

    const hasBackRoute = Boolean(route.query[RouteQuery.BackRoute]);

    return {
      name: isAuth.value ? AppRoute.AccountInfo : AppRoute.AuthEnter,
      query: isAuth.value ? undefined : { [RouteQuery.BackRoute]: hasBackRoute ? path : route.fullPath },
    };
  });

  return {
    isLinkActive,
    onAppHeaderLinkClick,
    isHeaderShown,
    isMobileMyChannelPage,
    isHeaderLanguagePickerShown,
    isAccountLanguageMenuShown,
    profileLinkRoute,
    navItems,
  };
}
