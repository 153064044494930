<template>
  <app-modal-wrapper
    :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Referral, 'modalWrapper')"
    :class="$style.referralModal"
  >
    <modal-title :title="$t('referralPopup.title')" />
    <modal-description :description="$t('referralPopup.description')" />
    <app-button
      :text="$t('referralPopup.buttonText')"
      :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Referral, 'inviteFriendButton')"
      :class="$style.inviteButton"
      @click="goToReferral()"
    />
  </app-modal-wrapper>
</template>

<script lang="ts" setup>
import { BonusProgram } from '@package/sdk/src/api';
import { addMonths } from 'date-fns';

import { CookieName, cookies } from '@/platform/cookies/cookies';
import { RouteQuery } from '@/platform/router/query';
import { AppRoute } from '@/platform/router/routes';
import { useLayoutStore } from '@/stores/use-layout-store';

import useAppCookie from '../../platform/cookies/use-app-cookie';
import AppButton from '../ui/AppButton.vue';
import ModalDescription from './ModalDescription.vue';
import ModalTitle from './ModalTitle.vue';

const router = useRouter();

const layoutStore = useLayoutStore();
const { $emitter } = useNuxtApp();

const referralPopupShowDate = useAppCookie(CookieName.ReferralModalShowingDate, {
  maxAge: cookies.referralPopupShowDate.maxAge,
  path: '/',
});

const setShowDate = () => {
  referralPopupShowDate.value = addMonths(new Date(), 1);
};

const goToReferral = () => {
  layoutStore.setCurrentModalName(null);

  setShowDate();

  router.push({
    name: AppRoute.AccountBonuses,
    query: { [RouteQuery.BonusProgram]: BonusProgram.Referral },
  });
};

const onReferralModalClose = () => {
  setShowDate();
};

onMounted(() => {
  setShowDate();
  $emitter.on('app.modal.disposed', onReferralModalClose);
});

onBeforeUnmount(() => {
  $emitter.off('app.modal.disposed', onReferralModalClose);
});
</script>

<style lang="scss" module>
.referralModal {
  display: flex;
  flex-direction: column;
}

.inviteButton {
  margin-top: var(--g-spacing-32);
  flex: 1;
}
</style>
