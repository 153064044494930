import { ensureStartSlash } from '@package/sdk/src/core';
import useLocalStorage from '@package/sdk/src/core/dom/use-local-storage';

import { maxAge } from '@/platform/cookies/cookies';
import { RouteQuery } from '@/platform/router/query';
import { AppRoute } from '@/platform/router/routes';
import type { LocalStorageValueMap } from '@/platform/storage/local-storage';
import { LocalStorageKey } from '@/platform/storage/local-storage';
import { useLayoutStore } from '@/stores/use-layout-store';
import { useOffersStore } from '@/stores/use-offers-store';

export default function useTvPromoPopupActions() {
  // @ts-ignore
  const localStorage = useLocalStorage<LocalStorageKey, LocalStorageValueMap>();
  const route = useRoute();
  const layoutStore = useLayoutStore();
  const { isActiveSubscription } = storeToRefs(useOffersStore());

  const getTvPromoRedirectFlag = () => localStorage.getValue(LocalStorageKey.TvPromoRedirectUrlAfterReg, '1');

  const isPromoPopupShown = () => localStorage.getValue(LocalStorageKey.TvPromoPopupShown, false);

  const getTvRedirectUrl = () => {
    const tvPromoRedirectUrlAfterReg = getTvPromoRedirectFlag();

    if (!tvPromoRedirectUrlAfterReg) {
      return ensureStartSlash(AppRoute.TV);
    }

    // Если урл до этого содержал ссылку на конкретный канал
    const isSingleChannelBackUrl = /tv-channels\/[A-Z]/i.test(tvPromoRedirectUrlAfterReg.value as string);

    // Если валидная ссылка возвращаем на конкретный канал
    if (isSingleChannelBackUrl) {
      return ensureStartSlash(tvPromoRedirectUrlAfterReg.value as string);
    } else {
      // Возвращаем просто на страницу ТВ канала
      return ensureStartSlash(AppRoute.TV);
    }
  };

  const removeFlag = () => {
    localStorage.remove(LocalStorageKey.TvPromoRedirectUrlAfterReg);
    localStorage.remove(LocalStorageKey.TvPromoPopupShown);
  };

  const showTvPromoPopup = () => {
    if (isActiveSubscription.value) {
      return;
    }

    const mustShowPopup = getTvPromoRedirectFlag();
    if (!mustShowPopup || isPromoPopupShown()) {
      return;
    }

    localStorage.setValue(LocalStorageKey.TvPromoPopupShown, {
      value: true,
      expires: maxAge.ONE_WEEK,
    });

    layoutStore.setCurrentModalName('TvPromoModal');
  };

  const addTvPromoPopupFlag = (url?: string) => {
    // Для юзеров с подпиской не отображаем попап
    if (isActiveSubscription.value) {
      return;
    }

    localStorage.setValue(LocalStorageKey.TvPromoRedirectUrlAfterReg, {
      value: url || (route.query[RouteQuery.BackRoute] as string) || ensureStartSlash(AppRoute.TV),
      expires: maxAge.ONE_WEEK,
    });
  };

  return {
    isPromoPopupShown,
    getTvRedirectUrl,
    getTvPromoRedirectFlag,
    removeFlag,
    showTvPromoPopup,
    addTvPromoPopupFlag,
  };
}
